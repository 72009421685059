import * as actionTypes from '../actions/actionTypes';

const initialState = {
  products: {},  // Using an object for O(1) lookups by ID
  isLoading: false,
  error: null
};

const serializeTimestamp = (value) => {
  if (!value) return value;
  
  // Convert Timestamp objects to ISO strings
  if (value._seconds !== undefined && value._nanoseconds !== undefined) {
    return new Date(value._seconds * 1000).toISOString();
  }
  
  return value;
};

const serializeObject = (obj) => {
  if (!obj || typeof obj !== 'object') return obj;
  
  const newObj = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    const value = obj[key];
    newObj[key] = typeof value === 'object' ? serializeObject(value) : serializeTimestamp(value);
  }
  return newObj;
};

const mergeProductData = (product) => {
  // Start with essential product data
  const mergedProduct = {
    ...product,
    effects: [],
    activities: [],
    weights: null,
    doses: undefined,
    classification: undefined
  };

  // 1. Check for sp nested data
  if (product.sp) {
    Object.assign(mergedProduct, {
      effects: product.sp.effects || [],
      activities: product.sp.activities || [],
      weights: product.sp.weights || null,
      doses: product.sp.doses,
      classification: product.sp.classification,
    });
  }

  // 2. Check for root level fields
  if (product.attributes) {
    Object.assign(mergedProduct, {
      effects: product.attributes.effects || mergedProduct.effects,
      activities: product.attributes.activities || mergedProduct.activities,
      weights: product.attributes.weights || mergedProduct.weights,
      doses: product.attributes.doses || mergedProduct.doses,
      classification: product.attributes.classification || mergedProduct.classification,
    });
  }

  // 3. Get data from first store if available
  if (product.stores && Object.keys(product.stores).length > 0) {
    const firstStoreId = Object.keys(product.stores)[0];
    const storeData = product.stores[firstStoreId];
    if (storeData?.attributes) {
      Object.assign(mergedProduct, {
        effects: storeData.attributes.effects || mergedProduct.effects,
        activities: storeData.attributes.activities || mergedProduct.activities,
        weights: storeData.attributes.weights || mergedProduct.weights,
        doses: storeData.attributes.doses || mergedProduct.doses,
        classification: storeData.attributes.classification || mergedProduct.classification,
      });
    }
  }

  // Serialize timestamps in the final object
  return serializeObject(mergedProduct);
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS: {
      // Convert array to object with IDs as keys and merge data
      const productsObj = {};
      for (const product of action.payload) {
        productsObj[product.id] = mergeProductData(product);
      }
      return {
        ...state,
        products: productsObj
      };
    }

    case actionTypes.ADD_PRODUCTS: {
      // Merge new products with existing ones
      const newProducts = {};
      for (const product of action.payload) {
        newProducts[product.id] = mergeProductData(product);
      }
      return {
        ...state,
        products: {
          ...state.products,
          ...newProducts
        }
      };
    }

    case actionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.id]: mergeProductData(action.payload)
        }
      };

    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default productReducer;
