import { useSelector } from 'react-redux';
import "./OrderDetails.css";

const OrderDetails = ({ order, isHistorical = false, buyNowProduct, buyNowQuantity }) => {
  const cartItems = useSelector(state => state.order.cart);
  
  const calculateTotals = () => {
    if (isHistorical) {
      return calculateHistoricalTotals(order);
    } else if (buyNowProduct) {
      return calculateBuyNowTotals(buyNowProduct, buyNowQuantity);
    } else {
      return calculateStashTotals(cartItems);
    }
  };

  const calculateHistoricalTotals = (order) => {
    if (!order?.totals?.subtotal || !order?.totals?.taxes) {
      return {
        subtotal: "0.00",
        laCannabisCity: "0.00",
        caExcise: "0.00",
        salesTax: "0.00",
        savings: "0.00",
        total: "0.00"
      };
    }

    const { subtotal, taxes, total } = order.totals;
    const savings = order.totals.savings || 0;

    return {
      subtotal: (subtotal / 100).toFixed(2),
      laCannabisCity: (taxes.laCannabisCity / 100).toFixed(2),
      caExcise: (taxes.caExcise / 100).toFixed(2),
      salesTax: (taxes.salesTax / 100).toFixed(2),
      savings: (savings / 100).toFixed(2),
      total: (total / 100).toFixed(2)
    };
  };

  const calculateBuyNowTotals = (product, quantity) => {
    const regularPrice = product.pricing.price_sell * quantity;
    const actualPrice = (product.pricing.discounted_price || product.pricing.price_sell) * quantity;
    const savings = product.pricing.discounted_price ? 
      (product.pricing.price_sell - product.pricing.discounted_price) * quantity : 0;

    const laCannabisRate = 0.10;
    const caExciseRate = 0.15;
    const salesTaxRate = 0.0975;

    const laCannabisCity = actualPrice * laCannabisRate;
    const caExcise = actualPrice * caExciseRate;
    const salesTax = actualPrice * salesTaxRate;
    const total = actualPrice + laCannabisCity + caExcise + salesTax;

    return {
      subtotal: actualPrice.toFixed(2),
      laCannabisCity: laCannabisCity.toFixed(2),
      caExcise: caExcise.toFixed(2),
      salesTax: salesTax.toFixed(2),
      savings: savings.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const calculateStashTotals = (items) => {
    let subtotal = 0;
    let savings = 0;

    items.forEach(item => {
      const regularPrice = item.price * item.quantity;
      const actualPrice = (item.discountedPrice || item.price) * item.quantity;
      
      subtotal += actualPrice;
      if (item.discountedPrice && item.discountedPrice < item.price) {
        savings += (item.price - item.discountedPrice) * item.quantity;
      }
    });

    const laCannabisRate = 0.10;
    const caExciseRate = 0.15;
    const salesTaxRate = 0.0975;

    const laCannabisCity = subtotal * laCannabisRate;
    const caExcise = subtotal * caExciseRate;
    const salesTax = subtotal * salesTaxRate;
    const total = subtotal + laCannabisCity + caExcise + salesTax;

    return {
      subtotal: subtotal.toFixed(2),
      laCannabisCity: laCannabisCity.toFixed(2),
      caExcise: caExcise.toFixed(2),
      salesTax: salesTax.toFixed(2),
      savings: savings.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const totals = calculateTotals();

  return (
    <div className="receipt-container">
      {!isHistorical && !buyNowProduct && <h2 className="receipt-header">Order Details</h2>}
      <hr className="receipt-divider" />
      
      <div className="receipt-section">
        <div className="receipt-row">
          <span className="receipt-label">Subtotal</span>
          <span className="receipt-amount">${totals.subtotal}</span>
        </div>

        <div className="receipt-row receipt-tax">
          <span className="receipt-label">LA Cannabis City Tax</span>
          <span className="receipt-amount">${totals.laCannabisCity}</span>
        </div>

        <div className="receipt-row receipt-tax">
          <span className="receipt-label">CA Excise Tax</span>
          <span className="receipt-amount">${totals.caExcise}</span>
        </div>

        <div className="receipt-row receipt-tax">
          <span className="receipt-label">Sales Tax</span>
          <span className="receipt-amount">${totals.salesTax}</span>
        </div>

        {parseFloat(totals.savings) > 0 && (
          <div className="receipt-row receipt-savings">
            <span className="receipt-label">{isHistorical ? 'You Saved' : 'You Save'}</span>
            <span className="receipt-amount">-${totals.savings}</span>
          </div>
        )}

        <hr className="receipt-divider" />
        
        <div className="receipt-row receipt-total">
          <span className="receipt-label">Total</span>
          <span className="receipt-amount">${totals.total}</span>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
