import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { 
  createStoreWithInventory,
  updateStore,
  getStores,
  getStoreDetails,
} from '../../utils/FirebaseUtils';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { loadAdminPanelData } from '../utils/adminPanelLoader';
import Grid from '@mui/material/Grid2';

import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Card,
  CardContent,
  Avatar,
  Collapse,
  Switch,
  ThemeProvider,
  createTheme,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Alert,
  Autocomplete
} from '@mui/material';
import { 
  Edit as EditIcon,
  Save as SaveIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
  Close as CloseIcon,
  AddPhotoAlternate as AddPhotoIcon
} from '@mui/icons-material';

// import { fetchFrontOfHouseProducts } from '../../utils/TreezService';  // Add this import
import { searchAddress, validateAddress } from '../utils/addressValidation';

const INTEGRATION_TYPES = {
  DUTCHIE: 'DUTCHIE',
  TREEZ: 'TREEZ',
  MEADOW: 'MEADOW',
  IHEART_JANE: 'IHEART_JANE'
};

const TIMEZONES = [
  { id: 'America/Los_Angeles', label: 'Pacific Time (Los Angeles)' },
  { id: 'America/Denver', label: 'Mountain Time (Denver)' },
  { id: 'America/Chicago', label: 'Central Time (Chicago)' },
  { id: 'America/New_York', label: 'Eastern Time (New York)' }
];

// Create a theme that matches your brand
const theme = createTheme({
  palette: {
    primary: {
      main: '#1FBFFF', // deepskyblue
      light: 'rgba(31, 191, 255, 0.15)',
      dark: '#0005A3', // darkblue
    },
    secondary: {
      main: '#FF26C9', // deeppink
      light: '#F500A3',
      dark: 'rgba(245, 0, 163, 0.6)',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#00011F', // gray-100
      secondary: 'rgba(0, 1, 31, 0.75)', // gray-200
    },
  },
  typography: {
    fontFamily: 'Outfit, sans-serif',
    h1: {
      fontFamily: 'Lexend, sans-serif',
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Lexend, sans-serif',
      fontWeight: 500,
    },
    h3: {
      fontFamily: 'Lexend, sans-serif',
      fontWeight: 500,
    },
    button: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 12px rgba(0, 1, 31, 0.08)',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 24px rgba(0, 1, 31, 0.12)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            '&:hover fieldset': {
              borderColor: '#1FBFFF',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1FBFFF',
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontFamily: 'Outfit, sans-serif',
          fontWeight: 500,
        },
      },
    },
  },
});

const AddressInput = React.memo(({ value, onChange, isEdit }) => {
  // Parse the initial value to handle store document format
  const parseInitialValue = (val) => {
    if (!val) return null;
    
    // If we already have a location object in the store document
    if (typeof val === 'string' && window.storeData?.location) {
      return {
        formatted: val,
        location: window.storeData.location
      };
    }
    
    // If it's just a string address
    if (typeof val === 'string') {
      return {
        formatted: val,
        location: null
      };
    }
    
    return val;
  };
  
  const [selectedAddress, setSelectedAddress] = useState(parseInitialValue(value));
  const [searchQuery, setSearchQuery] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Initialize searchQuery with store address when component mounts
  useEffect(() => {
    if (value) {
      setSearchQuery(value);
    }
  }, [value]);

  // Handle search input change with debounce
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!searchQuery.trim()) {
        setSuggestions([]);
        return;
      }

      setIsLoading(true);
      try {
        const results = await searchAddress(searchQuery);
        setSuggestions(results);
      } catch (error) {
        console.error('Address search failed:', error);
        setSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleSearchChange = (event, value) => {
    setSearchQuery(value || '');
  };

  // Handle address selection
  const handleAddressSelect = async (suggestion) => {
    if (!suggestion || typeof suggestion === 'string') {
      return;
    }

    setSearchQuery(suggestion.formatted);
    setSuggestions([]);
    
    try {
      // Validate the selected address
      const validated = await validateAddress(suggestion.address);
      
      // Format the data to match our store document structure
      const formattedAddress = {
        formatted: validated.formatted,
        location: {
          lat: validated.location.lat,
          lng: validated.location.lng
        }
      };
      
      setSelectedAddress(formattedAddress);
      
      // Call onChange with both the address string and location object
      onChange({
        address: formattedAddress.formatted,
        location: formattedAddress.location
      });
      
    } catch (error) {
      console.error('Address validation failed:', error);
      // If validation fails, still use the suggestion but without coordinates
      const fallbackAddress = {
        formatted: suggestion.formatted,
        location: null
      };
      setSelectedAddress(fallbackAddress);
      onChange({
        address: fallbackAddress.formatted,
        location: null
      });
    }
  };

  if (!isEdit) {
    return (
      <Grid xs={12}>
        <TextField
          fullWidth
          label="Address"
          value={selectedAddress?.formatted || value || ''}
          disabled
          variant="outlined"
        />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Autocomplete
          freeSolo
          options={suggestions}
          getOptionLabel={(option) => 
            typeof option === 'string' ? option : option.formatted
          }
          loading={isLoading}
          inputValue={searchQuery}
          onInputChange={handleSearchChange}
          onChange={(event, newValue) => handleAddressSelect(newValue)}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Address"
              placeholder="Enter store address"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Grid container direction="column">
                <Typography variant="body1">{option.formatted}</Typography>
                {option.location && (
                  <Typography variant="caption" color="textSecondary">
                    Coordinates: {option.location.lat.toFixed(6)}, {option.location.lng.toFixed(6)}
                  </Typography>
                )}
              </Grid>
            </li>
          )}
        />
      </Grid>
      {selectedAddress?.location && (
        <Grid xs={12}>
          <Alert severity="success">
            <Typography variant="body2">
              Validated Address: {selectedAddress.formatted}
              <br />
              Coordinates: {selectedAddress.location.lat.toFixed(6)}, {selectedAddress.location.lng.toFixed(6)}
            </Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
});

const AdminPanel = () => {
  const auth = getAuth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('products'); // 'products' or 'stores'
  const [stores, setStores] = useState([]);
  const [storeDialogOpen, setStoreDialogOpen] = useState(false);
  const [editingStore, setEditingStore] = useState(null);
  const [storeForm, setStoreForm] = useState({
    name: '',
    contactEmail: '',
    contactPhone: '',
    hours: {
      monday: { isOpen: false, open: '09:00', close: '21:00' },
      tuesday: { isOpen: false, open: '09:00', close: '21:00' },
      wednesday: { isOpen: false, open: '09:00', close: '21:00' },
      thursday: { isOpen: false, open: '09:00', close: '21:00' },
      friday: { isOpen: false, open: '09:00', close: '21:00' },
      saturday: { isOpen: false, open: '09:00', close: '21:00' },
      sunday: { isOpen: false, open: '09:00', close: '21:00' }
    },
    settings: {  // New structure
      delivery: {  // Moved from deliverySettings
        enabled: false,
        minimumOrder: '',
        fee: '',
        radius: '',
        zones: []
      },
      pickup: {  // Moved from pickupSettings
        enabled: false,
        minimumOrder: ''
      },
      inventory: {  // Add new inventory settings
        autoSync: true,
        defaultThreshold: 3
      },
      integration: {
        type: '',
        apiKeys: {
          clientId: '',
          apiKey: '',
          dispensaryName: ''
        }
      },
    },
    commissionRate: '',
    status: 'ACTIVE',
    timezone: 'America/Los_Angeles'
  });
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 20;
  const loaderRef = useRef(null);
  // State for expanded product data
  const [expandedProductData, setExpandedProductData] = useState({});
  const [loadingExpandedData, setLoadingExpandedData] = useState(false);
  const [expandedProductId, setExpandedProductId] = useState(null);

  const fetchProductDetails = async (productId) => {
    const product = products.find(p => p.id === productId);
    if (!product) return null;
    
    // Return enhanced product data
    return {
      ...product,
      expanded: true,  // Add a flag to indicate this is expanded data
      // Add any additional data you want to show in expanded view
    };
  };

  // Handle card expansion and load detailed data
  const handleProductExpandClick = async (productId) => {
    console.log('handleProductExpandClick called with productId:', productId);
    console.log('Current expandedProductId:', expandedProductId);
    setLoadingExpandedData(true);
    try {
      console.log('Fetching product details...');
      const detailedData = await fetchProductDetails(productId);
      console.log('Fetched detailed data:', detailedData);
      
      if (detailedData) {
        console.log('Updating expanded product data...');
        setExpandedProductData(prev => {
          const newData = {
            ...prev,
            [productId]: detailedData
          };
          console.log('New expanded product data:', newData);
          return newData;
        });
        
        setExpandedProductId(prevId => {
          const newId = productId === prevId ? null : productId;
          console.log('Setting expandedProductId from', prevId, 'to', newId);
          return newId;
        });
      }
    } catch (error) {
      console.error('Error loading product details:', error);
    } finally {
      console.log('Setting loadingExpandedData to false');
      setLoadingExpandedData(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasMore) {
          loadMoreProducts();
        }
      },
      { threshold: 0.1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [hasMore, products]);

  const loadMoreProducts = () => {
    const startIndex = page * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const newProducts = products.slice(startIndex, endIndex);
    
    if (newProducts.length > 0) {
      setLoadedProducts(prev => [...prev, ...newProducts]);
      setPage(prev => prev + 1);
      setHasMore(endIndex < products.length);
    } else {
      setHasMore(false);
    }
  };

  // Reset loaded products when main products array changes
  useEffect(() => {
    setLoadedProducts([]);
    setPage(0);
    setHasMore(true);
  }, [products]);

  const fetchProducts = async () => {
    try {
      const productsCollection = collection(db, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      
      const productsList = await Promise.all(productsSnapshot.docs.map(async doc => {
        const productData = doc.data();
        const storesCollection = collection(doc.ref, 'stores');
        const storesSnapshot = await getDocs(storesCollection);
        const storesData = storesSnapshot.docs.reduce((acc, storeDoc) => {
          acc[storeDoc.id] = storeDoc.data();
          return acc;
        }, {});
        
        return {
          id: doc.id,
          ...productData,
          storeData: storesData
        };
      }));
      
      return productsList; // Return the list instead of setting state
    } catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  };

  // Add useEffect to handle state updates
  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true);
      const productsFetched = await fetchProducts();
      console.log('=== Products Fetched ===', productsFetched.length);
      setProducts(productsFetched);
      setLoading(false);
    };

    loadProducts();
  }, []);
    
    // Add a separate useEffect to monitor products state
    // useEffect(() => {
    //   console.log('=== Products State Updated ===');
    //   console.log('Products length:', products.length);
    //   if (products.length > 0) {
    //     console.log('Sample product:', products[0]);
    //   }
    // }, [products]);

  // Edit management functions
  // const startEditing = async (docSnapshot) => {
  //   const productId = docSnapshot.id;
  //   setEditingProducts(prev => ({
  //     ...prev,
  //     [productId]: {
  //       id: productId,
  //       data: docSnapshot.data(),
  //       originalData: docSnapshot.data()
  //     }
  //   }));
  // };

  // const stopEditing = (productId) => {
  //   setEditingProducts(prev => {
  //     const { [productId]: removed, ...rest } = prev;
  //     return rest;
  //   });
  // };

  // const saveProduct = async (productId) => {
  //   try {
  //     const editedProduct = editingProducts[productId];
  //     await updateDoc(doc(db, 'products', productId), editedProduct.data);
  //     stopEditing(productId);
  //   } catch (error) {
  //     console.error('Error saving product:', error);
  //   }
  // };

  const fetchStores = async () => {
    try {
      setLoading(true);
      console.log('🔍 Starting store ID load...');
      const storesCollection = collection(db, 'stores');
      const storesSnapshot = await getDocs(storesCollection);
      console.log('📝 Store IDs:');
        storesSnapshot.docs.forEach(doc => {
        console.log(doc.id);
      });
    
      console.log(`✅ Total stores found: ${storesSnapshot.size}`);
      console.timeEnd('Store IDs Load');
      const storesList = storesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setStores(storesList);
    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    // fetchProducts();
    fetchStores();
  }, []);

  // const fetchProducts = async () => {
  //   try {
  //     setLoading(true);
  //     const productsCollection = collection(db, 'products');
  //     const productsSnapshot = await getDocs(productsCollection);
  //     const productsList = await Promise.all(productsSnapshot.docs.map(async doc => {
  //       const productData = doc.data();
  //       const storesCollection = collection(doc.ref, 'stores');
  //       const storesSnapshot = await getDocs(storesCollection);
  //       const storesData = storesSnapshot.docs.reduce((acc, storeDoc) => {
  //         acc[storeDoc.id] = storeDoc.data();
  //         return acc;
  //       }, {});
        
  //       return {
  //         id: doc.id,
  //         ...productData,
  //         storeData: storesData
  //       };
  //     }));
  //     setProducts(productsList);
  //   } catch (error) {
  //     console.error('Error fetching products:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const fetchStores = async () => {
  //   try {
  //     const storesCollection = collection(db, 'stores');
  //     const storesSnapshot = await getDocs(storesCollection);
  //     const storesList = storesSnapshot.docs.map(doc => ({
  //       id: doc.id,
  //       ...doc.data()
  //     }));
  //     setStores(storesList);
  //   } catch (error) {
  //     console.error('Error fetching stores:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchProducts();
  //   fetchStores();
  // }, []);

  // const loadData = async (signal) => {
  //   console.log('🚀 loadData called');
  //   if (signal?.aborted) return;
  //   try {
  //     console.log('Setting loading state...');
  //     setLoading(true);
  //     console.log('Calling loadAdminPanelData...');
  //     const { products: productsList, stores: storesList } = await loadAdminPanelData();
  //     setProducts(productsList);
  //     setStores(storesList);
  //   } catch (error) {
  //     console.error('Error loading admin panel data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   loadData();
  // }, []);

  const handleImageUpload = async (event, productId) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      // Generate a unique image ID using timestamp and random string
      const imageId = `${Date.now()}-${Math.random().toString(36).substring(2)}`;
      const storageRef = ref(storage, `product_images/${imageId}`);
      
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);

      const productRef = doc(db, 'products', productId);
      await updateDoc(productRef, {
        'sp.imageUrl': imageUrl
      });

      fetchProducts();
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleStoreImageUpload = async (storeId, file) => {
    try {
      setLoading(true);
      const storageRef = ref(storage, `product_images/${storeId}_${file.name}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      
      const storeRef = doc(db, 'stores', storeId);
      await updateDoc(storeRef, {
        imageUrl: imageUrl,
      });
      
      fetchStores();
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  const ProductRowBasic = ({ product, isExpanded, onToggleExpand }) => {
    const treezImage = product.product_configurable_fields?.image_url;
    const spImage = product.sp?.imageUrl;
    return (
      <CardContent>
        <Grid container alignItems="center" spacing={2} >
            {/* Image and Title Section */}
            <Grid xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}}>
                <Box sx={{ position: 'relative' }}>
                  {(spImage || treezImage) ? (
                    <Avatar 
                      src={spImage || treezImage} 
                      alt={product.sp?.name || product.product_configurable_fields?.name}
                      sx={{ width: 60, height: 60, borderRadius: 2 }}
                    />
                  ) : (
                    <Box sx={{ 
                      width: 60, 
                      height: 60, 
                      borderRadius: 2,
                      bgcolor: 'grey.200',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      No Image
                    </Box>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`image-upload-${product.id}`}
                    onChange={(e) => handleImageUpload(e, product.id)}
                  />
                  <label htmlFor={`image-upload-${product.id}`}>
                    <IconButton
                      component="span"
                      size="small"
                      sx={{
                        position: 'absolute',
                        bottom: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        '&:hover': {
                          bgcolor: 'primary.light'
                        }
                      }}
                    >
                      <AddPhotoIcon fontSize="small" />
                    </IconButton>
                  </label>
                </Box>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      color: 'text.primary',
                      width: '300px',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineHeight: 1.2,
                      height: '3.6em'
                    }}
                  >
                    {product.sp?.name || product.product_configurable_fields?.name || 'Unnamed Product'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: {product.id}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Categories and Status */}
            <Grid xs={12} md={6}>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                {product.sp?.categories?.map((category) => (
                  <Chip 
                    key={category}
                    label={category}
                    size="small"
                    sx={{ 
                      backgroundColor: 'primary.light',
                      color: 'primary.dark',
                    }}
                  />
                ))}
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Chip 
                  label={product.product_status || 'Unknown'}
                  size="small"
                  color={product.product_status === 'ACTIVE' ? 'success' : 'default'}
                />
                <Typography variant="body2" color="text.secondary">
                  Stock: {product.sellable_quantity || '0'} units
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${product.pricing?.price_sell?.toFixed(2) || '0.00'}
                </Typography>
              </Box>
            </Grid>

            {/* Action Buttons */}
            <Grid xs={12} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <IconButton 
                  onClick={onToggleExpand}
                  sx={{ 
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    }
                  }}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                {!isEditing ? (
                  <IconButton 
                    onClick={() => handleEdit()}
                    sx={{ 
                      color: 'secondary.main',
                      '&:hover': {
                        backgroundColor: 'secondary.light',
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <>
                    <IconButton 
                      onClick={handleSave}
                      sx={{ 
                        color: 'primary.main',
                        '&:hover': {
                          backgroundColor: 'primary.light',
                        }
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton 
                      onClick={handleCancel}
                      sx={{ 
                        color: 'error.main',
                        '&:hover': {
                          backgroundColor: 'error.light',
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
      </CardContent>
    );
  };

  const ProductRowExpanded = ({ product, isExpanded, onToggleExpand, onEdit }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [expandedStores, setExpandedStores] = useState({});
    const [localEditForm, setLocalEditForm] = useState({
      name: product.sp?.name || product.product_configurable_fields?.name || '',
      brand: product.sp?.brand || product.product_configurable_fields?.brand || '',
      description: product.sp?.description || '',
      effects: product.sp?.attributes?.effects?.join(', ') || '',
      flavors: product.sp?.attributes?.flavors?.join(', ') || '',
      activities: product.sp?.attributes?.activities?.join(', ') || '',
      classification: product.sp?.attributes?.classification || '',
      category: product.sp?.attributes?.category || '',
      doses: product.sp?.attributes?.doses || 0,
      mgPerDose: product.sp?.attributes?.mgPerDose || 0,
      weightValue: product.sp?.attributes?.amount?.value || 0,
      weightUnit: product.sp?.attributes?.amount?.unit || '',
      ingredients: product.sp?.attributes?.details?.ingredients || '',
      servings: product.sp?.attributes?.details?.servings || '',
      instructions: product.sp?.attributes?.details?.instructions || '',
      seoTitle: product.sp?.seoTitle || '',
      seoKeywords: product.sp?.seoKeywords?.join(', ') || '',
      seoDescription: product.sp?.seoDescription || '',
      featured: product.sp?.featured || false,
      hidden: product.sp?.hidden || false,
      displayPriority: product.sp?.displayPriority || 0,
      notes: product.sp?.notes || '',
      thcValue: product.sp?.attributes?.cannabinoids?.thc?.value || 0,
      thcUnit: product.sp?.attributes?.cannabinoids?.thc?.unit || '%',
      cbdValue: product.sp?.attributes?.cannabinoids?.cbd?.value || 0,
      cbdUnit: product.sp?.attributes?.cannabinoids?.cbd?.unit || '%',
      delta9Value: product.sp?.attributes?.cannabinoids?.delta9?.value || 0,
      delta9Unit: product.sp?.attributes?.cannabinoids?.delta9?.unit || '%',
      thcaValue: product.sp?.attributes?.cannabinoids?.thca?.value || 0,
      thcaUnit: product.sp?.attributes?.cannabinoids?.thca?.unit || '%',
      thcvValue: product.sp?.attributes?.cannabinoids?.thcv?.value || 0,
      thcvUnit: product.sp?.attributes?.cannabinoids?.thcv?.unit || '%',
      cbgValue: product.sp?.attributes?.cannabinoids?.cbg?.value || 0,
      cbgUnit: product.sp?.attributes?.cannabinoids?.cbg?.unit || '%',
      cbnValue: product.sp?.attributes?.cannabinoids?.cbn?.value || 0,
      cbnUnit: product.sp?.attributes?.cannabinoids?.cbn?.unit || '%',
      cbcValue: product.sp?.attributes?.cannabinoids?.cbc?.value || 0,
      cbcUnit: product.sp?.attributes?.cannabinoids?.cbc?.unit || '%',
      cbgaValue: product.sp?.attributes?.cannabinoids?.cbga?.value || 0,
      cbgaUnit: product.sp?.attributes?.cannabinoids?.cbga?.unit || '%'
    });

    const calculateMgPerDose = (amount, unit, doses, category) => {
      console.log('Calculating mg per dose:', { amount, unit, doses, category });
      if (category !== 'EDIBLE' || unit !== 'mg' || !doses || doses === 0) {
        console.log('Returning 0 because:', { 
          notEdible: category !== 'EDIBLE', 
          notMg: unit !== 'mg', 
          noDoses: !doses || doses === 0 
        });
        return 0;
      }
      const result = Math.round(amount / doses);
      console.log('Calculated result:', result);
      return result;
    };

    const handleEdit = () => {
      setIsEditing(true);
      // setIsExpanded(true);
    };

    const handleCancel = () => {
      setIsEditing(false);
      setLocalEditForm({
        name: product.sp?.name || product.product_configurable_fields?.name || '',
        brand: product.sp?.brand || product.product_configurable_fields?.brand || '',
        description: product.sp?.description || '',
        effects: product.sp?.attributes?.effects?.join(', ') || '',
        flavors: product.sp?.attributes?.flavors?.join(', ') || '',
        activities: product.sp?.attributes?.activities?.join(', ') || '',
        classification: product.sp?.attributes?.classification || '',
        category: product.sp?.attributes?.category || '',
        doses: product.sp?.attributes?.doses || 0,
        mgPerDose: product.sp?.attributes?.mgPerDose || 0,
        weightValue: product.sp?.attributes?.amount?.value || 0,
        weightUnit: product.sp?.attributes?.amount?.unit || '',
        ingredients: product.sp?.attributes?.details?.ingredients || '',
        servings: product.sp?.attributes?.details?.servings || '',
        instructions: product.sp?.attributes?.details?.instructions || '',
        seoTitle: product.sp?.seoTitle || '',
        seoKeywords: product.sp?.seoKeywords?.join(', ') || '',
        seoDescription: product.sp?.seoDescription || '',
        featured: product.sp?.featured || false,
        hidden: product.sp?.hidden || false,
        displayPriority: product.sp?.displayPriority || 0,
        notes: product.sp?.notes || '',
        thcValue: product.sp?.attributes?.cannabinoids?.thc?.value || 0,
        thcUnit: product.sp?.attributes?.cannabinoids?.thc?.unit || '%',
        cbdValue: product.sp?.attributes?.cannabinoids?.cbd?.value || 0,
        cbdUnit: product.sp?.attributes?.cannabinoids?.cbd?.unit || '%',
        delta9Value: product.sp?.attributes?.cannabinoids?.delta9?.value || 0,
        delta9Unit: product.sp?.attributes?.cannabinoids?.delta9?.unit || '%',
        thcaValue: product.sp?.attributes?.cannabinoids?.thca?.value || 0,
        thcaUnit: product.sp?.attributes?.cannabinoids?.thca?.unit || '%',
        thcvValue: product.sp?.attributes?.cannabinoids?.thcv?.value || 0,
        thcvUnit: product.sp?.attributes?.cannabinoids?.thcv?.unit || '%',
        cbgValue: product.sp?.attributes?.cannabinoids?.cbg?.value || 0,
        cbgUnit: product.sp?.attributes?.cannabinoids?.cbg?.unit || '%',
        cbnValue: product.sp?.attributes?.cannabinoids?.cbn?.value || 0,
        cbnUnit: product.sp?.attributes?.cannabinoids?.cbn?.unit || '%',
        cbcValue: product.sp?.attributes?.cannabinoids?.cbc?.value || 0,
        cbcUnit: product.sp?.attributes?.cannabinoids?.cbc?.unit || '%',
        cbgaValue: product.sp?.attributes?.cannabinoids?.cbga?.value || 0,
        cbgaUnit: product.sp?.attributes?.cannabinoids?.cbga?.unit || '%'
      });
    };

    const handleInputChange = (field, value) => {
      setLocalEditForm(prev => {
        const newForm = {
          ...prev,
          [field]: value
        };

        // Set cannabinoid units based on category
        if (field === 'category') {
          const unitValue = value === 'EDIBLE' ? 'mg' : '%';
          newForm.thcUnit = unitValue;
          newForm.cbdUnit = unitValue;
          newForm.cbnUnit = unitValue;
          newForm.cbgUnit = unitValue;
          newForm.cbcUnit = unitValue;
          newForm.thcvUnit = unitValue;
          newForm.thcaUnit = unitValue;
          newForm.cbgaUnit = unitValue;
          newForm.delta9Unit = unitValue;
        }

        // Calculate mgPerDose when relevant fields change
        if (['weightValue', 'weightUnit', 'doses', 'category'].includes(field)) {
          const mgPerDose = calculateMgPerDose(
            parseFloat(newForm.weightValue) || 0,
            newForm.weightUnit,
            parseInt(newForm.doses) || 0,
            newForm.category
          );
          newForm.mgPerDose = mgPerDose;
        }

        return newForm;
      });
    };

    const handleSave = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser?.uid) {
          console.error('No user found - please log in again');
          return;
        }

        const productRef = doc(db, 'products', product.id);
        await updateDoc(productRef, {
          'sp.name': localEditForm.name,
          'sp.brand': localEditForm.brand,
          'sp.description': localEditForm.description,
          'sp.attributes.effects': localEditForm.effects.split(',').map(e => e.trim()).filter(e => e),
          'sp.attributes.flavors': localEditForm.flavors.split(',').map(f => f.trim()).filter(f => f),
          'sp.attributes.activities': localEditForm.activities.split(',').map(a => a.trim()).filter(a => a),
          'sp.attributes.classification': localEditForm.classification,
          'sp.attributes.category': localEditForm.category,
          'sp.attributes.doses': parseInt(localEditForm.doses) || 0,
          'sp.attributes.mgPerDose': parseInt(localEditForm.mgPerDose) || 0,
          'sp.attributes.amount': {
            value: parseFloat(localEditForm.weightValue),
            unit: localEditForm.weightUnit
          },
          'sp.attributes.details': {
            ingredients: localEditForm.ingredients,
            servings: localEditForm.servings,
            instructions: localEditForm.instructions
          },
          'sp.attributes.cannabinoids': {
            thc: {
              value: parseFloat(localEditForm.thcValue),
              unit: localEditForm.thcUnit
            },
            cbd: {
              value: parseFloat(localEditForm.cbdValue),
              unit: localEditForm.cbdUnit
            },
            delta9: {
              value: parseFloat(localEditForm.delta9Value),
              unit: localEditForm.delta9Unit
            },
            thca: {
              value: parseFloat(localEditForm.thcaValue),
              unit: localEditForm.thcaUnit
            },
            thcv: {
              value: parseFloat(localEditForm.thcvValue),
              unit: localEditForm.thcvUnit
            },
            cbg: {
              value: parseFloat(localEditForm.cbgValue),
              unit: localEditForm.cbgUnit
            },
            cbn: {
              value: parseFloat(localEditForm.cbnValue),
              unit: localEditForm.cbnUnit
            },
            cbc: {
              value: parseFloat(localEditForm.cbcValue),
              unit: localEditForm.cbcUnit
            },
            cbga: {
              value: parseFloat(localEditForm.cbgaValue),
              unit: localEditForm.cbgaUnit
            }
          },
          'sp.seoTitle': localEditForm.seoTitle,
          'sp.seoKeywords': localEditForm.seoKeywords.split(',').map(k => k.trim()).filter(k => k),
          'sp.seoDescription': localEditForm.seoDescription,
          'sp.featured': localEditForm.featured,
          'sp.hidden': localEditForm.hidden,
          'sp.displayPriority': parseInt(localEditForm.displayPriority),
          'sp.notes': localEditForm.notes,
          'sp.lastEditedBy': currentUser.uid,
          'sp.lastEditedAt': new Date().toISOString(),
          'lab_results': product.lab_results || []
        });
        
        // Create the updatedProduct object matching the existing structure
        const updatedProduct = {
          ...product,
          sp: {
            ...product.sp,
            name: localEditForm.name,
            brand: localEditForm.brand,
            description: localEditForm.description,
            attributes: {
              ...product.sp?.attributes,
              effects: localEditForm.effects.split(',').map(e => e.trim()).filter(e => e),
              flavors: localEditForm.flavors.split(',').map(f => f.trim()).filter(f => f),
              activities: localEditForm.activities.split(',').map(a => a.trim()).filter(a => a),
              classification: localEditForm.classification,
              category: localEditForm.category,
              doses: parseInt(localEditForm.doses) || 0,
              mgPerDose: parseInt(localEditForm.mgPerDose) || 0,
              amount: {
                value: parseFloat(localEditForm.weightValue),
                unit: localEditForm.weightUnit
              },
              details: {
                ingredients: localEditForm.ingredients,
                servings: localEditForm.servings,
                instructions: localEditForm.instructions
              },
              cannabinoids: {
                thc: {
                  value: parseFloat(localEditForm.thcValue),
                  unit: localEditForm.thcUnit
                },
                cbd: {
                  value: parseFloat(localEditForm.cbdValue),
                  unit: localEditForm.cbdUnit
                },
                delta9: {
                  value: parseFloat(localEditForm.delta9Value),
                  unit: localEditForm.delta9Unit
                },
                thca: {
                  value: parseFloat(localEditForm.thcaValue),
                  unit: localEditForm.thcaUnit
                },
                thcv: {
                  value: parseFloat(localEditForm.thcvValue),
                  unit: localEditForm.thcvUnit
                },
                cbg: {
                  value: parseFloat(localEditForm.cbgValue),
                  unit: localEditForm.cbgUnit
                },
                cbn: {
                  value: parseFloat(localEditForm.cbnValue),
                  unit: localEditForm.cbnUnit
                },
                cbc: {
                  value: parseFloat(localEditForm.cbcValue),
                  unit: localEditForm.cbcUnit
                },
                cbga: {
                  value: parseFloat(localEditForm.cbgaValue),
                  unit: localEditForm.cbgaUnit
                }
              }
            },
            seoTitle: localEditForm.seoTitle,
            seoKeywords: localEditForm.seoKeywords.split(',').map(k => k.trim()).filter(k => k),
            seoDescription: localEditForm.seoDescription,
            featured: localEditForm.featured,
            hidden: localEditForm.hidden,
            displayPriority: parseInt(localEditForm.displayPriority) || 0,
            notes: localEditForm.notes,
            lastEditedBy: currentUser.uid,
            lastEditedAt: new Date().toISOString()
          },
          lab_results: product.lab_results || []
        };

        // Update states with the new product data
        setProducts(prevProducts => 
          prevProducts.map(p => p.id === product.id ? updatedProduct : p)
        );
        setExpandedProductData(prev => ({
          ...prev,
          [product.id]: updatedProduct
        }));

        setIsEditing(false);
      } catch (error) {
        console.error('Error updating product:', error);
      }
    };

    const treezImage = product.product_configurable_fields?.image_url;
    const spImage = product.sp?.imageUrl;

    return (
      <Card sx={{ 
        position: 'relative',
        overflow: 'visible',
        zIndex: isExpanded ? 10 : 1,
        boxShadow: isExpanded ? 4 : 1,
        bgcolor: 'background.paper',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: 4,
          backgroundColor: product.sp?.featured ? 'secondary.main' : 'primary.main',
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        }
      }}>
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            {/* Image and Title Section */}
            <Grid xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}}>
                <Box sx={{ position: 'relative' }}>
                  {(spImage || treezImage) ? (
                    <Avatar 
                      src={spImage || treezImage} 
                      alt={product.sp?.name || product.product_configurable_fields?.name}
                      sx={{ width: 60, height: 60, borderRadius: 2 }}
                    />
                  ) : (
                    <Box sx={{ 
                      width: 60, 
                      height: 60, 
                      borderRadius: 2,
                      bgcolor: 'grey.200',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      No Image
                    </Box>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`image-upload-${product.id}`}
                    onChange={(e) => handleImageUpload(e, product.id)}
                  />
                  <label htmlFor={`image-upload-${product.id}`}>
                    <IconButton
                      component="span"
                      size="small"
                      sx={{
                        position: 'absolute',
                        bottom: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        '&:hover': {
                          bgcolor: 'primary.light'
                        }
                      }}
                    >
                      <AddPhotoIcon fontSize="small" />
                    </IconButton>
                  </label>
                </Box>
                <Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      color: 'text.primary',
                      width: '300px',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineHeight: 1.2,
                      height: '3.6em'
                    }}
                  >
                    {product.sp?.name || product.product_configurable_fields?.name || 'Unnamed Product'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ID: {product.id}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Categories and Status */}
            <Grid xs={12} md={6}>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                {product.sp?.categories?.map((category) => (
                  <Chip 
                    key={category}
                    label={category}
                    size="small"
                    sx={{ 
                      backgroundColor: 'primary.light',
                      color: 'primary.dark',
                    }}
                  />
                ))}
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Chip 
                  label={product.product_status || 'Unknown'}
                  size="small"
                  color={product.product_status === 'ACTIVE' ? 'success' : 'default'}
                />
                <Typography variant="body2" color="text.secondary">
                  Stock: {product.sellable_quantity || '0'} units
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${product.pricing?.price_sell?.toFixed(2) || '0.00'}
                </Typography>
              </Box>
            </Grid>

            {/* Action Buttons */}
            <Grid xs={12} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <IconButton 
                  onClick={onToggleExpand}
                  sx={{ 
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    }
                  }}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                {!isEditing ? (
                  <IconButton 
                    onClick={() => handleEdit()}
                    sx={{ 
                      color: 'secondary.main',
                      '&:hover': {
                        backgroundColor: 'secondary.light',
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <>
                    <IconButton 
                      onClick={handleSave}
                      sx={{ 
                        color: 'primary.main',
                        '&:hover': {
                          backgroundColor: 'primary.light',
                        }
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton 
                      onClick={handleCancel}
                      sx={{ 
                        color: 'error.main',
                        '&:hover': {
                          backgroundColor: 'error.light',
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Expanded Content */}
          <Collapse in={isExpanded}>
            <Box sx={{ mt: 3 }}>
              {/* SP Template Section */}
              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  color: 'primary.dark',
                  borderBottom: '2px solid',
                  borderColor: 'primary.main',
                  pb: 1
                }}>
                  Stash Pandas Template
                  {isEditing ? (
                    <Box sx={{ ml: 'auto' }}>
                      <Button
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={handleSave}
                        sx={{ mr: 1 }}
                      >
                        Save Changes
                      </Button>
                      <Button
                        startIcon={<CloseIcon />}
                        variant="outlined"
                        onClick={handleCancel}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      startIcon={<EditIcon />}
                      variant="outlined"
                      onClick={handleEdit}
                      sx={{ ml: 'auto' }}
                    >
                      Edit SP Fields
                    </Button>
                  )}
                </Typography>
                <Card variant="outlined" sx={{ mb: 3 }}>
                  <CardContent>
                    <Grid container spacing={1}>
                      {/* Basic Information */}
                      <Grid xs={12} md={6}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Basic Information
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid xs={12}>
                            <Typography variant="caption" color="textSecondary">Product ID</Typography>
                            <Typography variant="body2" gutterBottom>
                              {product.product_id}
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              label="Name"
                              value={isEditing ? localEditForm.name : (product.sp?.name || '')}
                              onChange={(e) => handleInputChange('name', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              label="Brand"
                              value={isEditing ? localEditForm.brand : (product.sp?.brand || '')}
                              onChange={(e) => handleInputChange('brand', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              label="Description"
                              value={isEditing ? localEditForm.description : (product.sp?.description || '')}
                              onChange={(e) => handleInputChange('description', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              multiline
                              rows={4}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Attributes */}
                      <Grid xs={12} md={6}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Attributes
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid xs={12}>
                            <TextField
                              select
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Classification"
                              value={isEditing ? localEditForm.classification : (product.sp?.attributes?.classification || '')}
                              onChange={(e) => handleInputChange('classification', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                            >
                              <option value="">Select a classification</option>
                              <option value="INDICA">INDICA</option>
                              <option value="SATIVA">SATIVA</option>
                              <option value="HYBRID">HYBRID</option>
                              <option value="CBD">CBD</option>
                              <option value="CBN">CBN</option>
                            </TextField>
                          </Grid>

                          <Grid xs={12} container spacing={1}>
                            <Grid xs={6}>
                              <TextField
                                fullWidth
                                label="Amount"
                                value={isEditing ? localEditForm.weightValue : (product.sp?.attributes?.amount?.value || '')}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  
                                  // Only allow digits and a single decimal point
                                  if (/^[0-9]*\.?[0-9]{0,2}$/.test(value)) {
                                    handleInputChange('weightValue', value);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  // Prevent 'e' from being entered
                                  if (e.key === 'e' || e.key === 'E') {
                                    e.preventDefault();
                                  }
                                }}
                                disabled={!isEditing}
                                size="small"
                                // Use type="text" and inputMode="decimal" for better mobile keyboard
                                type="text"
                                inputMode="decimal"
                                inputProps={{
                                  style: { textAlign: 'right' }
                                }}
                                sx={{
                                  width: '80px',
                                  '& .MuiInputLabel-root': {
                                    backgroundColor: 'background.paper',
                                    px: 0.5,
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={6}>
                              <TextField
                                select
                                fullWidth
                                label="Unit"
                                value={isEditing ? localEditForm.weightUnit : (product.sp?.attributes?.amount?.unit || '')}
                                onChange={(e) => handleInputChange('weightUnit', e.target.value)}
                                disabled={!isEditing}
                                size="small"
                                SelectProps={{
                                  native: true
                                }}
                              >
                                <option value="">Select unit</option>
                                <option value="g">g</option>
                                <option value="mg">mg</option>
                                <option value="oz">oz</option>
                                <option value="lb">lb</option>
                              </TextField>
                            </Grid>
                          </Grid>

                          <Grid xs={12} container spacing={1}>
                            <Grid xs={6}>
                              <TextField
                                fullWidth
                                type="number"
                                label="Doses"
                                value={isEditing ? localEditForm.doses : (product.sp?.attributes?.doses || 0)}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === '' || (value === '0')) {
                                    handleInputChange('doses', value);
                                  } else {
                                    const parsedValue = parseInt(value, 10).toString();
                                    if (/^[1-9]\d*$/.test(parsedValue)) {
                                      handleInputChange('doses', parsedValue);
                                    }
                                  }
                                }}
                                disabled={!isEditing}
                                size="small"
                                InputProps={{
                                  inputProps: { 
                                    min: 0,
                                    step: 1
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={6}>
                              <TextField
                                fullWidth
                                type="number"
                                label="MG per Dose"
                                value={isEditing ? localEditForm.mgPerDose : (product.sp?.attributes?.mgPerDose || 0)}
                                disabled={true}
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: <InputAdornment position="end">mg</InputAdornment>
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid xs={12}>
                            <TextField
                              select
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Effects"
                              value={isEditing ? (localEditForm.effects ? localEditForm.effects.split(',').map(e => e.trim()) : []) : (product.sp?.attributes?.effects || [])}
                              onChange={(e) => {
                                const selectedEffects = e.target.value;
                                if (selectedEffects.length <= 3) {
                                  handleInputChange('effects', selectedEffects.join(', '));
                                }
                              }}
                              disabled={!isEditing}
                              size="small"
                              SelectProps={{
                                native: false,
                                multiple: true,
                                renderValue: (selected) => selected.join(', '),
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300
                                    }
                                  }
                                }
                              }}
                            >
                              {[
                                "Relaxed", "Uplifted", "Happy", "Euphoric", "Creative",
                                "Energetic", "Focused", "Calming", "Sleepy", "Pain Relief",
                                "Stress Relief", "Anxiety Relief", "Hungry", "Talkative",
                                "Giggly", "Social", "Motivated", "Clear-Headed", "Body High",
                                "Cerebral"
                              ].map((effect) => (
                                <MenuItem key={effect} value={effect}>
                                  {effect}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              select
                              fullWidth
                              label="Flavors"
                              value={isEditing ? (localEditForm.flavors ? localEditForm.flavors.split(',').map(f => f.trim()) : []) : (product.sp?.attributes?.flavors || [])}
                              onChange={(e) => {
                                const selectedFlavors = e.target.value;
                                if (selectedFlavors.length <= 3) {  // Allowing up to 3 flavors per product
                                  handleInputChange('flavors', selectedFlavors.join(', '));
                                }
                              }}
                              disabled={!isEditing}
                              size="small"
                              SelectProps={{
                                native: false,
                                multiple: true,
                                renderValue: (selected) => selected.join(', '),
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300
                                    }
                                  }
                                }
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {[
                                // Fruity
                                "Berry", "Blueberry", "Strawberry", "Raspberry", "Fruity",
                                // Tropical
                                "Tropical", "Pineapple", "Mango", "Papaya",
                                // Citrus
                                "Citrus", "Lemon", "Orange", "Lime", "Grapefruit",
                                // Sweet & Floral
                                "Sweet", "Floral", "Vanilla", "Candy", "Honey",
                                // Earthy/Natural
                                "Earthy", "Woody", "Pine", "Forest", "Herbal", "Grass", "Soil",
                                // Sour & Tangy
                                "Tart", "Sour", "Tangy",
                                // Spicy/Savory
                                "Spicy", "Pepper", "Clove", "Nutmeg", "Sage", "Mint", "Minty", "Menthol",
                                // Distinct/Chemical
                                "Skunky", "Skunk", "Diesel", "Gassy", "Chemical",
                                // Other
                                "Coffee", "Tea", "Cheese", "Nutty"
                              ].map((flavor) => (
                                <MenuItem key={flavor} value={flavor}>
                                  {flavor}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              select
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Activities"
                              value={isEditing ? (localEditForm.activities ? localEditForm.activities.split(',').map(a => a.trim()) : []) : (product.sp?.attributes?.activities || [])}
                              onChange={(e) => {
                                const selectedActivities = e.target.value;
                                if (selectedActivities.length <= 3) {
                                  handleInputChange('activities', selectedActivities.join(', '));
                                }
                              }}
                              disabled={!isEditing}
                              size="small"
                              SelectProps={{
                                native: false,
                                multiple: true,
                                renderValue: (selected) => selected.join(', '),
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300
                                    }
                                  }
                                }
                              }}
                            >
                              {[
                                // Time of Day
                                "Wake & Bake", "Morning Buzz", "Daytime", "Happy Hour", "After Work", "Late Night", "Bedtime",
                                
                                // Social Vibes
                                "Hotbox", "Sesh with Friends", "Party Mode", "Date Night", "Smoke Circle", "Cypher", "Social Smoking",
                                
                                // Entertainment
                                "Gaming", "Movies", "Binge Watching", "Music Sessions", "Concert Vibes", "Festival", "Live Music",
                                
                                // Active Lifestyle
                                "Workout", "Yoga", "Hiking", "Beach Day", "Swimming", "Dancing", "Sports", "Adventure",
                                
                                // Chill Activities
                                "Couch Lock", "Netflix & Chill", "Munchies", "Stargazing", "Cloud Watching", "Hammock Time", "Zoning Out",
                                
                                // Creative Vibes
                                "Making Art", "Jamming", "Writing", "Photography", "Drawing", "Creative Flow", "Inspiration",
                                
                                // Wellness
                                "Pain Relief", "Sleep Aid", "Stress Relief", "Meditation", "Recovery", "Self-Care", "Healing",
                                
                                // Productivity
                                "Deep Focus", "Cleaning", "Organizing", "Study Session", "Brain Boost", "Problem Solving",
                                
                                // Locations
                                "Home Sesh", "Nature Walks", "Outdoor Blazing", "Camping", "Beach Vibes", "Park Hangs", "Backyard Chilling",
                                
                                // Special Occasions
                                "4/20 Celebration", "Weekend Vibes", "Holiday Blazing", "Special Occasion", "Me Time",
                                
                                // Mindful Activities
                                "Deep Conversations", "Philosophy", "Spiritual", "Mind Expansion", "Introspection", "Zen Mode",
                                
                                // Daily Activities
                                "Cooking", "Gardening", "Reading", "Listening to Podcasts", "Before Meals", "After Dinner"

                              ].map((activity) => (
                                <MenuItem key={activity} value={activity}>
                                  {activity}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              select
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Category"
                              value={isEditing ? localEditForm.category : (product.sp?.attributes?.category || '')}
                              onChange={(e) => handleInputChange('category', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                            >
                              <option value="">Select a category</option>
                              <option value="FLOWER">FLOWER</option>
                              <option value="VAPE">VAPE</option>
                              <option value="EDIBLE">EDIBLE</option>
                              <option value="PRE-ROLL">PRE-ROLL</option>
                              <option value="EXTRACT">EXTRACT</option>
                              <option value="TINCTURE">TINCTURE</option>
                              <option value="BEVERAGE">BEVERAGE</option>
                              <option value="ACCESSORIES">ACCESSORIES</option>
                              <option value="MERCH">MERCH</option>
                              <option value="PILL">PILL</option>
                              <option value="MISC">MISC</option>
                              <option value="PLANT">PLANT</option>
                              <option value="TOPICAL">TOPICAL</option>
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Cannabinoids */}
                      <Grid xs={12}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Cannabinoids
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', mt: 1 }}>
                          {/* THC */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="THC"
                              value={isEditing ? localEditForm.thcValue : (product.sp?.attributes?.cannabinoids?.thc?.value || 0)}
                              onChange={(e) => handleInputChange('thcValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.thcUnit : (product.sp?.attributes?.cannabinoids?.thc?.unit || '%')}
                              onChange={(e) => handleInputChange('thcUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* CBD */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="CBD"
                              value={isEditing ? localEditForm.cbdValue : (product.sp?.attributes?.cannabinoids?.cbd?.value || 0)}
                              onChange={(e) => handleInputChange('cbdValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.cbdUnit : (product.sp?.attributes?.cannabinoids?.cbd?.unit || '%')}
                              onChange={(e) => handleInputChange('cbdUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* Delta-9 */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="Δ9"
                              value={isEditing ? localEditForm.delta9Value : (product.sp?.attributes?.cannabinoids?.delta9?.value || 0)}
                              onChange={(e) => handleInputChange('delta9Value', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.delta9Unit : (product.sp?.attributes?.cannabinoids?.delta9?.unit || '%')}
                              onChange={(e) => handleInputChange('delta9Unit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* THCa */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="THCa"
                              value={isEditing ? localEditForm.thcaValue : (product.sp?.attributes?.cannabinoids?.thca?.value || 0)}
                              onChange={(e) => handleInputChange('thcaValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.thcaUnit : (product.sp?.attributes?.cannabinoids?.thca?.unit || '%')}
                              onChange={(e) => handleInputChange('thcaUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* THCv */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="THCv"
                              value={isEditing ? localEditForm.thcvValue : (product.sp?.attributes?.cannabinoids?.thcv?.value || 0)}
                              onChange={(e) => handleInputChange('thcvValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.thcvUnit : (product.sp?.attributes?.cannabinoids?.thcv?.unit || '%')}
                              onChange={(e) => handleInputChange('thcvUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* CBG */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="CBG"
                              value={isEditing ? localEditForm.cbgValue : (product.sp?.attributes?.cannabinoids?.cbg?.value || 0)}
                              onChange={(e) => handleInputChange('cbgValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.cbgUnit : (product.sp?.attributes?.cannabinoids?.cbg?.unit || '%')}
                              onChange={(e) => handleInputChange('cbgUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* CBN */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="CBN"
                              value={isEditing ? localEditForm.cbnValue : (product.sp?.attributes?.cannabinoids?.cbn?.value || 0)}
                              onChange={(e) => handleInputChange('cbnValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.cbnUnit : (product.sp?.attributes?.cannabinoids?.cbn?.unit || '%')}
                              onChange={(e) => handleInputChange('cbnUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* CBC */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="CBC"
                              value={isEditing ? localEditForm.cbcValue : (product.sp?.attributes?.cannabinoids?.cbc?.value || 0)}
                              onChange={(e) => handleInputChange('cbcValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.cbcUnit : (product.sp?.attributes?.cannabinoids?.cbc?.unit || '%')}
                              onChange={(e) => handleInputChange('cbcUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>

                          {/* CBGa */}
                          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="CBGa"
                              value={isEditing ? localEditForm.cbgaValue : (product.sp?.attributes?.cannabinoids?.cbga?.value || 0)}
                              onChange={(e) => handleInputChange('cbgaValue', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              inputProps={{
                                min: 0,
                                step: 0.01,
                                style: { textAlign: 'right' }
                              }}
                              sx={{
                                width: '80px',
                                '& .MuiInputLabel-root': {
                                  backgroundColor: 'background.paper',
                                  px: 0.5,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              label="Unit"
                              value={isEditing ? localEditForm.cbgaUnit : (product.sp?.attributes?.cannabinoids?.cbga?.unit || '%')}
                              onChange={(e) => handleInputChange('cbgaUnit', e.target.value)}
                              disabled={true}
                              size="small"
                              SelectProps={{
                                native: true
                              }}
                              sx={{
                                width: '70px',
                                ml: 1
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value="%">%</option>
                              <option value="mg">mg</option>
                            </TextField>
                          </Box>
                        </Box>
                      </Grid>

                      {/* Details */}
                      <Grid xs={12}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Details
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', mt: 1 }}>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label="Ingredients"
                              value={isEditing ? localEditForm.ingredients : (product.sp?.attributes?.details?.ingredients || '')}
                              onChange={(e) => handleInputChange('ingredients', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label="Servings"
                              value={isEditing ? localEditForm.servings : (product.sp?.attributes?.details?.servings || '')}
                              onChange={(e) => handleInputChange('servings', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label="Instructions"
                              value={isEditing ? localEditForm.instructions : (product.sp?.attributes?.details?.instructions || '')}
                              onChange={(e) => handleInputChange('instructions', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Box>
                      </Grid>

                      {/* SEO Fields */}
                      <Grid xs={12}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          SEO Information
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="SEO Title"
                              value={isEditing ? localEditForm.seoTitle : (product.sp?.seoTitle || '')}
                              onChange={(e) => handleInputChange('seoTitle', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="SEO Keywords (comma-separated)"
                              value={isEditing ? localEditForm.seoKeywords : (product.sp?.seoKeywords?.join(', ') || '')}
                              onChange={(e) => handleInputChange('seoKeywords', e.target.value)}
                              disabled={!isEditing}
                              size="small"
                              helperText="Enter keywords separated by commas"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextField
                              fullWidth
                              label="SEO Description"
                              value={isEditing ? localEditForm.seoDescription : (product.sp?.seoDescription || '')}
                              onChange={(e) => handleInputChange('seoDescription', e.target.value)}
                              disabled={!isEditing}
                              multiline
                              rows={2}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Display Settings */}
                      <Grid xs={12}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Display Settings
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isEditing ? localEditForm.featured : (product.sp?.featured || false)}
                                  onChange={(e) => handleInputChange('featured', e.target.checked)}
                                  disabled={!isEditing}
                                />
                              }
                              label="Featured Product"
                            />
                          </Grid>
                          <Grid xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isEditing ? localEditForm.hidden : (product.sp?.hidden || false)}
                                  onChange={(e) => handleInputChange('hidden', e.target.checked)}
                                  disabled={!isEditing}
                                />
                              }
                              label="Hidden from Store"
                            />
                          </Grid>
                          <Grid xs={12} md={4}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Display Priority"
                              value={isEditing ? localEditForm.displayPriority : (product.sp?.displayPriority || 0)}
                              onChange={(e) => handleInputChange('displayPriority', parseInt(e.target.value) || 0)}
                              disabled={!isEditing}
                              size="small"
                              InputProps={{ inputProps: { min: 0 } }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Notes */}
                      <Grid xs={12}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                          Internal Notes
                        </Typography>
                        <TextField
                          fullWidth
                          label="Notes"
                          value={isEditing ? localEditForm.notes : (product.sp?.notes || '')}
                          onChange={(e) => handleInputChange('notes', e.target.value)}
                          disabled={!isEditing}
                          multiline
                          rows={2}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              {/* Store Data Section */}
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" color="primary" gutterBottom>Store Data</Typography>
                {Object.entries(product.storeData || {}).map(([storeId, storeData]) => (
                  <Card key={storeId} sx={{ mb: 2, backgroundColor: 'white' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                          {stores.find(s => s.id === storeId)?.name || 'Unknown Store'}
                        </Typography>
                        <Box>
                          <Chip 
                            label={`Quantity: ${storeData.sellable_quantity || 0}`}
                            color={storeData.sellable_quantity > 0 ? 'success' : 'error'}
                            size="small"
                            sx={{ mr: 1 }}
                          />
                          <IconButton 
                            size="small"
                            onClick={() => setExpandedStores(prev => ({
                              ...prev,
                              [storeId]: !prev[storeId]
                            }))}
                          >
                            {expandedStores[storeId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        </Box>
                      </Box>

                      <Collapse in={expandedStores[storeId]}>
                        <Box sx={{ mt: 2 }}>
                          <Grid container spacing={2}>
                            {/* Inventory Info */}
                            <Grid xs={12} md={6}>
                              <Card variant="outlined">
                                <CardContent>
                                  <Typography variant="subtitle2" color="primary" gutterBottom>
                                    Inventory Details
                                  </Typography>
                                  <TableContainer>
                                    <Table size="small">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>Sellable Quantity</TableCell>
                                          <TableCell>{storeData.sellable_quantity || 0}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Location</TableCell>
                                          <TableCell>
                                            {storeData.sellable_quantity_detail?.[0]?.location || 'N/A'}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Last Updated</TableCell>
                                          <TableCell>
                                            {storeData.last_updated_at ? 
                                              new Date(storeData.last_updated_at).toLocaleString() : 'N/A'}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </CardContent>
                              </Card>
                            </Grid>

                            {/* Pricing Info */}
                            <Grid xs={12} md={6}>
                              <Card variant="outlined">
                                <CardContent>
                                  <Typography variant="subtitle2" color="primary" gutterBottom>
                                    Pricing Details
                                  </Typography>
                                  <TableContainer>
                                    <Table size="small">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>Base Price</TableCell>
                                          <TableCell>${storeData.pricing?.price_sell?.toFixed(2) || '-'}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Discounted Price</TableCell>
                                          <TableCell>
                                            {storeData.pricing?.discounted_price ? 
                                              `$${storeData.pricing.discounted_price}` : 'N/A'}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </CardContent>
                              </Card>
                            </Grid>

                            {/* Active Discounts */}
                            {storeData.discounts && storeData.discounts.length > 0 && (
                              <Grid xs={12}>
                                <Card variant="outlined">
                                  <CardContent>
                                    <Typography variant="subtitle2" color="primary" gutterBottom>
                                      Active Discounts
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                      {storeData.discounts.map((discount, idx) => (
                                        <Chip
                                          key={idx}
                                          label={`${discount.discount_title} - ${
                                            discount.discount_method === 'PERCENT' ? 
                                            `${discount.discount_amount}%` : 
                                            `$${discount.discount_amount}`
                                          }`}
                                          size="small"
                                          color="primary"
                                          variant="outlined"
                                        />
                                      ))}
                                    </Box>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )}

                            {/* Lab Results */}
                            {storeData.lab_results && storeData.lab_results.length > 0 && (
                              <Grid xs={12}>
                                <Card variant="outlined">
                                  <CardContent>
                                    <Typography variant="subtitle2" color="primary" gutterBottom>
                                      Lab Results
                                    </Typography>
                                    <TableContainer>
                                      <Table size="small">
                                        <TableBody>
                                          {storeData.lab_results.map((result, idx) => (
                                            <TableRow key={idx}>
                                              <TableCell>{result.result_type}</TableCell>
                                              <TableCell>
                                                {result.amount?.maximum_value}
                                                {result.amount?.amount_type === 'PERCENTAGE' ? '%' : ' mg'}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )}
                            {/* Treez Product Data Section */}
                            <Grid xs={12}>
                              <Box sx={{ p: 2, bgcolor: 'grey.50', borderRadius: 2 }}>
                                <Typography variant="subtitle2" color="primary" gutterBottom>
                                  Treez Product Data
                                </Typography>
                                <Grid container spacing={2}>
                                  {/* Product Details */}
                                  <Grid xs={12} md={6}>
                                    <Card variant="outlined">
                                      <CardContent>
                                        <Typography variant="subtitle2" color="primary" gutterBottom>
                                          Product Details
                                        </Typography>
                                        <Grid container spacing={1}>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Name</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.name || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Brand</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.brand || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Category</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.category_type || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">UOM</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.uom || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Amount</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.amount || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Classification</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.classification || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">THC (mg)</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.total_mg_thc || '0'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">CBD (mg)</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.product_configurable_fields?.total_mg_cbd || '0'}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>

                                  {/* Lab Results */}
                                  <Grid xs={12} md={6}>
                                    <Card variant="outlined">
                                      <CardContent>
                                        <Typography variant="subtitle2" color="primary" gutterBottom>
                                          Lab Results
                                        </Typography>
                                        <Grid container spacing={1}>
                                          {storeData.lab_results?.map((result, index) => (
                                            <Grid xs={12} key={index}>
                                              <Typography variant="body2" color="text.secondary">
                                                {result.result_type.replace(/_/g, ' ')}
                                              </Typography>
                                              <Typography variant="body2">
                                                {result.amount?.maximum_value}% {result.amount?.amount_type}
                                              </Typography>
                                            </Grid>
                                          ))}
                                          {(!storeData.lab_results || storeData.lab_results.length === 0) && (
                                            <Grid xs={12}>
                                              <Typography variant="body2" color="text.secondary">
                                                No lab results available
                                              </Typography>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>

                                  {/* Pricing & Inventory */}
                                  <Grid xs={12} md={6}>
                                    <Card variant="outlined">
                                      <CardContent>
                                        <Typography variant="subtitle2" color="primary" gutterBottom>
                                          Pricing & Inventory
                                        </Typography>
                                        <Grid container spacing={1}>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Base Price</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              ${storeData.pricing?.price_sell?.toFixed(2) || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography variant="caption" color="textSecondary">Current Stock</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.sellable_quantity || '0'} units
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12}>
                                            <Typography variant="caption" color="textSecondary">Price Type</Typography>
                                            <Typography variant="body2" gutterBottom>
                                              {storeData.pricing?.price_type || '-'}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12}>
                                            <Typography variant="caption" color="textSecondary">Product Status</Typography>
                                            <Box variant="body2">
                                              <Chip 
                                                label={storeData.product_status} 
                                                color={storeData.product_status === 'ACTIVE' ? 'success' : 'default'}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid xs={12}>
                                            <Typography variant="caption" color="textSecondary">Last Updated</Typography>
                                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                              {new Date(storeData.last_updated_at).toLocaleString()}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12}>
                                            <Typography variant="caption" color="textSecondary">Product ID</Typography>
                                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                              {storeData.product_id}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>

                                  {/* Active Discounts */}
                                  {storeData.discounts && storeData.discounts.length > 0 && (
                                    <Grid xs={12}>
                                      <Card variant="outlined">
                                        <CardContent>
                                          <Typography variant="subtitle2" color="primary" gutterBottom>
                                            Active Discounts
                                          </Typography>
                                          <Grid container spacing={1}>
                                            {storeData.discounts.map((discount) => (
                                              <Grid xs={12} sm={6} md={4} key={discount.discount_id}>
                                                <Card variant="outlined" sx={{ bgcolor: 'grey.50' }}>
                                                  <CardContent>
                                                    <Typography variant="subtitle2" color="primary">
                                                      {discount.discount_title}
                                                    </Typography>
                                                    <Box sx={{ mt: 1 }}>
                                                      <Typography variant="caption" color="textSecondary">Amount</Typography>
                                                      <Typography variant="body2">
                                                        {discount.discount_amount}% {discount.discount_method}
                                                      </Typography>
                                                    </Box>
                                                    <Box sx={{ mt: 1 }}>
                                                      <Typography variant="caption" color="textSecondary">Conditions</Typography>
                                                      {discount.discount_condition_detail?.map((condition, idx) => (
                                                        <Typography key={idx} variant="body2" sx={{ fontSize: '0.8rem' }}>
                                                          • {condition.discount_condition_type}: {condition.discount_condition_value}
                                                        </Typography>
                                                      ))}
                                                    </Box>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapse>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

 

  const StoreRow = ({ store, onEdit }) => {
    const [expanded, setExpanded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [expandedStores, setExpandedStores] = useState({});
    const [localEditForm, setLocalEditForm] = useState({
      name: store.name || '',
      address: store.address || '',
      contactEmail: store.contactEmail || '',
      contactPhone: store.contactPhone || '',
      status: store.status || 'INACTIVE',
      active: store.active || false,  // Add this line
      location: store.location || { lat: 0, lng: 0 },
      hours: store.hours || {
        monday: { open: '09:00', close: '21:00', isOpen: true },
        tuesday: { open: '09:00', close: '21:00', isOpen: true },
        wednesday: { open: '09:00', close: '21:00', isOpen: true },
        thursday: { open: '09:00', close: '21:00', isOpen: true },
        friday: { open: '09:00', close: '21:00', isOpen: true },
        saturday: { open: '09:00', close: '21:00', isOpen: true },
        sunday: { open: '09:00', close: '21:00', isOpen: true }
      },
      settings: {
        delivery: store.settings?.delivery || {
          enabled: false,
          minimumOrder: 0,
          fee: 0,
          radius: 0,
          zones: []
        },
        pickup: store.settings?.pickup || {
          enabled: false,
          minimumOrder: 0
        },
        inventory: store.settings?.inventory || {
          autoSync: true,
          defaultThreshold: 3
        },
        integration: store.settings?.integration || {
          type: '',
          apiKeys: {
            clientId: '',
            apiKey: '',
            dispensaryName: ''
          }
        },
      },
      commissionRate: store.commissionRate || 0,
      featuredProducts: store.featuredProducts || [],
      timezone: store.timezone || 'America/Los_Angeles'
    });

    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (autoComplete) => {
      setAutocomplete(autoComplete);
    };

    const onPlaceChanged = () => {
      if (autocomplete !== null) {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          setLocalEditForm({
            ...localEditForm,
            address: place.formatted_address,
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            }
          });
        }
      }
    };

    const handleExpandClick = () => {
      setExpanded(!expanded);
      if (!expanded) {
        setIsEditing(false);
      }
    };

    const handleEditClick = (e) => {
      e.stopPropagation();
      setIsEditing(true);
    };

    const handleSave = async () => {
      await handleUpdateStore(store.id, localEditForm);
      setIsEditing(false);
    };

    const handleCancel = () => {
      setLocalEditForm({
        name: store.name,
        address: store.address,
        contactEmail: store.contactEmail,
        contactPhone: store.contactPhone,
        status: store.status,
        active: store.active,  // Add this line
        location: store.location,
        hours: store.hours,
        settings: store.settings,
        commissionRate: store.commissionRate,
        featuredProducts: store.featuredProducts,
        timezone: store.timezone
      });
      setIsEditing(false);
    };

    const renderAddressInput = (isEdit = false) => {
      if (!isEdit) return null;
      
      return (
        <AddressInput
          value={localEditForm.address}
          onChange={(update) => {
            setLocalEditForm(prev => ({
              ...prev,
              ...update
            }));
          }}
          isEdit={true}
        />
      );
    };

    return (
      <Card sx={{ width: '100%', mb: 2 }}>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            {store.imageUrl && (
              <img 
                src={store.imageUrl} 
                alt={store.name} 
                style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: '4px' }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleStoreImageUpload(store.id, e.target.files[0])}
              style={{ width: 100 }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" component="div">
              {store.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {store.address}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Chip 
                label={store.status || 'INACTIVE'} 
                color={store.status === 'ACTIVE' ? 'success' : 'default'}
                size="small"
                sx={{ mr: 1 }}
              />
              {store.settings?.integration?.type && (
                <Chip 
                  label={store.settings.integration.type}
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleExpandClick}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                {/* Store Active Status */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Store Status</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant="body2" sx={{ mr: 1 }}>
                          {isEditing 
                            ? (localEditForm.active ? "Active" : "Inactive")
                            : (store.active ? "Active" : "Inactive")
                          }
                        </Typography>
                        <Switch
                          checked={isEditing ? localEditForm.active : (store.active ?? false)}
                          onChange={(e) => setLocalEditForm({
                            ...localEditForm,
                            active: e.target.checked
                          })}
                          disabled={!isEditing}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Basic Information */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Basic Information</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="caption" color="textSecondary" display="block" gutterBottom>
                        Store ID
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {store.storeId || 'Not assigned'}
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        fullWidth
                        label="Store Name"
                        value={isEditing ? localEditForm.name : store.name}
                        onChange={(e) => setLocalEditForm({ ...localEditForm, name: e.target.value })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      {renderAddressInput(isEditing)}
                      {isEditing && localEditForm.location && (
                        <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
                          Latitude: {localEditForm.location.lat}, Longitude: {localEditForm.location.lng}
                        </Typography>
                      )}
                    </Grid>
                    <Grid xs={6}>
                      <Typography variant="caption" color="textSecondary">Latitude</Typography>
                      <TextField
                        fullWidth
                        type="number"
                        label="Latitude"
                        value={isEditing ? localEditForm.location.lat : store.location?.lat}
                        onChange={(e) => setLocalEditForm({ 
                          ...localEditForm, 
                          location: { ...localEditForm.location, lat: parseFloat(e.target.value) }
                        })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <Typography variant="caption" color="textSecondary">Longitude</Typography>
                      <TextField
                        fullWidth
                        type="number"
                        label="Longitude"
                        value={isEditing ? localEditForm.location.lng : store.location?.lng}
                        onChange={(e) => setLocalEditForm({ 
                          ...localEditForm, 
                          location: { ...localEditForm.location, lng: parseFloat(e.target.value) }
                        })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Contact Information */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Contact Information</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <Typography variant="caption" color="textSecondary">Email</Typography>
                      <TextField
                        fullWidth
                        value={isEditing ? localEditForm.contactEmail : store.contactEmail}
                        onChange={(e) => setLocalEditForm({ ...localEditForm, contactEmail: e.target.value })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <Typography variant="caption" color="textSecondary">Phone</Typography>
                      <TextField
                        fullWidth
                        value={isEditing ? localEditForm.contactPhone : store.contactPhone}
                        onChange={(e) => setLocalEditForm({ ...localEditForm, contactPhone: e.target.value })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Integration Settings */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Integration Settings</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="caption" color="textSecondary">Integration Type</Typography>
                      <FormControl fullWidth sx={{ mt: 1 }}>
                        <Select
                          value={isEditing ? localEditForm.settings?.integration?.type : store.settings?.integration?.type || ''}
                          onChange={(e) => setLocalEditForm({
                            ...localEditForm,
                            settings: { ...localEditForm.settings, integration: { ...localEditForm.settings.integration, type: e.target.value } }
                          })}
                          disabled={!isEditing}
                        >
                          <MenuItem value={INTEGRATION_TYPES.DUTCHIE}>Dutchie</MenuItem>
                          <MenuItem value={INTEGRATION_TYPES.TREEZ}>Treez</MenuItem>
                          <MenuItem value={INTEGRATION_TYPES.MEADOW}>Meadow</MenuItem>
                          <MenuItem value={INTEGRATION_TYPES.IHEART_JANE}>iHeartJane</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={12}>
                      <Typography variant="caption" color="textSecondary">Client ID</Typography>
                      <TextField
                        fullWidth
                        type="password"
                        value={isEditing ? localEditForm.settings?.integration?.apiKeys?.clientId : store.settings?.integration?.apiKeys?.clientId || ''}
                        onChange={(e) => setLocalEditForm({
                          ...localEditForm,
                          settings: {
                            ...localEditForm.settings,
                            integration: {
                              ...localEditForm.settings.integration,
                              apiKeys: { ...localEditForm.settings.integration.apiKeys, clientId: e.target.value }
                            }
                          }
                        })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Typography variant="caption" color="textSecondary">API Key</Typography>
                      <TextField
                        fullWidth
                        type="password"
                        value={isEditing ? localEditForm.settings?.integration?.apiKeys?.apiKey : store.settings?.integration?.apiKeys?.apiKey || ''}
                        onChange={(e) => setLocalEditForm({
                          ...localEditForm,
                          settings: {
                            ...localEditForm.settings,
                            integration: {
                              ...localEditForm.settings.integration,
                              apiKeys: { ...localEditForm.settings.integration.apiKeys, apiKey: e.target.value }
                            }
                          }
                        })}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{ mt: 1 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {localEditForm.settings?.integration?.type === INTEGRATION_TYPES.TREEZ && (
                      <Grid xs={12}>
                        <Typography variant="caption" color="textSecondary">Dispensary Name</Typography>
                        <TextField
                          fullWidth
                          value={isEditing ? localEditForm.settings?.integration?.apiKeys?.dispensaryName : store.settings?.integration?.apiKeys?.dispensaryName || ''}
                          onChange={(e) => setLocalEditForm({
                            ...localEditForm,
                            settings: {
                              ...localEditForm.settings,
                              integration: {
                                ...localEditForm.settings.integration,
                                apiKeys: { ...localEditForm.settings.integration.apiKeys, dispensaryName: e.target.value }
                              }
                            }
                          })}
                          disabled={!isEditing}
                          variant="outlined"
                          sx={{ mt: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Commission Settings */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Commission Settings</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="caption" color="textSecondary">Commission Rate</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <TextField
                          type="number"
                          label="Commission Rate"
                          value={isEditing ? localEditForm.commissionRate : (store.commissionRate ?? 9)}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === '0') return;
                            if (/^0\d+$/.test(value)) {
                              e.target.value = value.replace(/^0+/, '');
                            }
                            const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 100);
                            setLocalEditForm({
                              ...localEditForm,
                              commissionRate: numValue
                            });
                          }}
                          onKeyDown={(e) => {
                            if (!/[\d\b]/.test(e.key) && 
                                !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          disabled={!isEditing}
                          variant="outlined"
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: 1
                          }}
                          sx={{ 
                            width: '80px',
                            '& input': {
                              textAlign: 'right',
                              paddingRight: '8px'
                            },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                              opacity: 1,
                              height: '38px',
                              cursor: 'pointer'
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                          %
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Operating Hours */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Operating Hours</Typography>
                  <Grid container spacing={2}>
                    {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
                      <Grid xs={12} key={day}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Boolean(isEditing ? localEditForm.hours?.[day.toLowerCase()]?.isOpen : store.hours?.[day.toLowerCase()]?.isOpen)}
                                onChange={(e) => setLocalEditForm({
                                  ...localEditForm,
                                  hours: {
                                    ...localEditForm.hours,
                                    [day.toLowerCase()]: {
                                      ...localEditForm.hours?.[day.toLowerCase()],
                                      isOpen: e.target.checked
                                    }
                                  }
                                })}
                                disabled={!isEditing}
                              />
                            }
                            label={day}
                          />
                          {(isEditing ? localEditForm.hours?.[day.toLowerCase()]?.isOpen : store.hours?.[day.toLowerCase()]?.isOpen) && (
                            <>
                              <TextField
                                type="time"
                                value={isEditing ? localEditForm.hours?.[day.toLowerCase()]?.open : store.hours?.[day.toLowerCase()]?.open}
                                onChange={(e) => setLocalEditForm({
                                  ...localEditForm,
                                  hours: {
                                    ...localEditForm.hours,
                                    [day.toLowerCase()]: {
                                      ...localEditForm.hours?.[day.toLowerCase()],
                                      open: e.target.value
                                    }
                                  }
                                })}
                                disabled={!isEditing}
                                sx={{ width: 150 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <Typography>to</Typography>
                              <TextField
                                type="time"
                                value={isEditing ? localEditForm.hours?.[day.toLowerCase()]?.close : store.hours?.[day.toLowerCase()]?.close}
                                onChange={(e) => setLocalEditForm({
                                  ...localEditForm,
                                  hours: {
                                    ...localEditForm.hours,
                                    [day.toLowerCase()]: {
                                      ...localEditForm.hours?.[day.toLowerCase()],
                                      close: e.target.value
                                    }
                                  }
                                })}
                                disabled={!isEditing}
                                sx={{ width: 150 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/* Timezone */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Timezone</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Timezone</InputLabel>
                        <Select
                          value={isEditing ? localEditForm.timezone : store.timezone}
                          onChange={(e) => setLocalEditForm({
                            ...localEditForm,
                            timezone: e.target.value
                          })}
                          disabled={!isEditing}
                        >
                          {TIMEZONES.map((tz) => (
                            <MenuItem key={tz.id} value={tz.id}>
                              {tz.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Delivery Settings */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Delivery Settings</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(isEditing ? localEditForm.settings?.delivery?.enabled : store.settings?.delivery?.enabled)}
                            onChange={(e) => setLocalEditForm({
                              ...localEditForm,
                              settings: {
                                ...localEditForm.settings,
                                delivery: { ...localEditForm.settings.delivery, enabled: e.target.checked }
                              }
                            })}
                            disabled={!isEditing}
                          />
                        }
                        label="Enable Delivery"
                      />
                    </Grid>
                    {(isEditing ? localEditForm.settings?.delivery?.enabled : store.settings?.delivery?.enabled) && (
                      <>
                        <Grid xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                              type="number"
                              label="Minimum Order"
                              value={isEditing ? localEditForm.settings?.delivery?.minimumOrder : store.settings?.delivery?.minimumOrder}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '0') return;
                                if (/^0\d+$/.test(value)) {
                                  e.target.value = value.replace(/^0+/, '');
                                }
                                const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                setLocalEditForm({
                                  ...localEditForm,
                                  settings: {
                                    ...localEditForm.settings,
                                    delivery: { 
                                      ...localEditForm.settings.delivery, 
                                      minimumOrder: numValue 
                                    }
                                  }
                                });
                              }}
                              disabled={!isEditing}
                              variant="outlined"
                              inputProps={{
                                min: 0,
                                max: 99999,
                                step: 1
                              }}
                              sx={{ 
                                width: '80px',
                                '& input': {
                                  textAlign: 'right',
                                  paddingRight: '8px'
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                  opacity: 1,
                                  height: '38px',
                                  cursor: 'pointer'
                                }
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                              $
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                              type="number"
                              label="Delivery Fee"
                              value={isEditing ? localEditForm.settings?.delivery?.fee : store.settings?.delivery?.fee}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '0') return;
                                if (/^0\d+$/.test(value)) {
                                  e.target.value = value.replace(/^0+/, '');
                                }
                                const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                setLocalEditForm({
                                  ...localEditForm,
                                  settings: {
                                    ...localEditForm.settings,
                                    delivery: { 
                                      ...localEditForm.settings.delivery, 
                                      fee: numValue 
                                    }
                                  }
                                });
                              }}
                              disabled={!isEditing}
                              variant="outlined"
                              inputProps={{
                                min: 0,
                                max: 99999,
                                step: 1
                              }}
                              sx={{ 
                                width: '80px',
                                '& input': {
                                  textAlign: 'right',
                                  paddingRight: '8px'
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                  opacity: 1,
                                  height: '38px',
                                  cursor: 'pointer'
                                }
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                              $
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                              type="number"
                              label="Delivery Radius"
                              value={isEditing ? localEditForm.settings?.delivery?.radius : store.settings?.delivery?.radius}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '0') return;
                                if (/^0\d+$/.test(value)) {
                                  e.target.value = value.replace(/^0+/, '');
                                }
                                const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                setLocalEditForm({
                                  ...localEditForm,
                                  settings: {
                                    ...localEditForm.settings,
                                    delivery: { 
                                      ...localEditForm.settings.delivery, 
                                      radius: numValue 
                                    }
                                  }
                                });
                              }}
                              disabled={!isEditing}
                              variant="outlined"
                              inputProps={{
                                min: 0,
                                max: 99999,
                                step: 1
                              }}
                              sx={{ 
                                width: '80px',
                                '& input': {
                                  textAlign: 'right',
                                  paddingRight: '8px'
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                  opacity: 1,
                                  height: '38px',
                                  cursor: 'pointer'
                                }
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                              miles
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* Delivery Zones */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Delivery Zones</Typography>
                  <Grid container spacing={2}>
                    {isEditing && (
                      <Grid xs={12}>
                        <Button
                          variant="outlined"
                          onClick={() => setLocalEditForm({
                            ...localEditForm,
                            settings: {
                              ...localEditForm.settings,
                              delivery: {
                                ...localEditForm.settings.delivery,
                                zones: [...(localEditForm.settings.delivery?.zones || []), { zipCode: '', fee: 0 }]
                              }
                            }
                          })}
                          startIcon={<AddIcon />}
                          sx={{ mb: 2 }}
                        >
                          Add Zone
                        </Button>
                      </Grid>
                    )}
                    {(isEditing ? localEditForm.settings?.delivery?.zones : store.settings?.delivery?.zones)?.map((zone, index) => (
                      <Grid xs={12} key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <TextField
                            label="ZIP Code"
                            value={zone.zipCode}
                            onChange={(e) => {
                              const newZones = [...(localEditForm.settings.delivery?.zones || [])];
                              newZones[index] = { ...newZones[index], zipCode: e.target.value };
                              setLocalEditForm({
                                ...localEditForm,
                                settings: {
                                  ...localEditForm.settings,
                                  delivery: {
                                    ...localEditForm.settings.delivery,
                                    zones: newZones
                                  }
                                }
                              });
                            }}
                            disabled={!isEditing}
                            sx={{ width: '120px' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                              type="number"
                              label="Fee ($)"
                              value={zone.fee}
                              onChange={(e) => {
                                const newZones = [...(localEditForm.settings.delivery?.zones || [])];
                                newZones[index] = { ...newZones[index], fee: parseFloat(e.target.value) };
                                setLocalEditForm({
                                  ...localEditForm,
                                  settings: {
                                    ...localEditForm.settings,
                                    delivery: {
                                      ...localEditForm.settings.delivery,
                                      zones: newZones
                                    }
                                  }
                                });
                              }}
                              disabled={!isEditing}
                              sx={{ width: '80px' }}
                              inputProps={{
                                min: 0,
                                max: 99999,
                                step: 1
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                              $
                            </Typography>
                          </Box>
                          {isEditing && (
                            <IconButton
                              onClick={() => {
                                const newZones = [...(localEditForm.settings.delivery?.zones || [])];
                                newZones.splice(index, 1);
                                setLocalEditForm({
                                  ...localEditForm,
                                  settings: {
                                    ...localEditForm.settings,
                                    delivery: {
                                      ...localEditForm.settings.delivery,
                                      zones: newZones
                                    }
                                  }
                                });
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/* Pickup Settings */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Pickup Settings</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(isEditing ? localEditForm.settings?.pickup?.enabled : store.settings?.pickup?.enabled)}
                            onChange={(e) => setLocalEditForm({
                              ...localEditForm,
                              settings: {
                                ...localEditForm.settings,
                                pickup: { ...localEditForm.settings.pickup, enabled: e.target.checked }
                              }
                            })}
                            disabled={!isEditing}
                          />
                        }
                        label="Enable Pickup"
                      />
                    </Grid>
                    {(isEditing ? localEditForm.settings?.pickup?.enabled : store.settings?.pickup?.enabled) && (
                      <Grid xs={6}>
                        <Typography variant="caption" color="textSecondary">Minimum Order ($)</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <TextField
                            type="number"
                            label="Minimum Order"
                            value={isEditing ? localEditForm.settings?.pickup?.minimumOrder : store.settings?.pickup?.minimumOrder}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '0') return;
                              if (/^0\d+$/.test(value)) {
                                e.target.value = value.replace(/^0+/, '');
                              }
                              const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                              setLocalEditForm({
                                ...localEditForm,
                                settings: {
                                  ...localEditForm.settings,
                                  pickup: { 
                                    ...localEditForm.settings.pickup, 
                                    minimumOrder: numValue 
                                  }
                                }
                              });
                            }}
                            disabled={!isEditing}
                            variant="outlined"
                            inputProps={{
                              min: 0,
                              max: 99999,
                              step: 1
                            }}
                            sx={{ 
                              width: '80px',
                              '& input': {
                                textAlign: 'right',
                                paddingRight: '8px'
                              },
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                opacity: 1,
                                height: '38px',
                                cursor: 'pointer'
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                            $
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Featured Products */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Featured Products</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      {/* We'll need to implement a product selector here */}
                      <Typography variant="caption" color="textSecondary">
                        Featured products selection will be implemented in a future update
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Inventory Settings */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Inventory Settings</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(isEditing ? localEditForm.settings?.inventory?.autoSync : store.settings?.inventory?.autoSync)}
                            onChange={(e) => setLocalEditForm({
                              ...localEditForm,
                              settings: {
                                ...localEditForm.settings,
                                inventory: { ...localEditForm.settings.inventory, autoSync: e.target.checked }
                              }
                            })}
                            disabled={!isEditing}
                          />
                        }
                        label="Auto-sync Inventory"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                          type="number"
                          label="Default Threshold"
                          value={isEditing ? localEditForm.settings?.inventory?.defaultThreshold : store.settings?.inventory?.defaultThreshold}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === '0') return;
                            if (/^0\d+$/.test(value)) {
                              e.target.value = value.replace(/^0+/, '');
                            }
                            const numValue = Math.min(Math.max(parseInt(value) || 0, 0), 999);
                            setLocalEditForm({
                              ...localEditForm,
                              settings: {
                                ...localEditForm.settings,
                                inventory: { 
                                  ...localEditForm.settings.inventory, 
                                  defaultThreshold: numValue 
                                }
                              }
                            });
                          }}
                          disabled={!isEditing}
                          variant="outlined"
                          inputProps={{
                            min: 0,
                            max: 999,
                            step: 1
                          }}
                          sx={{ 
                            width: '120px',
                            '& input': {
                              textAlign: 'right',
                              paddingRight: '8px'
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          helperText="Minimum quantity to show in store"
                        />
                        <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                          items
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Last Synced */}
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Integration Status</Typography>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="body2">
                        Last Synced: {store.lastSyncedAt ? new Date(store.lastSyncedAt).toLocaleString() : 'Never'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {isEditing && (
                  <Grid xs={12}>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                      <Button onClick={handleCancel}>CANCEL</Button>
                      <Button variant="contained" onClick={handleSave}>SAVE</Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  const handleAddStore = () => {
    setEditingStore(null);
    setStoreForm({
      name: '',
      contactEmail: '',
      contactPhone: '',
      hours: {
        monday: { isOpen: false, open: '09:00', close: '21:00' },
        tuesday: { isOpen: false, open: '09:00', close: '21:00' },
        wednesday: { isOpen: false, open: '09:00', close: '21:00' },
        thursday: { isOpen: false, open: '09:00', close: '21:00' },
        friday: { isOpen: false, open: '09:00', close: '21:00' },
        saturday: { isOpen: false, open: '09:00', close: '21:00' },
        sunday: { isOpen: false, open: '09:00', close: '21:00' }
      },
      settings: {  // New structure
        delivery: {  // Moved from deliverySettings
          enabled: false,
          minimumOrder: '',
          fee: '',
          radius: '',
          zones: []
        },
        pickup: {  // Moved from pickupSettings
          enabled: false,
          minimumOrder: ''
        },
        inventory: {  // Add new inventory settings
          autoSync: true,
          defaultThreshold: 3
        },
        integration: {
          type: '',
          apiKeys: {
            clientId: '',
            apiKey: '',
            dispensaryName: ''
          }
        },
      },
      commissionRate: '',
      status: 'ACTIVE',
      timezone: 'America/Los_Angeles'
    });
    setStoreDialogOpen(true);
  };

  const handleEditStore = (store) => {
    setEditingStore(store);
    setStoreForm({
      name: store.name,
      address: store.address,
      contactEmail: store.contactEmail,
      contactPhone: store.contactPhone,
      active: store.active,  // Add this line
      hours: store.hours,
      settings: {
        delivery: store.settings?.delivery || {
          enabled: false,
          minimumOrder: 0,
          fee: 0,
          radius: 0,
          zones: []
        },
        pickup: store.settings?.pickup || {
          enabled: false,
          minimumOrder: 0
        },
        inventory: store.settings?.inventory || {
          autoSync: true,
          defaultThreshold: 3
        },
        integration: store.settings?.integration || {
          type: '',
          apiKeys: {
            clientId: '',
            apiKey: '',
            dispensaryName: ''
          }
        },
      },
      commissionRate: store.commissionRate,
      status: store.status,
      timezone: store.timezone
    });
    setStoreDialogOpen(true);
  };

  const handleStoreFormChange = (path, value) => {
    setStoreForm(prev => {
      const newForm = { ...prev };
      const parts = path.split('.');
      let target = newForm;
      
      for (let i = 0; i < parts.length - 1; i++) {
        if (!target[parts[i]]) {
          target[parts[i]] = {};
        }
        target = target[parts[i]];
      }
      
      target[parts[parts.length - 1]] = value;
      return newForm;
    });
  };

  const handleSaveStore = async () => {
    try {
      if (editingStore) {
        await handleUpdateStore(editingStore.id, storeForm);
      } else {
        await handleCreateStore();
      }
      setStoreDialogOpen(false);
      fetchStores();
    } catch (error) {
      console.error('Error saving store:', error);
    }
  };

  const handleCreateStore = async () => {
    try {
      setLoading(true);
      await createStoreWithInventory(storeForm);
      await fetchStores();
      setStoreDialogOpen(false);
      // Reset form...
    } catch (error) {
      console.error('Error creating store:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStore = async (storeId, updates) => {
    try {
      setLoading(true);
      await updateStore(storeId, updates);
      await fetchStores();
    } catch (error) {
      console.error('Error updating store:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseStoreDialog = () => {
    setStoreDialogOpen(false);
  };

  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [store, setStore] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);
  const [loadError, setLoadError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = (autoComplete) => {
    console.log('Autocomplete loaded successfully');
    setAutocomplete(autoComplete);
  };

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setEditForm(prev => ({ ...prev, address: place.formatted_address, location: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } }));
      }
    }
  };

  const renderAddressInput = (isEdit = false) => (
    <AddressInput
      value={isEdit ? editForm.address : storeForm.address}
      onChange={(update) => {
        if (isEdit) {
          setEditForm(prev => ({ ...prev, ...update }));
        } else {
          setStoreForm(prev => ({ ...prev, ...update }));
        }
      }}
      isEdit={isEdit}
    />
  );

  const handleOpenStoreDialog = () => {
    setStoreForm({
      name: '',
      contactEmail: '',
      contactPhone: '',
      hours: {
        monday: { isOpen: false, open: '09:00', close: '21:00' },
        tuesday: { isOpen: false, open: '09:00', close: '21:00' },
        wednesday: { isOpen: false, open: '09:00', close: '21:00' },
        thursday: { isOpen: false, open: '09:00', close: '21:00' },
        friday: { isOpen: false, open: '09:00', close: '21:00' },
        saturday: { isOpen: false, open: '09:00', close: '21:00' },
        sunday: { isOpen: false, open: '09:00', close: '21:00' }
      },
      settings: {  // New structure
        delivery: {  // Moved from deliverySettings
          enabled: false,
          minimumOrder: '',
          fee: '',
          radius: '',
          zones: []
        },
        pickup: {  // Moved from pickupSettings
          enabled: false,
          minimumOrder: ''
        },
        inventory: {  // Add new inventory settings
          autoSync: true,
          defaultThreshold: 3
        },
        integration: {
          type: '',
          apiKeys: {
            clientId: '',
            apiKey: '',
            dispensaryName: ''
          }
        },
      },
      commissionRate: '',
      status: 'ACTIVE',
      timezone: 'America/Los_Angeles'
    });
    setStoreDialogOpen(true);
  };

  const handleTestFrontOfHouse = async () => {
    // try {
    //   await fetchFrontOfHouseProducts();
    //   // No further processing needed - function will console.log the data
    // } catch (error) {
    //   console.error('Error testing Front of House products:', error);
    // }
  };

  if (loading) {
    return (
      <div style={{ 
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2rem'
      }}>
        <img
          style={{ width: '120px', height: 'auto' }}
          loading="lazy"
          alt=""
          src="/big-logo-traced.svg"
        />
        <CircularProgress style={{ color: '#FF69B4' }} size={40} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        p: 3, 
        minHeight: '100vh',
        background: 'linear-gradient(135deg, rgba(31, 191, 255, 0.05) 0%, rgba(245, 0, 163, 0.05) 100%)'
      }}>
        <Box sx={{ p: 3 }}>
          {/* Header with Logo and Text */}
          <Box className="big-logo-traced-group" sx={{ 
            display: 'flex', 
            alignItems: 'center',
            mb: 4,
            gap: 2
          }}>
            <img
              className="big-logo-traced3"
              loading="lazy"
              alt=""
              src="/big-logo-traced.svg"
            />
            <Box className="your-stash-wrapper">
              <Typography 
                className="your-stash"
                variant="h4" 
                component="div"
              >
                Stash Pandas Admin
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mb: 4, mt: 2 }}>
            <Typography variant="h4" gutterBottom>
              Admin Panel
            </Typography>
            
            {/* View Toggle */}
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={(e, newView) => newView && setView(newView)}
              sx={{ mb: 3 }}
            >
              <ToggleButton value="products">
                Products
              </ToggleButton>
              <ToggleButton value="stores">
                Stores
              </ToggleButton>
            </ToggleButtonGroup>

            {/* Test Functions */}
            <Box sx={{ mb: 3 }}>
              <Button
                variant="contained"
                onClick={handleTestFrontOfHouse}
                sx={{ 
                  backgroundColor: '#FF69B4',
                  '&:hover': {
                    backgroundColor: '#FF1493',
                  }
                }}
              >
                Test Front of House Products
              </Button>
            </Box>

            {/* Products View */}
            {view === 'products' ? (
              <Grid container spacing={1}>
                {products.map((product) => (
                  <Grid item xs={12} key={product.id}>
                    <Card sx={{ 
                      position: 'relative',
                      zIndex: expandedProductId === product.id ? 2 : 1,
                      boxShadow: expandedProductId === product.id ? 8 : 1
                    }}>
                      <Collapse in={!expandedProductId || expandedProductId !== product.id} unmountOnExit>
                        <ProductRowBasic 
                          product={product}
                          isExpanded={expandedProductId === product.id}
                          onToggleExpand={() => handleProductExpandClick(product.id)}
                        />
                      </Collapse>
                      
                      <Collapse in={expandedProductId === product.id && expandedProductData[product.id]} unmountOnExit>
                        {loadingExpandedData && product.id === expandedProductId && (
                          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <CircularProgress size={24} />
                          </Box>
                        )}
                        <ProductRowExpanded 
                          product={expandedProductData[product.id] || product}
                          isExpanded={expandedProductId === product.id}
                          onToggleExpand={() => handleProductExpandClick(product.id)}
                          onEdit={() => handleEdit(product.id)}
                        />
                      </Collapse>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              // New stores view
              <Box sx={{ width: '100%', mt: 3 }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddStore}
                  sx={{ mb: 3 }}
                >
                  Add Store
                </Button>
                <Box>
                  {stores.map((store) => (
                    <StoreRow
                      key={store.id}
                      store={store}
                      onEdit={handleEditStore}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          
          {/* Store Edit Dialog */}
          <Dialog 
            open={storeDialogOpen && !editingStore} 
            onClose={handleCloseStoreDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Add New Store</DialogTitle>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  {/* Basic Information */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" gutterBottom>Basic Information</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <TextField
                          fullWidth
                          label="Store Name"
                          value={storeForm.name}
                          onChange={(e) => handleStoreFormChange('name', e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          fullWidth
                          label="Contact Email"
                          value={storeForm.contactEmail}
                          onChange={(e) => handleStoreFormChange('contactEmail', e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          fullWidth
                          label="Contact Phone"
                          value={storeForm.contactPhone}
                          onChange={(e) => handleStoreFormChange('contactPhone', e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Integration Type</InputLabel>
                          <Select
                            value={storeForm.settings?.integration?.type}
                            onChange={(e) => handleStoreFormChange('settings.integration.type', e.target.value)}
                            label="Integration Type"
                          >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.DUTCHIE}>Dutchie</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.TREEZ}>Treez</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.MEADOW}>Meadow</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.IHEART_JANE}>iHeartJane</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Operating Hours */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Operating Hours</Typography>
                    <Grid container spacing={2}>
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                        <Grid xs={12} key={day}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Boolean(storeForm.hours[day.toLowerCase()]?.isOpen)}
                                  onChange={(e) => handleStoreFormChange(`hours.${day.toLowerCase()}.isOpen`, e.target.checked)}
                                />
                              }
                              label={day}
                            />
                            {storeForm.hours[day.toLowerCase()]?.isOpen && (
                              <>
                                <TextField
                                  type="time"
                                  value={storeForm.hours[day.toLowerCase()]?.open}
                                  onChange={(e) => handleStoreFormChange(`hours.${day.toLowerCase()}.open`, e.target.value)}
                                  sx={{ width: 150 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <Typography>to</Typography>
                                <TextField
                                  type="time"
                                  value={storeForm.hours[day.toLowerCase()]?.close}
                                  onChange={(e) => handleStoreFormChange(`hours.${day.toLowerCase()}.close`, e.target.value)}
                                  sx={{ width: 150 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {/* Delivery Settings */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Delivery Settings</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={Boolean(storeForm.settings?.delivery?.enabled)}
                              onChange={(e) => handleStoreFormChange('settings.delivery.enabled', e.target.checked)}
                            />
                          }
                          label="Enable Delivery"
                        />
                      </Grid>
                      {storeForm.settings?.delivery?.enabled && (
                        <>
                          <Grid xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <TextField
                                type="number"
                                label="Minimum Order"
                                value={storeForm.settings?.delivery?.minimumOrder}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === '0') return;
                                  if (/^0\d+$/.test(value)) {
                                    e.target.value = value.replace(/^0+/, '');
                                  }
                                  const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                  setStoreForm({
                                    ...storeForm,
                                    settings: {
                                      ...storeForm.settings,
                                      delivery: { 
                                        ...storeForm.settings.delivery, 
                                        minimumOrder: numValue 
                                      }
                                    }
                                  });
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                sx={{ width: 200 }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <TextField
                                type="number"
                                label="Delivery Fee"
                                value={storeForm.settings?.delivery?.fee}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === '0') return;
                                  if (/^0\d+$/.test(value)) {
                                    e.target.value = value.replace(/^0+/, '');
                                  }
                                  const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                  setStoreForm({
                                    ...storeForm,
                                    settings: {
                                      ...storeForm.settings,
                                      delivery: { 
                                        ...storeForm.settings.delivery, 
                                        fee: numValue 
                                      }
                                    }
                                  });
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                sx={{ width: 200 }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <TextField
                                type="number"
                                label="Delivery Radius"
                                value={storeForm.settings?.delivery?.radius}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === '0') return;
                                  if (/^0\d+$/.test(value)) {
                                    e.target.value = value.replace(/^0+/, '');
                                  }
                                  const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                  setStoreForm({
                                    ...storeForm,
                                    settings: {
                                      ...storeForm.settings,
                                      delivery: { 
                                        ...storeForm.settings.delivery, 
                                        radius: numValue 
                                      }
                                    }
                                  });
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">miles</InputAdornment>
                                }}
                                sx={{ width: 200 }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  {/* Pickup Settings */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Pickup Settings</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={Boolean(storeForm.settings?.pickup?.enabled)}
                              onChange={(e) => handleStoreFormChange('settings.pickup.enabled', e.target.checked)}
                            />
                          }
                          label="Enable Pickup"
                        />
                      </Grid>
                      {storeForm.settings?.pickup?.enabled && (
                        <Grid xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                              type="number"
                              label="Minimum Order"
                              value={storeForm.settings?.pickup?.minimumOrder}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '0') return;
                                if (/^0\d+$/.test(value)) {
                                  e.target.value = value.replace(/^0+/, '');
                                }
                                const numValue = Math.min(Math.max(parseFloat(value) || 0, 0), 99999);
                                setStoreForm({
                                  ...storeForm,
                                  settings: {
                                    ...storeForm.settings,
                                    pickup: { 
                                      ...storeForm.settings.pickup, 
                                      minimumOrder: numValue 
                                    }
                                  }
                                });
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }}
                              sx={{ width: 200 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* Integration Settings */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Integration Settings</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Integration Type</InputLabel>
                          <Select
                            value={storeForm.settings?.integration?.type}
                            onChange={(e) => handleStoreFormChange('settings.integration.type', e.target.value)}
                            label="Integration Type"
                          >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.DUTCHIE}>Dutchie</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.TREEZ}>Treez</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.MEADOW}>Meadow</MenuItem>
                            <MenuItem value={INTEGRATION_TYPES.IHEART_JANE}>iHeartJane</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          fullWidth
                          label="Client ID"
                          value={storeForm.settings?.integration?.apiKeys?.clientId}
                          onChange={(e) => handleStoreFormChange('settings.integration.apiKeys.clientId', e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          fullWidth
                          label="API Key"
                          value={storeForm.settings?.integration?.apiKeys?.apiKey}
                          onChange={(e) => handleStoreFormChange('settings.integration.apiKeys.apiKey', e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      {storeForm.settings?.integration?.type === INTEGRATION_TYPES.TREEZ && (
                        <Grid xs={12}>
                          <TextField
                            fullWidth
                            label="Dispensary Name"
                            value={storeForm.settings?.integration?.apiKeys?.dispensaryName}
                            onChange={(e) => handleStoreFormChange('settings.integration.apiKeys.dispensaryName', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* Commission Rate */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Commission Settings</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <TextField
                            type="number"
                            label="Commission Rate"
                            value={storeForm.commissionRate}
                            onChange={(e) => handleStoreFormChange('commissionRate', e.target.value)}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            sx={{ width: 200 }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Timezone */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Timezone</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Timezone</InputLabel>
                          <Select
                            value={storeForm.timezone}
                            onChange={(e) => handleStoreFormChange('timezone', e.target.value)}
                            label="Timezone"
                          >
                            {TIMEZONES.map((tz) => (
                              <MenuItem key={tz.id} value={tz.id}>
                                {tz.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Inventory Settings */}
                  <Grid xs={12}>
                    <Typography variant="subtitle1" sx={{ mt: 3 }} gutterBottom>Inventory Settings</Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={Boolean(storeForm.settings?.inventory?.autoSync)}
                              onChange={(e) => handleStoreFormChange('settings.inventory.autoSync', e.target.checked)}
                            />
                          }
                          label="Auto-sync Inventory"
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <TextField
                            type="number"
                            label="Default Threshold"
                            value={storeForm.settings?.inventory?.defaultThreshold}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value === '0') return;
                              if (/^0\d+$/.test(value)) {
                                e.target.value = value.replace(/^0+/, '');
                              }
                              const numValue = Math.min(Math.max(parseInt(value) || 0, 0), 999);
                              setStoreForm({
                                ...storeForm,
                                settings: {
                                  ...storeForm.settings,
                                  inventory: { 
                                    ...storeForm.settings.inventory, 
                                    defaultThreshold: numValue 
                                  }
                                }
                              });
                            }}
                            InputProps={{
                              min: 0,
                              max: 999,
                              step: 1
                            }}
                            sx={{ 
                              width: '120px',
                              '& input': {
                                textAlign: 'right',
                                paddingRight: '8px'
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText="Minimum quantity to show in store"
                          />
                          <Typography variant="body1" sx={{ ml: 1, minWidth: '24px' }}>
                            items
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStoreDialog}>Cancel</Button>
              <Button variant="contained" onClick={handleSaveStore}>Save</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminPanel;
