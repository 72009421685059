import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkRole } from '../../utils/FirebaseUtils';

const ProtectedRoute = ({ children, requiredRole }) => {
  const user = useSelector(state => state.user);
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAccess = async () => {
      try {
        // If no user or no userId, not authorized
        if (!user?.userId) {
          setIsAuthorized(false);
          setIsLoading(false);
          return;
        }

        // First check the role in Redux store
        if (user.role === requiredRole) {
          setIsAuthorized(true);
          setIsLoading(false);
          return;
        }

        // If role doesn't match, double-check with backend
        const roleData = await checkRole(user.userId);
        console.log('Role check response:', roleData); // Debug log
        
        // Check if user has required role
        setIsAuthorized(roleData?.role === requiredRole);
      } catch (error) {
        console.error('Error verifying access:', error);
        setIsAuthorized(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAccess();
  }, [user, requiredRole]);

  // Show loading state
  if (isLoading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <div className="spinner"></div>
      </div>
    );
  }

  // If not authorized, redirect to home page with the intended location stored
  if (!isAuthorized) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // If authorized, render the protected component
  return children;
};

export default ProtectedRoute;
