import { useState, useEffect } from "react";
import "./CategorySelect.css";

const categories = [
  { src: "/marijuana-1@2x.png", alt: "Flower", title: "Flower", className: "flower", productType: "FLOWER" },
  { src: "/vaping-1-2@2x.png", alt: "Vapes", title: "Vapes", className: "vapes", productType: "CARTRIDGE" },
  { src: "/chocolatebar-2@2x.png", alt: "Edibles", title: "Edibles", className: "edibles", productType: "EDIBLE" },
  { src: "/man-1@2x.png", alt: "Pre-Rolls", title: "Pre-Rolls", className: "pre-rolls", productType: "PREROLL" },
  { src: "/wax.png", alt: "Extracts", title: "Extracts", className: "extracts", productType: "EXTRACT" },
];

const CategorySelect = ({ 
  setSelectedProductType, 
  selectedProductType,
  availableCategories,
  seeAll, 
  setSeeAll, 
  handleBackClick 
}) => {
  // Ensure availableCategories is a Set
  const categoriesSet = availableCategories instanceof Set ? availableCategories : new Set(availableCategories || []);
  console.log('Critical debug - Categories:', {
    availableCategories: Array.from(categoriesSet),
    extractAvailable: categoriesSet.has('EXTRACT'),
    setType: availableCategories instanceof Set ? 'Set' : 'Other'
  });

  console.log('CategorySelect Debug:', {
    rawAvailableCategories: availableCategories,
    isSet: availableCategories instanceof Set,
    asArray: Array.from(categoriesSet),
    extractCheck: {
      inRaw: availableCategories instanceof Set ? availableCategories.has('EXTRACT') : Array.from(availableCategories).includes('EXTRACT'),
      inProcessed: categoriesSet.has('EXTRACT')
    }});

  // console.log('=== CategorySelect Debug ===');
  // console.log('Raw Available Categories:', availableCategories);
  // console.log('Categories Set:', categoriesSet);
  // console.log('Selected Type:', selectedProductType);
  // console.log('Categories Array:', categories.map(c => ({
  //   title: c.title,
  //   productType: c.productType,
  //   available: categoriesSet.has(c.productType),
  //   matchingCategories: Array.from(categoriesSet).filter(cat => cat === c.productType)
  // })));

  const handleCategoryClick = (category) => {
    if (categoriesSet.has(category.productType)) {
      setSelectedProductType(category.productType);
    }
  };

  return (
    <div className="category-select-container">
      <div className="category-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`category-item ${category.className} ${
              selectedProductType === category.productType ? 'selected' : ''
            } ${!categoriesSet.has(category.productType) ? 'disabled' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            <div className="shine-container">
              <div className="shine-1"></div>
              <div className="shine-2"></div>
            </div>
            <img
              className="category-icon"
              loading="lazy"
              alt={category.alt}
              src={category.src}
            />
            <div className="category-title">{category.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySelect;
