// rootReducer.js
import { combineReducers } from 'redux';

import userReducer from './userReducer';
import stripeReducer from './stripeReducer';
import orderReducer from './orderReducer';
import productReducer from './productReducer';

const rootReducer = combineReducers({
  user: userReducer,
  stripe: stripeReducer,
  order: orderReducer,
  products: productReducer
});

export default rootReducer;
