import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import { IonIcon } from '@ionic/react';
import { caretDown, caretUp, cardOutline, helpCircleOutline } from 'ionicons/icons';

import LocationFull from "./LocationFull";
import ConfirmButton from "./ConfirmButton";
import CancelButton from "./CancelButton";
import QuantityCounter from "./QuantityCounter";
import VerificationForm from "./VerificationForm";
import ModalHeader from "./ModalHeader";
import ItemDetailsModal from "./ItemDetailsModal";
import OrderDetails from "./OrderDetails";
import PortalPopup from "./PortalPopup";

import { updateCartItemQuantity, addProductToCart, createOrder, removeFromCart } from '../../redux/actions/orderActions';
import { previewTreezTicket, createTreezTicket } from '../../utils/TreezService';

import "./BuyNowModal.css";

const BuyNowModal = ({ onClose, product, currentPrice, originalPrice, onOpenItemDetails, onPurchaseComplete, initialQuantity = 1 }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const cartItems = useSelector(state => state.order.cart);
  
  // Initialize state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(false);
  const [isOrderDetailsExpanded, setIsOrderDetailsExpanded] = useState(false);
  const [orderTotal, setOrderTotal] = useState("0.00");
  
  // Get initial quantity from cart or use default
  const cartItem = cartItems.find(item => item.id === product.id);
  const [quantity, setQuantity] = useState(cartItem?.quantity || initialQuantity);

  // Add to cart when modal opens if not already in cart
  useEffect(() => {
    console.log('buy now modal', product)
    if (!cartItem) {
      dispatch(addProductToCart(product));
    }
  }, []);

  // Update quantity when cart changes
  useEffect(() => {
    const updatedCartItem = cartItems.find(item => item.id === product.id);
    if (updatedCartItem) {
      setQuantity(updatedCartItem.quantity);
    }
  }, [cartItems]);

  // Initialize purchaseStatus based on user verification
  useEffect(() => {
    if (user && user.purchaseStatus) {
      setPurchaseStatus(true);
    }
  }, [user]);

  // Calculate total including taxes
  const calculateTotal = () => {
    const price = product.pricing.discounted_price || product.pricing.price_sell;
    const subtotal = price * quantity;
    const laCannabisRate = 0.10;
    const caExciseRate = 0.15;
    const salesTaxRate = 0.0975;

    const laCannabisCity = subtotal * laCannabisRate;
    const caExcise = subtotal * caExciseRate;
    const salesTax = subtotal * salesTaxRate;
    const total = subtotal + laCannabisCity + caExcise + salesTax;

    return total.toFixed(2);
  };

  // Update total when quantity changes
  useEffect(() => {
    setOrderTotal(calculateTotal());
  }, [quantity, product.pricing]);

  // Check if product is undefined
  if (!product) {
    console.error('Product is undefined in BuyNowModal');
    return null;
  }

  console.log('user', user)

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
    dispatch(updateCartItemQuantity(null, product.id, newQuantity));
  };

  const totalPrice = (product.price * quantity).toFixed(2);

  const handleVerifyAccount = (formData) => {
    // For now, just log the form data
    console.log('[BuyNowModal] Verification form submitted:', formData);
    handleVerificationSuccess();
  };

  const handleVerificationSuccess = () => {
    setPurchaseStatus(true);
    setShowVerificationForm(false);
  };

  const handleConfirmPurchaseNew = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      console.log("[BuyNowModal] Creating Treez ticket:", ticketData);
      const result = await createTreezTicket(ticketData);
      
      if (result.resultCode === 'SUCCESS') {
        const price = product.pricing.discounted_price || product.pricing.price_sell;
        const totalAmount = parseFloat((price * quantity).toFixed(2));
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [{
            productId: product.id,
            quantity: quantity,
            price: price,
          }],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
          isDirectPurchase: true 
        };

        const orderId = await dispatch(createOrder(orderData, result));
        console.log("Order successfully created in Firestore with ID:", orderId);

        // Only remove this product from cart if it exists there
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }
        onPurchaseComplete()
        onClose();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewConfirmPurchaseNew = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      console.log("[BuyNowModal] Previewing Treez ticket:", ticketData);
      const previewResult = await previewTreezTicket(ticketData);
      
      if (previewResult.resultCode === 'SUCCESS') {
        const price = product.pricing.discounted_price || product.pricing.price_sell;
        const totalAmount = parseFloat((price * quantity).toFixed(2));
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [{
            productId: product.id,
            quantity: quantity,
            price: price,
          }],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
          isDirectPurchase: true 
        };

        const orderId = await dispatch(createOrder(orderData, previewResult));
        console.log("Order successfully created in Firestore with ID:", orderId);

        // Only remove this product from cart if it exists there
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }
        onPurchaseComplete()
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPurchaseOld = async () => {
    console.log("Confirming purchase for product:", product);
    setIsLoading(true);
    setError(null);
    
    try {
      const ticketData = {
        type: "PICKUP", // or "DELIVERY" based on your app's logic
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            // location_name: "STASHPANDA",
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      
      const result = await createTreezTicket(ticketData);
      console.log("[BuyNowModal] Created Treez ticket:", result);
      
      if (result.resultCode === 'SUCCESS') {
        // Calculate total amount
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        // Create an object with all fields, filtering out undefined values
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [
            {
              productId: product.id,
              quantity: quantity,
              price: product.price ,
            }
          ],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
        };

        // Remove any fields with undefined values
        Object.keys(orderData).forEach(key => {
          if (orderData[key] === undefined) {
            delete orderData[key];
          }
        });

        console.log("[BuyNowModal] Creating order:", orderData);

        // Now add the document with cleaned data
        await addDoc(collection(db, 'orders'), orderData);
        
        console.log("[BuyNowModal] Order created successfully");
        // Call onPurchaseComplete before closing the modal
        onPurchaseComplete && onPurchaseComplete();
        onClose();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process your purchase. Please try again.");
      setIsLoading(false);
    }
  };

  const handleConfirmPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      console.log("[BuyNowModal] Previewing Treez ticket:", ticketData);
      const result = await createTreezTicket(ticketData);
      
      if (result.resultCode === 'SUCCESS') {
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [{
            productId: product.id,
            quantity: quantity,
            price: product.price,
          }],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
        };

        const orderId = await dispatch(createOrder(orderData, result));
        console.log("Order successfully created in Firestore with ID:", orderId);

        // Remove item from cart if it was added
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }

        // onPurchaseComplete();
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };


  const handlePreviewConfirmPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      console.log("[BuyNowModal] Previewing Treez ticket:", ticketData);
      const previewResult = await previewTreezTicket(ticketData);
      
      if (previewResult.resultCode === 'SUCCESS') {
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [{
            productId: product.id,
            quantity: quantity,
            price: product.price,
          }],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
        };

        const orderId = await dispatch(createOrder(orderData, previewResult));
        console.log("Order successfully created in Firestore with ID:", orderId);

        // Remove item from cart if it was added
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }

        // onPurchaseComplete();
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewConfirmPurchaseOld = async () => {
    console.log("Confirming purchase for product:", product);
    setIsLoading(true);
    setError(null);
    
    try {
      const ticketData = {
        type: "PICKUP", // or "DELIVERY" based on your app's logic
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            // location_name: "STASHPANDA",
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      
      const previewResult = await previewTreezTicket(ticketData);
      console.log("[BuyNowModal] Previewing Treez ticket:", previewResult);
      
      if (previewResult.resultCode === 'SUCCESS') {
        // Calculate total amount
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        // Create an object with all fields, filtering out undefined values
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [
            {
              productId: product.id,
              quantity: quantity,
              price: product.price ,
            }
          ],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
        };

        // Remove any fields with undefined values
        Object.keys(orderData).forEach(key => {
          if (orderData[key] === undefined) {
            delete orderData[key];
          }
        });

        console.log("[BuyNowModal] Creating order:", orderData);

        // Now add the document with cleaned data
        await addDoc(collection(db, 'orders'), orderData);
        
        console.log("[BuyNowModal] Order created successfully");
        onPurchaseComplete && onPurchaseComplete(); // Call onPurchaseComplete before closing the modal
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process your purchase. Please try again.");
      setIsLoading(false);
    }
  };

  const lineageLabel = useMemo(() => {
    if (product.classification) {
      switch (product.classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: 'var(--color-deepskyblue-100)' };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: 'var(--color-sativa-yellow)' }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: 'var(--color-deeppink-300)' };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: 'var(--color-hybrid-green)'};
        default:
          return null;
      }
    }
    return null;
  }, [product.classification]);

  return (
    <>
      <div className="buy-now-modal">
        <section className="buy-now-modal-content">
          <div className="buy-now-modal-header">
            <ModalHeader 
              onClose={onClose} 
              title={showVerificationForm ? "Verify Account" : "Complete Purchase"}
            />
          </div>

          {showVerificationForm ? (
            <VerificationForm 
              onSubmit={handleVerifyAccount} 
              onVerificationSuccess={handleVerificationSuccess} 
            />
          ) : (
            <>
              <div className="product-info-container">
                <div 
                  className="buy-now-image-container" 
                  onClick={() => {
                    onClose();
                    onOpenItemDetails && onOpenItemDetails(product, currentPrice, originalPrice);
                  }}
                >
                  {product.discountedPrice && product.price && (
                    <div className="discount-label">
                      {Math.round(((product.price - product.discountedPrice) / product.price) * 100)}% OFF
                    </div>
                  )}
                  {lineageLabel && (
                    <div 
                      className="lineage-label" 
                      style={{ backgroundColor: lineageLabel.backgroundColor }}
                    >
                      {lineageLabel.text}
                    </div>
                  )}
                  <img
                    className="expand-icon"
                    src="/informationbutton-1-traced.svg"
                    alt="Expand"
                  />
                  <img
                    className="buy-now-product-image"
                    loading="lazy"
                    alt="Product"
                    src={product.imageUrl || '/default-product-image.png'}
                  />
                </div>
                <div className="item-info-container">
                  <div 
                    className="product-name"
                    onClick={() => {
                      onClose();
                      onOpenItemDetails && onOpenItemDetails(product, currentPrice, originalPrice);
                    }}
                  >
                    {product.name}
                  </div>
                  <div className="product-brand">{product.brand}</div>
                  <div className="product-lineage">{product.lineage}</div>
                  <div className="price-container">
                    <div className="current-price">${Number(product.pricing.discounted_price).toFixed(2)}</div>
                    <div className="original-price">${Number(product.pricing.price_sell).toFixed(2)}</div>
                  </div>
                  <QuantityCounter 
                    inventoryCount={product.product_configurable_fields?.sellable_quantity || product.sellableQuantity || 999999} 
                    onQuantityChange={handleQuantityChange}
                    initialQuantity={quantity}
                    isBuyNow={true}
                    align="left"
                  />  
                </div>
              </div>
              <div className="divider" />
              <div className="details-container">
                <LocationFull />
                <div className="divider" />
                <div className="order-details-section">
                  <div 
                    className={`order-details-header mobile-only ${isOrderDetailsExpanded ? 'expanded' : ''}`}
                    onClick={() => setIsOrderDetailsExpanded(!isOrderDetailsExpanded)}
                  >
                    <span>Order Details - ${orderTotal} incl. taxes</span>
                    <IonIcon 
                      icon={isOrderDetailsExpanded ? caretUp : caretDown} 
                      className="order-details-caret"
                    />
                  </div>
                  <div className={`order-details-content ${isOrderDetailsExpanded ? 'expanded' : ''}`}>
                    <OrderDetails 
                      buyNowProduct={product}
                      buyNowQuantity={quantity}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-sticky-footer">
                <div className="modal-button-container">
                  <div className="payment-notice">
                    <IonIcon icon={cardOutline} /> Payment made at store
                  </div>
                  <div className="help-notice">
                    <IonIcon icon={helpCircleOutline} /> Need help? Email hello@stashpandas.com
                  </div>
                  <ConfirmButton 
                    onClick={() => purchaseStatus ? handleConfirmPurchaseNew() : setShowVerificationForm(true)}
                    text={isLoading ? "Processing..." : purchaseStatus ? `Complete Purchase • $${orderTotal}` : "Verify Account"}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="primary"
                    size="large"
                    fullWidth
                  />
                </div>
              </div>
              {error && <p className="error">{error}</p>}
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default BuyNowModal;
