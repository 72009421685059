import { httpsCallable } from 'firebase/functions';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  setDoc,
  deleteDoc,
  increment,
  onSnapshot,
  serverTimestamp
} from 'firebase/firestore';

import { db, functions, storage } from '../firebase';
import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";
import { store } from '../redux/store';
import { setUser } from '../redux/actions/userActions';
import { getAuth } from 'firebase/auth';

export const updateUserInfo = async (userId, userInfo) => {
  try {
    const updateUserInfoFunction = httpsCallable(functions, 'updateUserInfo');
    const result = await updateUserInfoFunction({ userId, ...userInfo });
    
    // After successful update, get the latest user data
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      store.dispatch(setUser({ userId, ...userData }));
    }
    
    return result.data;
  } catch (error) {
    console.error('Error updating user info:', error);
    throw new Error(error.message);
  }
};

export const updateUserEmail = async (userId, newEmail) => {
  try {
    const updateUserEmailFunction = httpsCallable(functions, 'updateUserEmail');
    const result = await updateUserEmailFunction({ userId, newEmail });
    
    // After successful update, get the latest user data
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      store.dispatch(setUser({ userId, ...userData }));
    }
    
    return result.data;
  } catch (error) {
    console.error('Error updating user email:', error);
    throw new Error(error.message);
  }
};

export const resetUserPassword = async (email) => {
  try {
    const resetUserPasswordFunction = httpsCallable(functions, 'resetUserPassword');
    const result = await resetUserPasswordFunction({ email });
    return result.data;
  } catch (error) {
    console.error('Error resetting user password:', error);
    throw new Error(error.message);
  }
};

// Fetch users
export const getUsers = async () => {
  try {
    const users = [];
    const snapshot = await getDocs(collection(db, 'users'));
    snapshot.forEach(doc => {
      users.push({ id: doc.id, ...doc.data() });
    });
    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const checkRole = async (userId) => {
  try {
    console.log('functions:', functions); // Debug: Log the 'functions' object
    const checkRoleFunction = httpsCallable(functions, 'checkRole');
    const result = await checkRoleFunction({ userId });
    console.log('result', result); // Debug: Log the result
    return result.data;
  } catch (error) {
    console.error('Error in checkRole:', error); // Debug: Log the error
    throw new Error(error.message);
  }
};

export const addUserRole = async (uid, role) => {
  try {
    const addUserRoleFunction = functions.httpsCallable('addUserRole');
    const result = await addUserRoleFunction({ uid, role });
    return result.data; // { message: "Role admin set for user target-user-uid" }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getStores = async (filters = {}) => {
  try {
    let storesQuery = collection(db, 'stores');

    // Apply filters
    if (filters.status) {
      storesQuery = query(storesQuery, where('status', '==', filters.status));
    }
    if (filters.deliveryEnabled) {
      storesQuery = query(storesQuery, where('deliverySettings.enabled', '==', true));
    }
    if (filters.pickupEnabled) {
      storesQuery = query(storesQuery, where('pickupSettings.enabled', '==', true));
    }

    const snapshot = await getDocs(storesQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

// Update store settings with validation
export const updateStore = async (storeId, updates) => {
  try {
    const docRef = doc(db, 'stores', storeId);
    const validatedUpdates = {
      ...updates,
      settings: {
        delivery: updates.settings?.delivery || {
          enabled: false,
          minimumOrder: 0,
          fee: 0,
          radius: 0,
          zones: []
        },
        pickup: updates.settings?.pickup || {
          enabled: false,
          minimumOrder: 0
        },
        inventory: updates.settings?.inventory || {  
          autoSync: true,
          defaultThreshold: 3
        },
        integration: updates.settings?.integration || {
          type: '',
          apiKeys: {
            clientId: '',
            apiKey: '',
            dispensaryName: ''
          }
        }
      }
    };
    await updateDoc(docRef, validatedUpdates);
  } catch (error) {
    console.error('Error updating store:', error);
    throw error;
  }
};

// Update store settings with validation
export const updateStoreSettings = async (storeId, updates) => {
  try {
    const updateStoreFunction = httpsCallable(functions, 'updateStoreSettings');
    const result = await updateStoreFunction({ 
      storeId, 
      updates: {
        ...updates,
        inventory: {
          threshold: updates.threshold || 5, // Default threshold
          isActive: updates.isActive !== undefined ? updates.isActive : true,
        }
      } 
    });
    return result.data;
  } catch (error) {
    console.error('Error updating store settings:', error);
    throw error;
  }
};

// Create store with empty inventory subcollection
export const createStoreWithInventory = async (storeData) => {
  try {
    const createStoreFunction = httpsCallable(functions, 'createStoreWithInventory');
    
    const result = await createStoreFunction(storeData);
    return result.data;
  } catch (error) {
    console.error('Error creating store:', error);
    throw error;
  }
};

export const getProductDetails = async (productId) => {
  try {
    const docRef = doc(db, 'products', productId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No product details found for ID:', productId);
      return null;
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
    return null;
  }
};

export const getInventory = async (storeId) => {
  try {
    const inventory = [];
    const snapshot = await getDocs(collection(db, `stores/${storeId}/inventory`));
    snapshot.forEach(doc => {
      inventory.push({ id: doc.id, ...doc.data() });
    });
    return inventory;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

export const getProducts = async (storeId = 'JlezFXhoNl4lVpibJZDx') => {
  try {
    console.log('Getting products for store:', storeId);
    const productsCollection = collection(db, 'products');
    const productsSnapshot = await getDocs(productsCollection);
    console.log('Found products count:', productsSnapshot.size);
    
    // Get all products with their store data in parallel
    const productsWithStoreData = await Promise.all(
      productsSnapshot.docs.map(async (productDoc) => {
        const storeRef = doc(db, 'products', productDoc.id, 'stores', storeId);
        const storeDoc = await getDoc(storeRef);
        const rootData = productDoc.data(); // Contains SP fields
        const storeData = storeDoc.exists() ? storeDoc.data() : {};
        
        console.log('Product processing:', {
          id: productDoc.id,
          hasStoreData: storeDoc.exists(),
          rootDataFields: Object.keys(rootData),
          storeDataFields: Object.keys(storeData),
          sp: rootData.sp
        });

        // Start with store data
        const mergedData = {
          id: productDoc.id,
          ...storeData
        };

        // Only use SP fields if they have content
        if (rootData.sp) {
          const sp = rootData.sp;
          
          // Basic fields
          if (hasContent(sp.name)) mergedData.name = sp.name;
          if (hasContent(sp.description)) mergedData.description = sp.description;
          if (hasContent(sp.brand)) mergedData.brand = sp.brand;
          if (hasContent(sp.category)) mergedData.category = sp.category;
          if (hasContent(sp.imageUrl)) mergedData.imageUrl = sp.imageUrl;
          
          // Attributes including amount
          if (hasContent(sp.attributes)) {
            mergedData.attributes = sp.attributes;
            if (sp.attributes.amount) {
              mergedData.amount = {
                unit: sp.attributes.amount.unit,
                value: sp.attributes.amount.value
              };
            }
          }

          // Cannabinoids
          if (hasContent(sp.attributes?.cannabinoids)) {
            mergedData.cannabinoids = sp.attributes.cannabinoids;
          }

          // Details
          if (hasContent(sp.details)) {
            mergedData.details = {
              ingredients: sp.details.ingredients || '',
              instructions: sp.details.instructions || '',
              servings: sp.details.servings || ''
            };
          }

          // Arrays - make sure they exist and are arrays before spreading
          if (Array.isArray(sp.effects)) mergedData.effects = [...sp.effects];
          if (Array.isArray(sp.flavors)) mergedData.flavors = [...sp.flavors];
          if (Array.isArray(sp.general)) mergedData.general = [...sp.general];
          if (Array.isArray(sp.ingredients)) mergedData.ingredients = [...sp.ingredients];
          if (Array.isArray(sp.internalTags)) mergedData.internalTags = [...sp.internalTags];
          if (Array.isArray(sp.categories)) mergedData.categories = [...sp.categories];
          if (Array.isArray(sp.relatedProducts)) mergedData.relatedProducts = [...sp.relatedProducts];
          if (Array.isArray(sp.seoKeywords)) mergedData.seoKeywords = [...sp.seoKeywords];
          if (Array.isArray(sp.tags)) mergedData.tags = [...sp.tags];

          // Numbers
          if (hasContent(sp.mgPerDose)) mergedData.mgPerDose = sp.mgPerDose;
          if (hasContent(sp.doses)) mergedData.doses = sp.doses;
          if (hasContent(sp.displayPriority)) mergedData.displayPriority = sp.displayPriority;

          // Booleans
          if (hasContent(sp.featured)) mergedData.featured = sp.featured;
          if (hasContent(sp.hidden)) mergedData.hidden = sp.hidden;

          // Images
          if (Array.isArray(sp.images)) mergedData.images = [...sp.images];

          // SEO
          if (hasContent(sp.seoDescription)) mergedData.seoDescription = sp.seoDescription;
          if (hasContent(sp.seoTitle)) mergedData.seoTitle = sp.seoTitle;

          // Metadata
          if (hasContent(sp.lastEditedAt)) mergedData.lastEditedAt = sp.lastEditedAt;
          if (hasContent(sp.lastEditedBy)) mergedData.lastEditedBy = sp.lastEditedBy;
          if (hasContent(sp.notes)) mergedData.notes = sp.notes;
        }

        // Add other root data
        mergedData.spFieldsAddedAt = rootData.spFieldsAddedAt;
        mergedData.multiStoreEnabled = rootData.multiStoreEnabled;
        
        console.log('Merged product data:', {
          id: productDoc.id,
          fields: Object.keys(mergedData),
          hasName: !!mergedData.name,
          hasDescription: !!mergedData.description,
          hasEffects: Array.isArray(mergedData.effects) && mergedData.effects.length > 0,
          hasCannabinoids: mergedData.cannabinoids && Object.keys(mergedData.cannabinoids).length > 0
        });
        
        return mergedData;
      })
    );
    
    console.log('Returning products count:', productsWithStoreData.length);
    return productsWithStoreData;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

export const getInStockProducts = async (storeId = 'JlezFXhoNl4lVpibJZDx', page = 1, pageSize = 20) => {
  try {
    console.log('Getting in-stock products for store:', storeId);
    
    // 1. First get inventory subcollection (lightweight)
    const inventoryRef = collection(db, 'stores', storeId, 'inventory');
    const inventorySnapshot = await getDocs(inventoryRef);
    console.log('Found inventory items:', inventorySnapshot.size);
    
    // Handle empty inventory
    if (!inventorySnapshot.docs || inventorySnapshot.docs.length === 0) {
      console.log('No inventory items found');
      return { products: [], total: 0, hasMore: false };
    }
    
    // 2. Get product details in batches
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const paginatedIds = inventorySnapshot.docs
      .map(doc => {
        const data = doc.data();
        console.log('Inventory doc data:', data);
        // Handle both field names
        const id = data.product_id || data.productId;
        if (!id) console.warn('Missing product ID in inventory doc:', data);
        return id;
      })
      .filter(id => id) // Remove any undefined/null IDs
      .slice(start, end);
    
    console.log('Paginated IDs:', paginatedIds);

    if (paginatedIds.length === 0) {
      console.log('No valid product IDs found in range');
      return { products: [], total: 0, hasMore: false };
    }

    // 3. Get product details in parallel
    const productsWithData = await Promise.all(
      paginatedIds.map(async (productId) => {
        // Get root product data (SP fields)
        const productRef = doc(db, 'products', productId);
        const storeRef = doc(db, 'products', productId, 'stores', storeId);
        
        try {
          const [productDoc, storeDoc] = await Promise.all([
            getDoc(productRef),
            getDoc(storeRef)
          ]);

          if (!productDoc.exists() || !storeDoc.exists()) {
            console.warn('Missing data for product:', productId);
            return null;
          }

          const rootData = productDoc.data();
          const storeData = storeDoc.data();
          
          // Add required fields for product display
          return {
            id: productId,
            ...storeData,
            // Required fields for product card
            price: storeData.pricing?.price_sell,
            discountedPrice: storeData.pricing?.discounted_price,
            sellableQuantity: storeData.sellable_quantity,
            name: rootData.sp?.name || storeData.product_configurable_fields?.name,
            description: rootData.sp?.description,
            brand: rootData.sp?.brand || storeData.product_configurable_fields?.brand,
            category: rootData.sp?.category || storeData.category_type,
            imageUrl: rootData.sp?.imageUrl || '/default-product-image.png',
            // Additional fields from SP
            effects: rootData.sp?.effects || [],
            flavors: rootData.sp?.flavors || [],
            attributes: rootData.sp?.attributes || {},
            cannabinoids: rootData.sp?.attributes?.cannabinoids || {},
            details: rootData.sp?.details || {},
            // Required for filtering
            sellable_quantity: storeData.sellable_quantity,
            category_type: storeData.category_type
          };
        } catch (err) {
          console.error('Error fetching product details:', productId, err);
          return null;
        }
      })
    );

    // Filter out any null values from missing data
    const validProducts = productsWithData.filter(p => p !== null);
    
    console.log('Returning products:', {
      count: validProducts.length,
      total: inventorySnapshot.size,
      hasMore: end < inventorySnapshot.size,
      products: validProducts.map(p => ({
        id: p.id,
        name: p.name,
        category: p.category,
        quantity: p.sellable_quantity
      }))
    });
    
    return {
      products: validProducts,
      total: inventorySnapshot.size,
      hasMore: end < inventorySnapshot.size
    };
  } catch (error) {
    console.error('Error fetching in-stock products:', error);
    return { products: [], total: 0, hasMore: false };
  }
};

export const getInStockProductsFast = async (storeId = 'JlezFXhoNl4lVpibJZDx', page = 1, pageSize = 20) => {
  try {
    console.log('Fast loading in-stock products for store:', storeId);
    
    // 1. Get inventory subcollection (lightweight)
    const inventoryRef = collection(db, 'stores', storeId, 'inventory');
    const inventorySnapshot = await getDocs(inventoryRef);
    console.log('Found inventory items:', inventorySnapshot.size);
    
    // Handle empty inventory
    if (!inventorySnapshot.docs || inventorySnapshot.docs.length === 0) {
      return { products: [], total: 0, hasMore: false };
    }
    
    // 2. Get product details in batches
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const paginatedDocs = inventorySnapshot.docs.slice(start, end);

    // 3. Get product details in parallel
    const productsWithData = await Promise.all(
      paginatedDocs.map(async (inventoryDoc) => {
        const data = inventoryDoc.data();
        const productId = data.product_id || data.productId;
        
        if (!productId) {
          console.warn('Missing product ID in inventory doc:', data);
          return null;
        }

        try {
          // Get root product data and store data
          const [productDoc, storeDoc] = await Promise.all([
            getDoc(doc(db, 'products', productId)),
            getDoc(doc(db, 'products', productId, 'stores', storeId))
          ]);

          if (!productDoc.exists() || !storeDoc.exists()) {
            console.warn('Missing data for product:', productId);
            return null;
          }

          const rootData = productDoc.data();
          const storeData = storeDoc.data();
          
          // Log each product's data
          console.log('Product data:', {
            id: productId,
            category_type: storeData.category_type,
            sellable_quantity: storeData.sellable_quantity,
            name: rootData.sp?.name || storeData.product_configurable_fields?.name,
            brand: rootData.sp?.brand || storeData.product_configurable_fields?.brand
          });
          
          return {
            id: productId,
            ...storeData,
            name: rootData.sp?.name || storeData.product_configurable_fields?.name,
            description: rootData.sp?.description,
            brand: rootData.sp?.brand || storeData.product_configurable_fields?.brand,
            category_type: storeData.category_type,
            classification: rootData.sp?.classification || storeData.classification || storeData.product_configurable_fields?.classification,
            imageUrl: rootData.sp?.imageUrl || '/default-product-image.png',
            effects: rootData.sp?.effects || [],
            flavors: rootData.sp?.flavors || [],
            attributes: rootData.sp?.attributes || {},
            cannabinoids: rootData.sp?.attributes?.cannabinoids || {},
            details: rootData.sp?.details || {},
            sellableQuantity: storeData.sellable_quantity,
            price: storeData.pricing?.price_sell,
            discountedPrice: storeData.pricing?.discounted_price,
            stockStatus: storeData.sellable_quantity <= 5 ? 'LOW_STOCK' : 'IN_STOCK'
          };
        } catch (err) {
          console.error('Error fetching product details:', productId, err);
          return null;
        }
      })
    );

    // Filter out any null values from missing data
    const validProducts = productsWithData.filter(p => p !== null);
    
    return {
      products: validProducts,
      total: inventorySnapshot.size,
      hasMore: end < inventorySnapshot.size
    };
  } catch (error) {
    console.error('Error in fast product loading:', error);
    return { products: [], total: 0, hasMore: false };
  }
};

export const createStore = async (storeData) => {
  try {
    const storeRef = await addDoc(collection(db, 'stores'), {
      ...storeData,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    return { id: storeRef.id, ...storeData };
  } catch (error) {
    console.error('Error creating store:', error);
    throw error;
  }
};

export const deleteStore = async (storeId) => {
  try {
    const storeRef = doc(db, 'stores', storeId);
    // Instead of actually deleting, mark as inactive
    await updateDoc(storeRef, {
      status: 'INACTIVE',
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error deleting store:', error);
    throw error;
  }
};

export const setupStoreListener = (storeId, callback) => {
  const storeRef = doc(db, 'stores', storeId);
  return onSnapshot(storeRef, (doc) => {
    if (doc.exists()) {
      callback({ id: doc.id, ...doc.data() });
    } else {
      callback(null);
    }
  });
};

export const getStoreDetails = async (storeId) => {
  const storeRef = doc(db, 'stores', storeId);
  const storeSnapshot = await getDoc(storeRef);
  return storeSnapshot.exists() ? storeSnapshot.data() : null;
};

// Update inventory item
export const updateInventory = async (storeId, itemId, updatedData) => {
  try {
    const inventoryItemRef = doc(db, `stores/${storeId}/inventory`, itemId);
    await updateDoc(inventoryItemRef, updatedData);
  } catch (error) {
    console.error('Error updating inventory item:', error);
    throw error;
  }
};

// Function to mark an inventory item as out of stock
export const removeInventoryItem = async (storeId, itemId) => {
  const itemRef = doc(db, `stores/${storeId}/inventory`, itemId);
  await updateDoc(itemRef, { quantity: 0 });
};

// Function to add a new inventory item
export const addInventoryItem = async (storeId, itemDetails) => {
  const inventoryRef = collection(db, `stores/${storeId}/inventory`);
  await addDoc(inventoryRef, {
      ...itemDetails,
      storeId: storeId,
  });
};

// Function to fetch a single inventory item's details
export const getInventoryItem = async (storeId, itemId) => {
  const itemRef = doc(db, `stores/${storeId}/inventory`, itemId);
  const itemSnapshot = await getDoc(itemRef);
  return itemSnapshot.exists() ? itemSnapshot.data() : null;
};

export const getDealOfTheDay = async () => {
  const dealQuery = query(collection(db, 'products'), where('dealOfTheDay', '==', true));
  const dealSnapshot = await getDocs(dealQuery);
  const deal = dealSnapshot.empty ? null : { id: dealSnapshot.docs[0].id, ...dealSnapshot.docs[0].data() };
  return deal;
};

export const updateUserRole = async (userId, role) => {
  try {
    const updateUserRoleFunction = httpsCallable(functions, 'updateUserRole');
    const result = await updateUserRoleFunction({ userId, role });
    return result.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addProductToUserCart = async (userId, product) => {
  try {
    if (!userId || !product.id) {
        throw new Error("Missing userID or productID");
    }
    const userCartRef = doc(db, `users/${userId}/cart/${product.id}`);
    await setDoc(userCartRef, {
        ...product,
        quantity: 1 // Adjust this as necessary
    });
    return true; // Indicate success
  } catch (error) {
      console.error('Error adding product to cart:', error);
      throw error; // Re-throw to handle it appropriately
  }
};

export const fetchUserCart = async (userId) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const cartSnapshot = await getDocs(cartRef);
    const cartItems = cartSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return cartItems;
  } catch (error) {
    console.error("Error fetching user cart:", error);
    return [];
  }
};

export const checkoutUser = async (userId) => {
  if (!userId) throw new Error("User ID is required for checkout.");

  const cartRef = collection(db, `users/${userId}/cart`);
  const cartSnapshot = await getDocs(cartRef);
  const cartItems = cartSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  // Create order object
  const order = {
    userId,
    date: new Date(),
    items: cartItems,
    totalAmount: cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    // Add other fields as needed, like user details, shipping address, etc.
  };

  // Add order to 'orders' collection
  await addDoc(collection(db, 'orders'), order);

  // Update inventory for each product
  for (const item of cartItems) {
    const productRef = doc(db, `stores/${item.storeId}/inventory`, item.id);
    await updateDoc(productRef, { quantity: increment(-item.quantity) }); // decrement item quantity from inventory
  }

  // Clear user's cart after checkout
  for (const item of cartItems) {
    await deleteDoc(doc(db, `users/${userId}/cart`, item.id));
  }

  return order; // return the order for confirmation or further processing
};

export const createBuyNowOrder = async (userId, product, quantity) => {
  if (!userId || !product.id) throw new Error("Missing user ID or product details.");

  // Create the order object
  const order = {
    userId,
    date: new Date(),
    items: [{ ...product, quantity }],
    totalAmount: product.price * quantity,
    status: 'pending' // You can set the initial order status as required
  };

  // Add order to 'orders' collection
  const orderRef = await addDoc(collection(db, 'orders'), order);

  // Update inventory for the product
  const productRef = doc(db, `stores/${product.storeId}/inventory`, product.id);
  await updateDoc(productRef, { quantity: increment(-quantity) });

  // Return order info, might be useful for confirmation message or further processing
  return { ...order, id: orderRef.id };
};

export const getPreviouslyOrderedInStockProducts = async (userId) => {
  try {
    let productsMap = {}; // Use an object to store unique products

    // Fetch User's Orders
    const ordersQuery = query(collection(db, 'orders'), where('userId', '==', userId));
    const ordersSnapshot = await getDocs(ordersQuery);

    for (const orderDoc of ordersSnapshot.docs) {
      const order = orderDoc.data(); // Access the order data

      for (const item of order.items) {
        // Check In-Stock Status
        const inventoryItemRef = doc(db, `stores/${item.storeId}/inventory`, item.id);
        const inventoryItemSnapshot = await getDoc(inventoryItemRef);

        if (inventoryItemSnapshot.exists() && inventoryItemSnapshot.data().quantity > 0) {
          // Use the product ID as the key to ensure uniqueness
          productsMap[item.id] = {
            ...item, // item data from the order
            inStock: true // Mark as in stock
          };
        } else {
          // Optionally, handle items that are not in stock
          console.log(`Product ${item.productName} is out of stock.`);
        }
      }
    }

    // Convert the productsMap back into an array of its values (the unique products)
    const uniqueOrderedProducts = Object.values(productsMap);
    return uniqueOrderedProducts;
  } catch (error) {
    console.error('Error fetching previously ordered in-stock products:', error);
    throw error;
  }
};

export const uploadAndValidateID = async (selectedFile, userId, accountName, legalName, idType) => {
  try {
    const fileRef = ref(storage, `ids/${userId}/${selectedFile.name}`);
    await uploadBytes(fileRef, selectedFile);
    const fileUrl = await getDownloadURL(fileRef);

    const uploadAndValidateIDFunction = httpsCallable(functions, 'uploadAndValidateID');
    const result = await uploadAndValidateIDFunction({
      filePath: fileUrl,
      userId,
      accountName,
      legalName,
      idType,
    });

    return result.data; // { success: true/false, message: '...' }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const uploadPhoto = async (selectedFile, userId, idType) => {
  try {
    // Ensure the user is authenticated
    // const user = firebase.auth().currentUser;
    if (!userId) {
      throw new Error("User is not authenticated");
    }
    // Upload the file to Firebase Storage
    const fileRef = ref(storage, `photos/${userId}/${selectedFile.name}`);
    await uploadBytes(fileRef, selectedFile);
    const fileUrl = await getDownloadURL(fileRef);

    // Call the Cloud Function
    const uploadPhotoFunction = httpsCallable(functions, 'uploadPhoto');
    const result = await uploadPhotoFunction({
      filePath: fileUrl,
      userId,
      idType,
    });

    // Access the success and message values
    const { success, message } = result.data;

    // Update the UI or handle the result as needed
    if (success) {
      document.getElementById('result').innerText = `Success!`;
    } else {
      document.getElementById('result').innerText = `Upload failed: ${message}`;
    }

    return result.data; // { success: true, fileUrl: '...' }
  } catch (error) {
    console.error('Error in uploadPhoto:', error);
    throw new Error(error.message);
  }
};

// Add a new function to setup real-time user updates
export const setupUserListener = (userId) => {
  if (!userId) return null;
  
  return onSnapshot(doc(db, 'users', userId), (doc) => {
    if (doc.exists()) {
      const userData = doc.data();
      store.dispatch(setUser({ userId, ...userData }));
    }
  });
};

// Helper function to check if a value has meaningful content
const hasContent = (value) => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'string') return value.trim() !== '';
  if (Array.isArray(value)) return value.length > 0;
  if (typeof value === 'object') return Object.keys(value).length > 0;
  if (typeof value === 'number') return true;
  if (typeof value === 'boolean') return true;
  return true;
};