import * as actionTypes from './actionTypes';
import { collection, onSnapshot, addDoc, doc, updateDoc, query, getDoc, increment, getDocs, where, deleteDoc, Timestamp, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

import { fetchUserCart } from '../../utils/FirebaseUtils';

const convertTimestamps = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (newObj[key] && typeof newObj[key] === 'object' && newObj[key].seconds) {
      newObj[key] = newObj[key].toDate().toISOString();
    } else if (typeof newObj[key] === 'object') {
      newObj[key] = convertTimestamps(newObj[key]);
    }
  });
  return newObj;
};

export const fetchCart = (userId) => async (dispatch) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const cartSnapshot = await getDocs(cartRef);
    const cartItems = cartSnapshot.docs.map(doc => ({
      ...doc.data(),
      cartItemId: doc.id // Store the Firebase document ID
    }));
    dispatch({
      type: actionTypes.SET_CART,
      payload: cartItems
    });
  } catch (error) {
    console.error("Error fetching cart:", error);
    dispatch({
      type: actionTypes.SET_CART,
      payload: []
    });
  }
};

export const fetchOrders = (userId) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_ORDERS_START });
  try {
    const ordersRef = collection(db, 'orders');
    const q = query(ordersRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const orders = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...convertTimestamps(doc.data())
    }));
    dispatch({ type: actionTypes.FETCH_ORDERS_SUCCESS, payload: orders });
  } catch (error) {
    console.error('Error fetching orders:', error);
    dispatch({ type: actionTypes.FETCH_ORDERS_FAILURE, payload: error.message });
  }
};

export const addProductToCart = (product) => async (dispatch, getState) => {
  console.log('Product received in addProductToCart:', product); 
  const { user } = getState();
  if (!user?.userId) {
    console.error('No user ID found');
    return;
  }

  if (!product?.id || !product?.name) {
    console.error('Invalid product data');
    return;
  }

  const cartRef = collection(db, 'users', user.userId, 'cart');

  try {
    // Check if the product already exists in the cart
    const existingProductQuery = query(cartRef, where('id', '==', product.id));
    const existingProductSnapshot = await getDocs(existingProductQuery);

    if (!existingProductSnapshot.empty) {
      // If product exists, increment the quantity
      const existingDoc = existingProductSnapshot.docs[0];
      const existingQuantity = existingDoc.data().quantity || 0;
      const newQuantity = existingQuantity + 1;
      
      await updateDoc(doc(cartRef, existingDoc.id), { 
        quantity: newQuantity,
        updatedAt: serverTimestamp()
      });

      dispatch({
        type: actionTypes.UPDATE_CART_ITEM_QUANTITY,
        payload: { id: product.id, quantity: newQuantity },
      });
    } else {
      const cartItem = {
        id: product.id,
        store_id: product.store_id,
        name: product.name,
        brand: product.brand || '',
        category_type: product.category_type || '',
        categories: product.categories || [],
        classification: product.classification || '',
        price: product.pricing?.price_sell || 0,
        discountedPrice: product.pricing?.discounted_price || null,
        quantity: 1,
        imageUrl: product.imageUrl || '',
        store_data: {
          store_id: product.store_id || 'JlezFXhoNl4lVpibJZDx',
          pricing: {
            price_sell: product.pricing?.price_sell || 0,
            discounted_price: product.pricing?.discounted_price || null,
            discount_percent: product.pricing?.discount_percent || 0,
            price_type: "FLAT",
            discount_amount: product.pricing?.price_sell && product.pricing?.discounted_price 
              ? product.pricing.price_sell - product.pricing.discounted_price 
              : 0,
            tier_name: null
          },
          quantity: {
            sellable_quantity: product.sellable_quantity || 0,
            sellable_quantity_details: product.sellable_quantity_detail?.reduce((acc, detail) => {
              acc[detail.location] = detail.quantity;
              return acc;
            }, {}) || {}
          }
        },
        addedAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      await addDoc(cartRef, cartItem);

      dispatch({
        type: actionTypes.ADD_PRODUCT_TO_CART,
        payload: cartItem
      });
    }
  } catch (error) {
    console.error('Error adding product to cart:', error);
    // Optionally dispatch an error action if you want to show UI feedback
  }
};

export const removeFromCart = (userId, productId) => async (dispatch) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const q = query(cartRef, where('id', '==', productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const cartItemDoc = querySnapshot.docs[0];
      await deleteDoc(cartItemDoc.ref);

      dispatch({
        type: actionTypes.REMOVE_PRODUCT_FROM_CART,
        payload: { id: productId },
      });
    } else {
      console.error('Cart item not found');
    }
  } catch (error) {
    console.error('Error removing item from cart:', error);
  }
};

export const createOrder = (orderData, ticketResponse) => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const itemsToProcess = orderData.isDirectPurchase ? orderData.items : getState().order.cart;

    // Tax rates
    const TAX_RATES = {
      laCannabisRate: 0.10,
      caExciseRate: 0.15,
      salesTaxRate: 0.0975
    };

    // Process items with detailed pricing
    const itemsWithDetails = itemsToProcess.map(item => {
      const priceInCents = Math.round((orderData.isDirectPurchase ? item.price : item.price) * 100);
      const regularPriceInCents = orderData.isDirectPurchase ? 
        priceInCents : 
        Math.round((item.regularPrice || item.price) * 100);
      const quantity = item.quantity;
      const itemSubtotal = priceInCents * quantity;
      
      // Calculate savings for this item
      const itemSavings = orderData.isDirectPurchase ? 0 :
        (item.regularPrice && item.regularPrice > item.price)
          ? Math.round((item.regularPrice - item.price) * 100) * quantity
          : 0;
      
      // Calculate taxes for this item
      const laCannabisCity = Math.round(itemSubtotal * TAX_RATES.laCannabisRate);
      const caExcise = Math.round(itemSubtotal * TAX_RATES.caExciseRate);
      const salesTax = Math.round(itemSubtotal * TAX_RATES.salesTaxRate);
      const itemTotalTax = laCannabisCity + caExcise + salesTax;

      return {
        productId: orderData.isDirectPurchase ? item.productId : item.id,
        quantity: quantity,
        pricePerUnit: {
          amount: priceInCents,
          regularAmount: regularPriceInCents,
          currency: "USD"
        },
        savings: itemSavings,
        taxes: {
          laCannabisCity,
          caExcise,
          salesTax,
          totalTax: itemTotalTax
        },
        itemSubtotal,
        itemTotal: itemSubtotal + itemTotalTax
      };
    });

    // Calculate order totals
    const orderTotals = itemsWithDetails.reduce((acc, item) => {
      acc.subtotal += item.itemSubtotal;
      acc.savings += item.savings;  
      acc.taxes.laCannabisCity += item.taxes.laCannabisCity;
      acc.taxes.caExcise += item.taxes.caExcise;
      acc.taxes.salesTax += item.taxes.salesTax;
      acc.taxes.totalTax += item.taxes.totalTax;
      return acc;
    }, {
      subtotal: 0,
      savings: 0,  
      taxes: {
        laCannabisCity: 0,
        caExcise: 0,
        salesTax: 0,
        totalTax: 0
      },
      currency: "USD"
    });

    orderTotals.total = orderTotals.subtotal + orderTotals.taxes.totalTax;

    const firestoreOrderData = {
      userId: user.userId,
      orderStatus: "VERIFICATION_PENDING",
      orderTime: Timestamp.now(),
      paymentStatus: "VERIFICATION_PENDING",
      items: itemsWithDetails.reduce((acc, item, index) => {
        acc[index.toString()] = item;
        return acc;
      }, {}),
      totals: orderTotals,
      ticketData: {
        ...orderData.ticketData,
      },
      treezOrderNumber: ticketResponse?.data?.order_number || '',
      treezOrderStatus: ticketResponse?.data?.order_status || "PREVIEW",
      treezPaymentStatus: ticketResponse?.data?.payment_status || "UNPAID",
      treezTicketId: ticketResponse?.data?.ticket_id || null,
      store_data: {
          store_id: itemsToProcess[0]?.store_id || 'JlezFXhoNl4lVpibJZDx',
      },  
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };

    const docRef = await addDoc(collection(db, 'orders'), firestoreOrderData);

    // Add reference to user's orders subcollection
    await setDoc(doc(db, 'users', user.userId, 'orders', docRef.id), { orderId: docRef.id });

    dispatch({
      type: actionTypes.CREATE_ORDER,
      payload: {
        ...firestoreOrderData,
        id: docRef.id,
      },
    });

    return docRef.id;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const updateCartItemQuantity = (cartItemId, productId, newQuantity) => async (dispatch, getState) => {
  const { user } = getState();
  
  try {
    // First, query to find the correct cart item document
    const cartRef = collection(db, 'users', user.userId, 'cart');
    const q = query(cartRef, where('id', '==', productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const cartItemDoc = querySnapshot.docs[0];
      await updateDoc(cartItemDoc.ref, { quantity: newQuantity });

      dispatch({
        type: actionTypes.UPDATE_CART_ITEM_QUANTITY,
        payload: { id: productId, cartItemId: cartItemDoc.id, quantity: newQuantity },
      });
    } else {
      console.error('Cart item not found');
    }
  } catch (error) {
    console.error('Error updating cart item quantity:', error);
  }
};

// Define other actionTypes as needed.
