import React from 'react';
import PropTypes from 'prop-types';
import "./ConfirmButton.css";

const ConfirmButton = ({ 
  onClick, 
  disabled = false, 
  text = "Confirm Purchase",
  isLoading = false,
  variant = 'primary',
  size = 'large',
  fullWidth = true,
}) => {
  const buttonClasses = [
    'confirm-button',
    `confirm-button--${variant}`,
    `confirm-button--${size}`,
    fullWidth && 'confirm-button--full-width',
    disabled && 'confirm-button--disabled',
    isLoading && 'confirm-button--loading'
  ].filter(Boolean).join(' ');

  return (
    <button 
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled || isLoading}
      type="button"
      aria-busy={isLoading}
    >
      {isLoading ? (
        <span className="confirm-button__loader" aria-hidden="true">
          <span className="confirm-button__loader-dot" />
          <span className="confirm-button__loader-dot" />
          <span className="confirm-button__loader-dot" />
        </span>
      ) : (
        <span className="confirm-button__text">{text}</span>
      )}
    </button>
  );
};

ConfirmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWidth: PropTypes.bool,
};

export default ConfirmButton;
