import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { syncTreezInventoryToFirebase } from '../../utils/TreezService';

const normalizeLabResults = (labResults = []) => {
  const normalized = {};
  labResults.forEach(result => {
    if (result.result_type && result.amount?.maximum_value) {
      normalized[result.result_type.toLowerCase()] = {
        value: parseFloat(result.amount.maximum_value),
        unit: '%'
      };
    }
  });
  return normalized;
};

const normalizeCannabinoids = (spCannabinoids = {}, storeCannabinoids = {}) => {
  const normalized = {};
  const cannabinoidTypes = ['thc', 'cbd', 'cbn', 'cbg', 'cbc', 'thca', 'delta9', 'thcv', 'cbda', 'cbga'];
  
  cannabinoidTypes.forEach(type => {
    // Prefer sp fields, fallback to store data
    const value = spCannabinoids[type]?.value || storeCannabinoids[type]?.percentage || 0;
    if (value > 0) {
      normalized[type] = {
        value,
        unit: '%'
      };
    }
  });
  
  return normalized;
};

const normalizeProductData = (rootData, storeData) => {
  const sp = rootData.sp || {};
  const storeFields = storeData.product_configurable_fields || {};
  
  // Normalize lab results
  const normalizedLabResults = normalizeLabResults(storeData.lab_results);
  
  // Normalize cannabinoids
  const normalizedCannabinoids = normalizeCannabinoids(
    sp.attributes?.cannabinoids,
    storeData.attributes?.cannabinoids
  );

  // Process pricing from store document
  const storePrice = storeData.pricing || {};
  
  // Normalize pricing to match what ItemGallery passes to BaseCard
  const normalizedPricing = {
    pricing: {
      price_sell: storePrice.price_sell || 0,  // Original price
      discounted_price: storePrice.discounted_price || null,  // Discounted price if any
      discount_percent: storePrice.discount_percent || 0  // Discount percentage
    }
  };

  // Normalize categories
  const normalizeCategory = (category) => {
    if (!category) return '';
    
    // First clean the string
    const cleaned = category.replace(/[\s-]/g, '').toUpperCase();
    
    // Category mappings to match exactly what CategorySelect uses
    const categoryMappings = {
      // Cartridge category
      'VAPE': 'CARTRIDGE',
      'VAPES': 'CARTRIDGE',
      'VAPECART': 'CARTRIDGE',
      'CARTRIDGE': 'CARTRIDGE',
      'CARTRIDGES': 'CARTRIDGE',
      'CART': 'CARTRIDGE',
      'CARTS': 'CARTRIDGE',
      
      // Edible category
      'EDIBLE': 'EDIBLE',
      'EDIBLES': 'EDIBLE',
      'TINCTURE': 'EDIBLE',
      'TINCTURES': 'EDIBLE',
      
      // Preroll category
      'PREROLL': 'PREROLL',
      'PRE-ROLL': 'PREROLL',
      'PREROLLS': 'PREROLL',
      
      // Extract category
      'EXTRACT': 'EXTRACT',
      'EXTRACTS': 'EXTRACT',
      'CONCENTRATE': 'EXTRACT',
      'CONCENTRATES': 'EXTRACT',
      
      // Flower category
      'FLOWER': 'FLOWER',
      'FLOWERS': 'FLOWER'
    };
    
    return categoryMappings[cleaned] || cleaned;
  };

  const categoryType = normalizeCategory(
    sp.category_type || 
    sp.attributes?.category || 
    storeData.category_type
  );

  const categories = Array.from(new Set([
    ...(sp.categories || []).map(normalizeCategory),
    categoryType
  ])).filter(Boolean);

  // Normalize attributes (using store data as fallback)
  const attributes = {
    ...(sp.attributes || {}),
    activities: sp.attributes?.activities || storeData.attributes?.activities || [],
    effects: sp.attributes?.effects || storeData.attributes?.effects || [],
    flavors: sp.attributes?.flavors || storeData.attributes?.flavors || [],
    amount: sp.attributes?.amount || storeData.attributes?.amount || {
      unit: storeFields.uom || 'GRAMS',
      value: storeFields.amount || 0
    },
    cannabinoids: normalizedCannabinoids,
    weights: sp.attributes?.weights || storeData.attributes?.weights || {
      concentrate: storeFields.total_concentrate_weight_g || 0,
      flower: storeFields.total_flower_weight_g || 0
    },
    details: sp.attributes?.details || storeData.attributes?.details || {
      ingredients: storeFields.ingredients || '',
      servings: storeFields.servings || '',
      instructions: ''
    }
  };

  // Remove category from attributes since we use category_type
  delete attributes.category;

  return {
    // Core product data (prefer sp fields)
    id: storeData.id || storeData.product_id,
    store_id: storeData.store_id,  // Keep this for consistency with existing code
    name: sp.name || storeFields.name || storeData.name,
    brand: sp.brand || storeFields.brand || storeData.brand,
    description: sp.description || storeData.description || '',
    
    // Categories and classification (from sp fields)
    category_type: categoryType,
    categories,
    classification: sp.classification || sp.attributes?.classification || storeData.classification || storeFields.classification,
    
    // Images
    imageUrl: sp.imageUrl || storeData.imageUrl || '/default-product-image.png',
    images: sp.images || { all: [], primary: null },
    
    // Product attributes (normalized structure)
    attributes,
    
    // Lab results
    lab_results: normalizedLabResults,
    
    // Inventory and status by location
    quantities: storeData.quantities || {},
    sellable_quantity: storeData.sellable_quantity || 0,
    sellable_quantity_detail: storeData.sellable_quantity_detail || [],
    stockStatus: storeData.sellable_quantity <= 5 ? 'LOW_STOCK' : 'IN_STOCK',
    above_threshold: storeData.above_threshold || false,
    
    // Pricing (fields that BaseCard expects via ItemGallery)
    ...normalizedPricing,
    
    // Store specific data
    store: {
      id: storeData.store_id,
      locations: storeData.locations || [],
      last_updated: storeData.last_updated_at || storeData.lastSyncedAt,
      discounts: storeData.discounts || []
    },
    
    // Metadata
    metadata: {
      source: storeData.metadata?.source || 'treez',
      sourceId: storeData.metadata?.sourceId,
      lastTreezUpdate: storeData.metadata?.lastTreezUpdate,
      minimumVisibleInventory: storeData.metadata?.minimumVisibleInventory || 3
    }
  };
};

export const loadProductsSmooth = async (storeId = 'JlezFXhoNl4lVpibJZDx', page = 1, pageSize = 1000) => {
  try {
    console.log('Fast loading in-stock products for store:', storeId);
    
    // 1. Get inventory subcollection (lightweight, only in-stock items)
    const inventoryRef = collection(db, 'stores', storeId, 'inventory');
    const inventorySnapshot = await getDocs(inventoryRef);
    
    // Always trigger a background sync
    syncTreezInventoryToFirebase()
      .then(result => console.log('Sync completed:', result))
      .catch(err => console.error('Error triggering sync:', err));
    
    // Handle empty inventory
    if (!inventorySnapshot.docs?.length) {
      return { products: [], total: 0, hasMore: false, availableCategories: [] };
    }
    
    // 2. Get product details in batches
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    const paginatedDocs = inventorySnapshot.docs.slice(start, end);

    // 3. Get product details in parallel
    const productsWithData = await Promise.all(
      paginatedDocs.map(async (inventoryDoc) => {
        const inventoryData = inventoryDoc.data();
        const productId = inventoryData.product_id || inventoryData.productId;
        
        if (!productId) {
          console.warn('Missing product ID in inventory doc:', inventoryData);
          return null;
        }

        try {
          // Get product data from products collection
          const productDoc = await getDoc(doc(db, 'products', productId));

          if (!productDoc.exists()) {
            console.warn('Missing product data for:', productId);
            return null;
          }

          const rootData = productDoc.data();
          
          // Get store-specific pricing from products/{productId}/stores/{storeId}
          const storeDoc = await getDoc(doc(db, 'products', productId, 'stores', storeId));
          const storeData = storeDoc.exists() ? storeDoc.data() : {};
          
          // Calculate total quantity across locations
          const totalQuantity = Object.values(inventoryData.quantities || {}).reduce((sum, qty) => sum + (qty || 0), 0);
          
          // Combine inventory data with product and store-specific data
          const combinedStoreData = {
            ...inventoryData,
            id: productId,
            store_id: storeId,
            sellable_quantity: totalQuantity,
            quantities: inventoryData.quantities || {},
            locations: inventoryData.locations || [],
            last_updated_at: inventoryData.last_updated_at,
            pricing: storeData.pricing || {}  // Use store-specific pricing
          };
          
          // Normalize the product data
          return normalizeProductData(rootData, combinedStoreData);
          
        } catch (err) {
          console.error('Error fetching product details:', productId, err);
          return null;
        }
      })
    );

    // Filter out any null values from missing data
    const validProducts = productsWithData.filter(p => p !== null);
    
    // Process categories
    const categories = new Set();
    const categoryStats = {};
    
    validProducts.forEach(p => {
      const category = p.category_type;
      if (category) {
        categories.add(category);
        categoryStats[category] = (categoryStats[category] || 0) + 1;
      }
      if (p.categories?.length) {
        p.categories.forEach(c => {
          categories.add(c);
          categoryStats[c] = (categoryStats[c] || 0) + 1;
        });
      }
    });

    // Debug log for products and categories
    console.log('Product Load Debug:', {
      totalProducts: validProducts.length,
      categoryBreakdown: categoryStats,
      sampleProducts: validProducts.slice(0, 3).map(p => ({
        name: p.name,
        category_type: p.category_type,
        categories: p.categories,
        pricing: p.pricing
      }))
    });

    return {
      products: validProducts,
      total: inventorySnapshot.size,
      hasMore: end < inventorySnapshot.size,
      availableCategories: Array.from(categories)
    };
  } catch (error) {
    console.error('Error in loadProductsSmooth:', error);
    return { products: [], total: 0, hasMore: false, availableCategories: [] };
  }
};
