import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

// Utility function for retrying failed API calls
const fetchWithRetry = async (fetchFn, maxRetries = 3) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await fetchFn();
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
    }
  }
};

// Validation function for product data
const validateProduct = (product) => {
  const required = ['product_id', 'total_quantity', 'locations', 'quantities'];
  return required.every(field => product[field] != null);
};

export const syncTreezInventoryToFirebase = async() => {
  try {
    // Get products from both locations in parallel using existing functions with retry
    const [stashPandaResult, fohResult] = await Promise.all([
      fetchWithRetry(() => 
        httpsCallable(functions, 'getTreezProducts')({
          location: 'STASHPANDA'
        })
      ),
      fetchWithRetry(() => 
        httpsCallable(functions, 'getFrontOfHouseProductsOld')()
      )
    ]);

    // Add null checks and logging
    if (!stashPandaResult?.data?.data?.product_list) {
      console.error('Invalid StashPanda result:', stashPandaResult);
      throw new Error('StashPanda products data is missing or invalid');
    }

    if (!Array.isArray(fohResult?.data)) {
      console.error('Invalid Front of House result:', fohResult);
      throw new Error('Front of House products data is missing or invalid');
    }

    const stashPandaProducts = stashPandaResult.data.data.product_list;
    const frontOfHouseProducts = fohResult.data;

    // Log the data we're working with
    console.log(`Retrieved ${stashPandaProducts.length} StashPanda products and ${frontOfHouseProducts.length} FOH products`);

    // Merge products
    const productMap = new Map();

    // Add StashPanda products first
    if (Array.isArray(stashPandaProducts)) {
      stashPandaProducts.forEach(product => {
        if (!product?.product_id) {
          console.warn('Invalid product without product_id:', product);
          return;
        }

        const mergedProduct = {
          ...product,
          locations: ['STASHPANDA'],
          total_quantity: product.sellable_quantity || 0,
          quantities: {
            STASHPANDA: product.sellable_quantity || 0
          }
        };
        if (validateProduct(mergedProduct)) {
          productMap.set(product.product_id, mergedProduct);
        } else {
          console.warn('Invalid StashPanda product:', product.product_id);
        }
      });
    }

    // Merge FOH products (only Jeeter/Stiiizy)
    if (Array.isArray(frontOfHouseProducts)) {
      frontOfHouseProducts.forEach(product => {
        if (!product?.product_id) {
          console.warn('Invalid product without product_id:', product);
          return;
        }

        if (productMap.has(product.product_id)) {
          // Product exists in StashPanda, merge quantities
          const existing = productMap.get(product.product_id);
          existing.locations.push('FRONT_OF_HOUSE');
          existing.total_quantity += product.sellable_quantity || 0;
          existing.quantities.FRONT_OF_HOUSE = product.sellable_quantity || 0;
        } else {
          // New FOH product
          const mergedProduct = {
            ...product,
            locations: ['FRONT_OF_HOUSE'],
            total_quantity: product.sellable_quantity || 0,
            quantities: {
              FRONT_OF_HOUSE: product.sellable_quantity || 0
            }
          };
          if (validateProduct(mergedProduct)) {
            productMap.set(product.product_id, mergedProduct);
          } else {
            console.warn('Invalid FOH product:', product.product_id);
          }
        }
      });
    }

    console.log(`Merging ${stashPandaProducts.length} StashPanda products with ${frontOfHouseProducts.length} FOH products`);

    const mergedProducts = Array.from(productMap.values());

    // Call the simplified syncNewProductsTest that only handles Firebase updates
    const syncResult = await fetchWithRetry(() => 
      httpsCallable(functions, 'syncTreezInventoryToFirebase')({
        treezProducts: mergedProducts
      })
    );
    // After merging:
    console.log(`Sending ${mergedProducts.length} merged products to Firebase`);
    console.log('Sync completed:', syncResult.data);
    return syncResult.data;

  } catch (error) {
    console.error('Error in product sync:', error);
    throw error;
  }
};

export const fetchTreezProducts = async () => {
  const getTreezProducts = httpsCallable(functions, 'getTreezProducts');
  try {
    const result = await getTreezProducts();
    console.log('Treez products:', result.data);
    return result.data;
  } catch (error) {
    console.error("Error fetching Treez products:", error);
    throw error;
  }
};

export const previewTreezTicket = async (ticketData) => {
  const previewTicket = httpsCallable(functions, 'previewTreezTicket');
  try {
    const result = await previewTicket(ticketData);
    console.log("Treez Preview Ticket Response:", result.data);
    return result.data;
  } catch (error) {
    console.error("Error previewing Treez ticket:", error);
    if (error.details) {
      console.error("Error details:", error.details);
    }
    if (error.code) {
      console.error("Error code:", error.code);
    }
    throw error;
  }
};

export const createTreezTicket = async (ticketData) => {
  const createTicket = httpsCallable(functions, 'createTreezTicket');
  try {
    const result = await createTicket(ticketData);
    console.log("Treez Create Ticket Response:", result.data);
    return result.data;
  } catch (error) {
    console.error("Error creating Treez ticket:", error);
    if (error.details) {
      console.error("Error details:", error.details);
    }
    if (error.code) {
      console.error("Error code:", error.code);
    }
    throw error;
  }
};

export const listTreezProducts = async ({ dispensary, productType, pageNum, pageSize, customerType }) => {
  const callListTreezProducts = httpsCallable(functions, 'listTreezProducts');

  try {
    const result = await callListTreezProducts({
      dispensary,
      productType,
      pageNum,
      pageSize,
      customerType
    });
    console.log(result.data); // Log the product list
    return result.data; // Return the product list for further processing
  } catch (error) {
    console.error('Error listing Treez products:', error);
    throw error; // Rethrow or handle as needed
  }
};

export const fetchTreezInventory = async () => {
  const getTreezInventory = httpsCallable(functions, 'getTreezInventory');
  
  try {
    console.log("fetching inventory")
    const result = await getTreezInventory(); // Assuming no parameters are needed, or add them as required
    console.log('after fetch')
    console.log("Inventory Data:", result.data); // Log the inventory data
    return result.data; // Return the inventory data for further processing
  } catch (error) {
    console.error("Error calling getTreezInventory:", error);
    throw error; // Rethrow or handle as needed
  }
};

export const fetchTicketData = async () => {
  const getTicketData = httpsCallable(functions, 'getTicketData');
  try {
    const result = await getTicketData();
    console.log("Ticket Data:", result.data);
    return result.data;
  } catch (error) {
    console.error("Error calling getTicketData:", error);
    throw error;
  }
};

// export const fetchIHeartJaneProducts = async () => {
//   const getIHeartJaneProducts = httpsCallable(functions, 'getIHeartJaneMenuProducts');
//   console.log('in jane')
//   try {
//     console.log('in try')
//     const result = await getIHeartJaneProducts();
//     console.log('after call')
//     console.log("IHeartJane Products:", result); // Log the response
//     return result.data;
//   } catch (error) {
//     console.error("Error calling getIHeartJaneMenuProducts:", error);
//     throw error;
//   }
// };

// // TEST ONLY - Fetch Front of House products (does not sync to Firebase)
// export const fetchFrontOfHouseProducts = async () => {
//   const getFrontOfHouseProducts = httpsCallable(functions, 'getFrontOfHouseProducts');
//   try {
//     const result = await getFrontOfHouseProducts();
//     console.log('Front of House Test Product:', result.data);
//     return result.data;
//   } catch (error) {
//     console.error("Error fetching Front of House products:", error);
//     throw error;
//   }
// };

// export const syncNewProducts = async () => {
//   const syncProducts = httpsCallable(functions, 'syncNewProducts');
//   try {
//     const result = await syncProducts({});
//     console.log('🔄 Sync completed:', result.data);
//     return result.data;
//   } catch (error) {
//     console.error('❌ Error syncing products:', error);
//     if (error.details) {
//       console.error('Error details:', error.details);
//     }
//     throw error;
//   }
// };

// export const syncNewProductsTest = async () => {
//   const syncProducts = httpsCallable(functions, 'syncNewProductsTest');
//   try {
//     const result = await syncProducts({});
//     console.log('🔄 Sync completed:', result.data);
//     return result.data;
//   } catch (error) {
//     console.error('❌ Error syncing products:', error);
//     if (error.details) {
//       console.error('Error details:', error.details);
//     }
//     throw error;
//   }
// };