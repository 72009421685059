import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';

// Cache keys
const STORE_KEYS = {
  all: ['stores'],
  active: () => [...STORE_KEYS.all, 'active'],
  byLocation: (lat, lng) => [...STORE_KEYS.all, 'location', lat, lng],
};

/**
 * Fetch active stores with optional location filtering
 * @param {Object} options
 * @param {number} options.page - Page number (1-based)
 * @param {number} options.limit - Items per page
 * @param {number} [options.latitude] - Optional latitude for location-based filtering
 * @param {number} [options.longitude] - Optional longitude for location-based filtering
 * @param {number} [options.radius] - Search radius in miles (default: 50)
 * @returns {Promise} Promise that resolves to paginated store data
 */
const fetchActiveStores = async ({ page = 1, limit = 8, latitude, longitude, radius }) => {
  const getActiveStores = httpsCallable(functions, 'getActiveStores');
  // For single store case, we'll always return it but include distance info if location is provided
  const params = {
    page,
    limit,
    ignoreRadius: true, // Always return the store regardless of distance
    // Temporarily disabled location parameters
    // ...(latitude && longitude ? { latitude, longitude } : {})
  };
  const response = await getActiveStores(params);
  return response.data;
};

/**
 * React Query hook to fetch active stores
 * @param {Object} options - Query options
 * @returns {Object} Query result object
 */
export const useActiveStores = (options = {}) => {
  // Temporarily disabled location parameters
  // const { latitude, longitude, radius, limit = 8 } = options;
  const { limit = 8 } = options;
  
  return useQuery({
    // Temporarily disabled location-based query key
    // queryKey: latitude && longitude 
    //   ? [...STORE_KEYS.byLocation(latitude, longitude), radius]
    //   : STORE_KEYS.active(),
    queryKey: STORE_KEYS.active(),
    // queryFn: () => fetchActiveStores({ page: 1, limit, latitude, longitude, radius }),
    queryFn: () => fetchActiveStores({ page: 1, limit }),
    staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
  });
};

/**
 * React Query hook for infinite scrolling of stores
 * @param {Object} options - Query options
 * @returns {Object} Infinite query result object
 */
export const useInfiniteStores = (options = {}) => {
  // Temporarily disabled location parameters
  // const { latitude, longitude, radius, limit = 8 } = options;
  // const locationKey = latitude && longitude ? `${latitude},${longitude}` : 'no-location';
  const { limit = 8 } = options;

  return useInfiniteQuery({
    // Temporarily disabled location-based query key
    // queryKey: [...STORE_KEYS.all, 'infinite', locationKey, radius],
    queryKey: [...STORE_KEYS.all, 'infinite'],
    queryFn: ({ pageParam = 1 }) => 
      fetchActiveStores({ 
        page: pageParam, 
        limit,
        // Temporarily disabled location parameters
        // latitude, 
        // longitude, 
        // radius 
      }),
    getNextPageParam: (lastPage) => 
      lastPage.pagination.hasMore ? lastPage.pagination.currentPage + 1 : undefined,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

/**
 * Format store hours for display
 * @param {Object} hours - Store hours object
 * @param {string} timezone - Store timezone
 * @returns {string} Formatted hours string
 */
export const formatStoreHours = (hours, timezone) => {
  if (!hours) return 'Hours not available';
  
  const now = new Date();
  const zonedDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
  const dayOfWeek = zonedDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
  
  const todayHours = hours[dayOfWeek];
  if (!todayHours?.open || !todayHours?.close) return 'Hours not available';
  
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit', 
      hour12: true 
    });
  };
  
  return `Today: ${formatTime(todayHours.open)} - ${formatTime(todayHours.close)}`;
};

/**
 * Calculate if store is currently open
 * @param {Object} hours - Store hours object
 * @param {string} timezone - Store timezone
 * @returns {boolean} Whether store is currently open
 */
export const isStoreOpen = (hours, timezone) => {
  if (!hours) return false;
  
  const now = new Date();
  const zonedDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
  const dayOfWeek = zonedDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
  
  const todayHours = hours[dayOfWeek];
  if (!todayHours?.isOpen || !todayHours?.open || !todayHours?.close) return false;
  
  const currentTime = zonedDate.toLocaleTimeString('en-US', { 
    hour12: false, 
    hour: '2-digit', 
    minute: '2-digit' 
  });
  
  return currentTime >= todayHours.open && currentTime <= todayHours.close;
};

/**
 * Format store address for display
 * @param {string} address - Full address string
 * @returns {string} Formatted address (City, State)
 */
export const formatStoreAddress = (address) => {
  if (!address) return '';
  const parts = address.split(',');
  if (parts.length >= 2) {
    return `${parts[1].trim()}, ${parts[2].trim()}`;
  }
  return address;
};
