import * as actionTypes from './actionTypes';

export const setUser = user => {
  // Convert Firestore timestamps to ISO strings
  const serializedUser = user ? {
    ...user,
    createdAt: user.createdAt?.toDate?.()?.toISOString() || null,
    lastUpdatedAt: user.lastUpdatedAt?.toDate?.()?.toISOString() || null,
  } : null;

  return {
    type: actionTypes.SET_USER,
    payload: serializedUser
  };
};

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};