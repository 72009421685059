import "./BuyNowButton.css";

const BuyNowButton = ({ buttonText, action, actionParam }) => {

  return (
    <>
      <div className="buy-now-container" onClick={() => {
        console.log('BuyNowButton clicked');
        action(actionParam);
      }}>
        <div className="buy-now1">{buttonText}</div>
      </div>
      
    </>
  );
};

export default BuyNowButton;
