import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { addProductToCart, updateCartItemQuantity } from '../../redux/actions/orderActions';
import ItemDetailsModal from './ItemDetailsModal';
import BuyNowModal from './BuyNowModal';
import PortalPopup from './PortalPopup';
import BuyNowButton from "./BuyNowButton";
import AddToStashButton from "./AddToStashButton";
import QuantityCounter from "./QuantityCounter";
import ShopNowButton from "./ShopNowButton";
import defaultProductImage from '../../public/cherry-ak--1removebgpreview-1@2x.png';
import defaultStoreImage from '../../public/default-store-image.svg';
import starIcon from '../../public/star.svg';
import locationIcon from '../../public/location.svg';
import StoreDetailsModal from './StoreDetailsModal';
import pickupBadge from '../../public/pickup-badge.svg';
import deliveryBadge from '../../public/delivery-badge.svg';
import { IonIcon } from '@ionic/react';
import { locationOutline, timeOutline } from 'ionicons/icons';

import "./BaseCard.css";

const storage = getStorage();

const categories = [
  { src: "/marijuana-1@2x.png", alt: "Flower", title: "Flower", className: "flower", category: "FLOWER" },
  { src: "/vaping-1-2@2x.png", alt: "Vapes", title: "Vapes", className: "vapes", category: "CARTRIDGE" },
  { src: "/chocolatebar-2@2x.png", alt: "Edibles", title: "Edibles", className: "edibles", category: "EDIBLE" },
  { src: "/man-1@2x.png", alt: "Pre-Rolls", title: "Pre-Rolls", className: "pre-rolls", category: "PREROLL" },
  { src: "/wax.png", alt: "Extracts", title: "Extracts", className: "extracts", category: "EXTRACT" },
];

const BaseCard = React.memo(({ 
  cardType = 'product',  // 'product' or 'store'
  propWidth, 
  propAlignSelf, 
  id, 
  cartItemId,
  name, 
  price, 
  discountedPrice, 
  category_type,
  classification,
  brand,
  lab_results,
  sellableQuantity,
  stockStatus,
  imageUrl, 
  description,
  onLoginClick,
  onPurchaseComplete,
  // Store specific props
  address,
  hours,
  timezone,
  settings,
  metrics,
  location,
  userLocation,
  featuredProducts = [],
  isLoading = false,
  type, 
  sp,
  onSeeAllClick,
  onShopNowClick,
  onStoreSelect,
  product, // Add product prop
  }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.order.cart);
  const user = useSelector(state => state.user);

  const [isItemDetailsModalOpen, setItemDetailsModalOpen] = useState(false);
  const [isBuyNowModalOpen, setBuyNowModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addedToStash, setAddedToStash] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [imageUrlFix, setImageUrlFix] = useState('/default-product-image.png');
  const [isCategoryImage, setIsCategoryImage] = useState(false);
  const loadedImages = useRef(new Set());
  const [isImageLoading, setIsImageLoading] = useState(!loadedImages.current.has(imageUrl));

  // Debug log
  // console.log('ProductCard props:', { name, brand, price, discountedPrice });

  useEffect(() => {
    const cartItem = cartItems.find(item => item.id === id);
    if (cartItem) {
      setAddedToStash(true);
      setQuantity(cartItem.quantity);
    } else {
      setAddedToStash(false);
      setQuantity(0);
    }
  }, [cartItems, id]);

  const handleImageError = () => {
    if (cardType === 'product') {
      // First try category_type, then check categories array
      const categoryItem = categories.find(cat => 
        cat.category === category_type || 
        (product?.categories || []).includes(cat.category)
      ) || categories[0];
      
      setImageUrlFix(categoryItem.src);
      setIsCategoryImage(true);
    } else {
      setImageUrlFix(defaultStoreImage);
      setIsCategoryImage(false);
    }
    setIsImageLoading(false);
  };

  useEffect(() => {
    if (loadedImages.current.has(imageUrl)) {
      setIsImageLoading(false);
      return;
    }
    
    if (!imageUrl) {
      handleImageError();
      return;
    }

    const img = new Image();
    img.onload = () => {
      loadedImages.current.add(imageUrl);
      setIsImageLoading(false);
      setImageUrlFix(imageUrl);
      setIsCategoryImage(false);
    };
    img.onerror = handleImageError;
    img.src = imageUrl;
  }, [imageUrl]);

  const handleQuantityChange = (newQuantity) => {
    dispatch(updateCartItemQuantity(null, id, newQuantity));
  };

  const handleAddToStash = () => {
    dispatch(addProductToCart(product));
    setAddedToStash(true);
  };

  const productDetails = {
    ...product, // Pass the full product object
    id,
    name,
    price,
    discountedPrice,
    category_type,
    classification,
    brand,
    lab_results,
    sellableQuantity,
    imageUrl: imageUrlFix,
    description,
    sp: product?.sp, // Explicitly pass sp data
    stores: product?.stores // Explicitly pass stores data
  };

  const openItemDetailsModal = useCallback((productFromBuyNow, priceFromBuyNow, originalPriceFromBuyNow) => {
    if (productFromBuyNow) {
      setSelectedProduct(productFromBuyNow);
    }
    setItemDetailsModalOpen(true);
  }, []);

  const closeItemDetailsModal = useCallback(() => {
    setItemDetailsModalOpen(false);
  }, []);

  const openBuyNowModal = useCallback((product) => {
    setSelectedProduct(product);
    setBuyNowModalOpen(true);
  }, []);

  const closeBuyNowModal = useCallback(() => {
    // console.log("Closing Buy Now Modal");
    setBuyNowModalOpen(false);
    setSelectedProduct(null); // Clear the selected product when closing the modal
  }, []);

  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth || '250px',
    };
  }, [propWidth]);

  const frameDiv1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const currentPrice = discountedPrice || price || 0;
  const originalPrice = price || 0;
  const discountPercentage = discountedPrice ? Math.round(((price - discountedPrice) / price) * 100) : 0;

  const lineageLabel = useMemo(() => {
    if (classification) {
      switch (classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: `rgba(var(--indica-color), var(--label-opacity))` };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: `rgba(var(--sativa-color), var(--label-opacity))` }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: `rgba(var(--cbd-color), var(--label-opacity))` };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: `rgba(var(--hybrid-color), var(--label-opacity))` };
        default:
          return null;
      }
    }
    return null;
  }, [classification]);

  const getFulfillmentLabel = () => {
    const pickupEnabled = settings?.pickup?.enabled;
    const deliveryEnabled = settings?.delivery?.enabled;

    if (pickupEnabled && deliveryEnabled) {
      return {
        text: 'Pickup + Delivery',
        backgroundColor: `rgba(var(--indica-color), var(--label-opacity))`
      };
    } else if (pickupEnabled) {
      return {
        text: 'Pickup Only',
        backgroundColor: `rgba(var(--indica-color), var(--label-opacity))`
      };
    } else if (deliveryEnabled) {
      return {
        text: 'Delivery Only',
        backgroundColor: `rgba(var(--indica-color), var(--label-opacity))`
      };
    }
    return null;
  };

  

  const handleImageLoad = (e) => {
    e.target.classList.add('loaded');
  };

  const formatAddress = () => {
    if (!address) return { street: '', location: '' };
    
    // Remove country from the address
    const addressWithoutCountry = address.replace(/, USA$/, '');
    
    const [street, ...rest] = addressWithoutCountry.split(',');
    return {
      street: street.trim(),
      location: rest.join(',').trim()
    };
  };

  const getDayName = (dayIndex) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[dayIndex];
  };

  const formatTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    let period = 'am';
    let displayHours = hours;
    
    if (hours >= 12) {
      period = 'pm';
      if (hours > 12) {
        displayHours = hours - 12;
      }
    }
    if (hours === 0) {
      displayHours = 12;
    }
    
    return `${displayHours}:${minutes.toString().padStart(2, '0')}${period}`;
  };

  const formatTodayHours = () => {
    if (!hours) return 'Hours not available';
    
    const now = new Date();
    const storeTimezone = hours.timezone || timezone;
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: storeTimezone }));
    const dayName = getDayName(localTime.getDay());
    const todayHours = hours[dayName];
    
    if (!todayHours || !todayHours.open || !todayHours.close) {
      return 'Closed today';
    }
    return `${formatTime(todayHours.open)} - ${formatTime(todayHours.close)}`;
  };

  const isClosingSoon = () => {
    if (!hours) return false;
    
    const now = new Date();
    const storeTimezone = hours.timezone || timezone;
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: storeTimezone }));
    const dayName = getDayName(localTime.getDay());
    const todayHours = hours[dayName];
    
    if (!todayHours?.close) return false;
    
    const [closeHour, closeMinute] = todayHours.close.split(':').map(Number);
    const closeTime = new Date(localTime);
    closeTime.setHours(closeHour, closeMinute, 0);
    
    const timeDiff = closeTime - localTime;
    const hoursDiff = timeDiff / (1000 * 60 * 60);
    
    return hoursDiff > 0 && hoursDiff <= 1;
  };

  const pickupBadge = "/pickup-badge.svg";
  const deliveryBadge = "/delivery-badge.svg";

  return (
    <div 
      className="product-card" 
      style={{ width: propWidth, alignSelf: propAlignSelf }}
      data-card-type={cardType}
    >
      <div 
        className="card-upper-content"
        onClick={() => openItemDetailsModal(productDetails)}
        style={{ cursor: 'pointer' }}
      >
        <div className="image-container">
          {isImageLoading ? (
            <div className="skeleton-image"></div>
          ) : (
            <div className="image-wrapper">
              <img
                className={`${cardType === 'product' ? 'product-image' : 'store-image'} ${isCategoryImage ? 'category-image' : ''}`}
                loading="lazy"
                alt={cardType === 'product' ? `${name || 'Product'} Image` : `${name || 'Store'} Image`}
                src={imageUrlFix}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
              
              {cardType === 'product' ? (
                // Product card labels
                <>
                  <div className="price-label-bc">{discountPercentage}% OFF</div>
                  {lineageLabel && (
                    <div 
                      className="lineage-label-bc" 
                      style={{ backgroundColor: lineageLabel.backgroundColor }}
                    >
                      {lineageLabel.text}
                    </div>
                  )}
                </>
              ) : (
                // Store card labels
                <>
                  <div className="price-label-bc">Up to 40% Off</div>
                  {getFulfillmentLabel() && (
                    <div 
                      className="lineage-label-bc" 
                      style={{ backgroundColor: getFulfillmentLabel().backgroundColor }}
                    >
                      {getFulfillmentLabel().text}
                    </div>
                  )}
                </>
              )}
              <img
                className="expand-icon"
                src="informationbutton-1-traced.svg"
                alt="Expand"
                onClick={() => openItemDetailsModal(productDetails)}
              />
            </div>
          )}
        </div>
        
        <div className={` ${cardType === 'store' ? 'store-info' : 'product-info'}`}>
          
          {cardType === 'product' ? (
            <>
              <h3 className="product-name">{name}</h3>
              <div className="product-brand">{brand}</div>
              <div className="price-info-bc">
                <div className="prices-only">
                  <span className="original-price-bc">${originalPrice.toFixed(2)}</span>
                  <span className="current-price-bc">${currentPrice.toFixed(2)}</span>
                </div>
                <div className={`stock-label-only ${stockStatus ? stockStatus.toLowerCase() : ''}`}>
                  {stockStatus === 'LOW_STOCK' && (
                    <span className="stock-status">
                      Only {sellableQuantity} left
                    </span>
                  )}
                  {stockStatus === 'OUT_OF_STOCK' && (
                    <span className="stock-status">
                      Out of Stock
                    </span>
                  )}
                </div>
              </div>
            </>
          ) : (
            cardType === 'store' && (
              <>
                <h3 className="store-name">{name}</h3>
                <div className="store-location">
                  <IonIcon icon={locationOutline} className="store-icon" />
                  <div className="store-text">
                    <div className="street-address">{formatAddress(address).street}</div>
                    <div className="location-address">{formatAddress(address).location}</div>
                    {/* Temporarily disabled distance display
                    <div className="distance">
                      {location && userLocation ? 
                        `${(location.distanceMiles || 0).toFixed(1)} miles away` :
                        '1.5 miles away'
                      }
                    </div>
                    */}
                  </div>
                </div>
                <div className="store-hours">
                  <IonIcon icon={timeOutline} className="store-icon" />
                  <div className="store-text">
                    <div>Today: {formatTodayHours(hours, timezone)}</div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
      <div className="divider-bc" />
      <div className="action-buttons">
        {cardType === 'product' ? (
          // Product card buttons
          user && user.email ? (
            <>
              <BuyNowButton buttonText="Buy Now" action={openBuyNowModal.bind(null, productDetails)} />
              {addedToStash ? (
                <QuantityCounter 
                  inventoryCount={sellableQuantity} 
                  onQuantityChange={handleQuantityChange}
                  initialQuantity={quantity}
                  productId={id}
                />
              ) : (
                <AddToStashButton 
                  title="Add to Stash" 
                  action={handleAddToStash}
                />
              )}
            </>
          ) : (
            <AddToStashButton 
              title="Login to Buy" 
              action={onLoginClick}
            />
          )
        ) : (
          // Store card button
          <ShopNowButton onClick={onStoreSelect} />
        )}
      </div>
      {isItemDetailsModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeItemDetailsModal}
        >
          <ItemDetailsModal 
            product={selectedProduct || product || productDetails} 
            onClose={closeItemDetailsModal}
            currentPrice={discountedPrice || price}
            originalPrice={price}
          />
        </PortalPopup>
      )}
      {isBuyNowModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeBuyNowModal}
        >
          <BuyNowModal 
            product={product || productDetails}
            currentPrice={discountedPrice || price}
            originalPrice={price}
            onClose={closeBuyNowModal}
            onOpenItemDetails={openItemDetailsModal}
            onPurchaseComplete={onPurchaseComplete}
            initialQuantity={quantity}
          />   
        </PortalPopup>
      )}
    </div>
  );
});

export default BaseCard;
