import { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOM from 'react-dom';
import { useSelector } from "react-redux";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import {getInStockProductsFast} from "../../utils/FirebaseUtils";
import { loadProductsSmooth } from "../utils/smoothProductLoad";
import HomeHero from "../components/HomeHero";
import CategorySelect from "../components/CategorySelect";
import ItemGallery from "../components/ItemGallery";
import SpotlightBanner from "../components/SpotlightBanner";
import Footer from "../components/Footer";
import TopNav from "../components/TopNav";
import PortalPopup from '../components/PortalPopup';
import AuthModal from '../components/AuthModal';
import StashModal from '../components/StashModal';
import TransitionModal from "../components/TransitionModal";
import defaultStoreImage from '../../public/default-store-image.svg';
import { IonIcon } from '@ionic/react';
import { locationOutline, timeOutline } from 'ionicons/icons';
import "./HomeScreen.css";
import { useInfiniteStores } from '../utils/storeUtils';
import { useGeolocation } from '../hooks/useGeolocation';

const HomeScreen = ({ openAuthModal, openStashModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState('FLOWER');
  const [availableCategories, setAvailableCategories] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [seeAll, setSeeAll] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [isStashModalOpen, setIsStashModalOpen] = useState(false);
  const [isTransitionModalOpen, setTransitionModalOpen] = useState(false);
  const [transitionModalMode, setTransitionModalMode] = useState('stash')
  const [stashModalMode, setStashModalMode] = useState('stash');

  const user = useSelector((state) => state.user);
  const productsState = useSelector((state) => state.products);
  const PAGE_SIZE = 1000;

  useEffect(() => {
    console.log('Redux Products State:', productsState);
  }, [productsState]);

  // useEffect(() => {
  //   console.log('User info:', user);
  // }, [user]);

  // useEffect(() => {
  //   console.log('=== Products State Update ===');
  //   console.log('Products length:', products.length);
  //   console.log('Selected type:', selectedProductType);
  //   console.log('Products state:', products.map(p => ({
  //     name: p.name,
  //     type: p.category_type,
  //     quantity: p.sellable_quantity
  //   })));
  // }, [products, selectedProductType]);

  // useEffect(() => {
  //   console.log('=== Categories Update ===');
  //   console.log('Available product types:', availableCategories);
  //   console.log('Selected type:', selectedProductType);
  // }, [availableCategories, selectedProductType]);

  // Update available categories whenever products change
  // useEffect(() => {
  //   const categories = new Set();
  //   products.forEach(p => {
  //     if (p.sellable_quantity > 0) {
  //       categories.add(p.category_type);
  //     }
  //   });
  //   setAvailableCategories(categories);
  // }, [products]);

  const openTransitionModal = (mode) => {
    setTransitionModalMode(mode);
    setTransitionModalOpen(true);
  };

  const loadProducts = useCallback(async (page = 1) => {
    // Only show loading spinner on initial load (page 1)
    if (page === 1) {
      setIsLoading(true);
    }
    
    try {
      const { products: newProducts, total, hasMore, availableCategories: newCategories } = 
        await loadProductsSmooth('JlezFXhoNl4lVpibJZDx', page, PAGE_SIZE);
        
      console.log('Loaded Products Data:', {
        numberOfProducts: newProducts.length,
        sampleProduct: newProducts[0],
        allFields: newProducts[0] ? Object.keys(newProducts[0]) : [],
        totalProducts: total,
        hasMorePages: hasMore
      });
  
      // Update products first to ensure they're available
      setProducts(prev => {
        if (page === 1) return newProducts;
        const existingIds = new Set(prev.map(p => p.id));
        return [...prev, ...newProducts.filter(p => !existingIds.has(p.id))];
      });

      // Then update the rest
      if (page === 1 && newCategories?.length) {
        const orderedTypes = ['FLOWER', 'CARTRIDGE', 'EDIBLE', 'PREROLL', 'EXTRACT'];
        const firstAvailable = orderedTypes.find(type => newCategories.includes(type));
        setAvailableCategories(new Set(newCategories));
        if (firstAvailable && !newCategories.includes(selectedProductType)) {
          setSelectedProductType(firstAvailable);
        }
      }

      setTotalProducts(total);
      setHasMore(hasMore);
      setCurrentPage(page);
      
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      if (page === 1) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    loadProducts(1);
  }, [loadProducts]);

  useEffect(() => {
    const storeId = selectedStore?.id || 'JlezFXhoNl4lVpibJZDx';
    // console.log('👂 [Listen] Setting up inventory listener for store:', storeId);
    
    const storeRef = collection(db, 'stores', storeId, 'inventory');
    const unsubscribe = onSnapshot(storeRef, (snapshot) => {
      const changes = snapshot.docChanges();
      
      // Only process if we have changes
      if (changes.length > 0) {
        // console.log('📝 [Listen] Received inventory changes:', {
        //   total: changes.length,
        //   types: changes.map(c => c.type),
        //   ids: changes.map(c => c.doc.id)
        // });
        
        setProducts(currentProducts => {
          // console.log('🔍 [Listen] Current products:', currentProducts.length);
          let productsNeedUpdate = false;
          const updatedProducts = currentProducts.map(product => {
            // Find if this product was modified
            const change = changes.find(change => change.doc.id === product.id);
            if (change) {
              const newData = change.doc.data();
              // Only update if we have valid quantity
              if (typeof newData.sellable_quantity === 'number') {
                // console.log(`✏️ [Listen] Product ${product.id}:`, {
                //   type: change.type,
                //   oldQuantity: product.sellable_quantity,
                //   newQuantity: newData.sellable_quantity
                // });
                productsNeedUpdate = true;
                if (change.type === 'removed' || newData.sellable_quantity === 0) {
                  return null; // Will be filtered out
                }
                return {
                  ...product,
                  ...newData
                };
              }
            }
            return product;
          }).filter(Boolean); // Remove null products

          // Add any new products
          changes.forEach(change => {
            const newData = change.doc.data();
            if (change.type === 'added' && 
                typeof newData.sellable_quantity === 'number' && 
                newData.sellable_quantity > 0 &&
                !updatedProducts.find(p => p.id === change.doc.id)) {
              // console.log('➕ [Listen] Adding new product:', {
              //   id: change.doc.id,
              //   quantity: newData.sellable_quantity
              // });
              productsNeedUpdate = true;
              updatedProducts.push({
                id: change.doc.id,
                ...newData
              });
            }
          });

          if (productsNeedUpdate) {
            // console.log('✅ [Listen] Updated products:', {
            //   before: currentProducts.length,
            //   after: updatedProducts.length,
            //   diff: updatedProducts.length - currentProducts.length
            // });
          }

          return productsNeedUpdate ? updatedProducts : currentProducts;
        });
      }
    }, (error) => {
      console.error('❌ [Listen] Error in inventory listener:', error);
    });

    return () => {
      // console.log('👋 [Listen] Cleaning up listener for store:', storeId);
      unsubscribe();
    };
  }, [selectedStore?.id]);

  // Memoize filtered products with store filter
  const filteredProducts = useMemo(() => {
    // Debug log for filtering
    console.log('Category Filter Debug:', {
      totalProducts: products.length,
      selectedType: selectedProductType,
      selectedStore: selectedStore,
      productTypes: products.map(p => ({
        name: p.name,
        category_type: p.category_type,
        categories: p.categories
      }))
    });

    if (!selectedProductType || selectedProductType === 'ALL') {
      return products;
    }

    return products.filter(product => {
      const matchesType = product.category_type === selectedProductType;
      const matchesCategories = product.categories?.includes(selectedProductType);
      return matchesType || matchesCategories;
    });
  }, [products, selectedProductType, selectedStore]);

  const dealProducts = useMemo(() => {
    return products
      .filter(product => {
        if (!product.sellable_quantity > 0 || !product.pricing?.price_sell || !product.pricing?.discounted_price) return false;
        const discountPercentage = ((product.pricing.price_sell - product.pricing.discounted_price) / product.pricing.price_sell) * 100;
        return discountPercentage >= 30;
      })
      .sort((a, b) => {
        const discountA = ((a.pricing.price_sell - a.pricing.discounted_price) / a.pricing.price_sell) * 100;
        const discountB = ((b.pricing.price_sell - b.pricing.discounted_price) / b.pricing.price_sell) * 100;
        return discountB - discountA;  // Sort descending (highest discount first)
      });
  }, [products]);

  // Handle category change without reload
  const handleCategoryChange = useCallback((newCategory) => {
    setSelectedProductType(newCategory);
  }, []);

  // Load more function for infinite scroll
  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      loadProducts(currentPage + 1);
    }
  }, [isLoading, hasMore, currentPage, loadProducts]);

  const openAuthModalLocal = () => setAuthModalOpen(true);
  const closeAuthModalLocal = () => setAuthModalOpen(false);
  const openStashModalLocal = (mode) => {
    setStashModalMode(mode);
    setIsStashModalOpen(true);
  };
  const closeStashModalLocal = () => setIsStashModalOpen(false);

  const handleBackClick = () => {
    setSeeAll(false);
    setSelectedStore(null);
  };

  const handleSeeAllClick = () => {
    setSeeAll(true);
  };

  const getFilteredProducts = useCallback((productsToFilter) => {
    if (!productsToFilter) return [];
    return productsToFilter.filter(product => 
      product.category_type.toUpperCase() === selectedProductType.toUpperCase()
    );
  }, [selectedProductType]);

  // Add store-related state and hooks
  // Temporarily disabled geolocation
  // const { location: userLocation, error: locationError } = useGeolocation();
  const { 
    data: storesData,
    fetchNextPage: fetchNextStoresPage,
    hasNextPage: hasMoreStores,
    isLoading: isLoadingStores,
    isFetching: isFetchingStores
  } = useInfiniteStores({
    // Temporarily disabled location-based filtering
    // ...(userLocation ? {
    //   latitude: userLocation.latitude,
    //   longitude: userLocation.longitude
    // } : {}),
    limit: 8,
  });

  // Combine isLoading and isFetching for UI state
  const isStoresLoading = isLoadingStores || isFetchingStores;

  // Memoize flattened stores data
  const stores = useMemo(() => {
    if (!storesData?.pages) return [];
    const allStores = storesData.pages.flatMap(page => page.stores);
    
    // Temporarily disabled location-based sorting
    // if (userLocation) {
    //   return allStores.sort((a, b) => {
    //     const distanceA = a.distance || Number.MAX_VALUE;
    //     const distanceB = b.distance || Number.MAX_VALUE;
    //     return distanceA - distanceB;
    //   });
    // }
    
    return allStores;
  }, [storesData]);

  // Handle location error gracefully
  // Temporarily disabled location error handling
  // useEffect(() => {
  //   if (locationError) {
  //     console.log('Location access denied or error occurred. Loading all stores.');
  //   }
  // }, [locationError]);

  // Add store handlers
  const handleSeeAllStores = () => {
    // navigate('/stores');
  };

  const loadMoreStores = () => {
    if (hasMoreStores) {
      fetchNextStoresPage();
    }
  };

  const handleStoreSelect = async (store) => {
    try {
      setIsLoading(true);
      // Get store's products from their subcollection
      const { products: storeProducts, total, hasMore } = await getInStockProductsFast(store.id, 1, PAGE_SIZE);
      
      // Update store with its products
      setSelectedStore({
        ...store,
        products: storeProducts
      });
      
      setTotalProducts(total);
      setHasMore(hasMore);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error loading store products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatAddress = (address) => {
    if (!address) return { street: '', location: '' };
    const addressWithoutCountry = address.replace(/, USA$/, '');
    const [street, ...rest] = addressWithoutCountry.split(',');
    return {
      street: street.trim(),
      location: rest.join(',').trim()
    };
  };

  const formatTodayHours = (hours, timezone) => {
    if (!hours) return 'Hours not available';
    
    const now = new Date();
    const storeTimezone = timezone || 'America/Los_Angeles';
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: storeTimezone }));
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayName = days[localTime.getDay()];
    const todayHours = hours[dayName];
    
    if (!todayHours || !todayHours.open || !todayHours.close) {
      return 'Closed';
    }
    
    const formatTime = (timeStr) => {
      const [hours, minutes] = timeStr.split(':').map(Number);
      let period = 'am';
      let displayHours = hours;
      
      if (hours >= 12) {
        period = 'pm';
        if (hours > 12) {
          displayHours = hours - 12;
        }
      }
      if (hours === 0) {
        displayHours = 12;
      }
      
      return `${displayHours}:${minutes.toString().padStart(2, '0')}${period}`;
    };

    return `${formatTime(todayHours.open)} - ${formatTime(todayHours.close)}`;
  };

  return (
    <div className="homescreen">
      <main className="home-page">
        <section className="frame-parent">
          <div className={`category-select-wrapper ${seeAll || selectedStore !== null ? 'fade-up' : ''}`}>
            {(seeAll || selectedStore) && (
              <div className="back-arrow" onClick={handleBackClick}>
                ← Back
              </div>
            )}
            <div className="section-header-container">
              <div 
                className="section-header" 
                data-text={`THE STASH${selectedStore ? ` @ ${selectedStore.name.toUpperCase()}` : ''}`}
              >
                THE STASH{selectedStore ? ` @ ${selectedStore.name.toUpperCase()}` : ''}
              </div>
              {selectedStore && (
                <img 
                  src={selectedStore.imageUrl || defaultStoreImage} 
                  alt={selectedStore.name}
                  className="store-header-image"
                />
              )}
            </div>
            {selectedStore && (
              <div className="store-details-card">
                <div className="store-info-line">
                  <img 
                    src={selectedStore.imageUrl || defaultStoreImage} 
                    alt={selectedStore.name}
                    className="store-card-icon"
                  />
                  <span className="store-name-text">{selectedStore.name}</span>
                </div>
                <div className="store-info-line">
                  <IonIcon icon={locationOutline} className="store-icon" />
                  <span>{formatAddress(selectedStore.address).street}, {formatAddress(selectedStore.address).location}</span>
                </div>
                <div className="store-info-line">
                  <IonIcon icon={timeOutline} className="store-icon" />
                  <span>Today: {formatTodayHours(selectedStore.hours, selectedStore.timezone)}</span>
                </div>
              </div>
            )}
            <CategorySelect 
              setSelectedProductType={handleCategoryChange}
              selectedProductType={selectedProductType}
              availableCategories={availableCategories}
              seeAll={seeAll || selectedStore !== null}
              setSeeAll={setSeeAll}
              handleBackClick={handleBackClick}
              products={products}
            />
          </div>
          <img
            className="night-mode-1-traced"
            alt=""
            src="/nightmode-1-traced.svg"
          />
          <img
            className="cloud-1-traced1"
            loading="lazy"
            alt=""
            src="/cloud-1-traced.svg"
          />
          {isLoading ? 
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
            : 
            <>
              <div className="product-gallery-wrapper">
                <ItemGallery 
                  products={filteredProducts}
                  onSeeAllClick={handleSeeAllClick} 
                  seeAll={seeAll}
                  onLoginClick={openAuthModalLocal}
                  onPurchaseComplete={() => openTransitionModal('history')}
                  onLoadMore={loadMore}
                  hasMore={hasMore}
                  onStoreSelect={handleStoreSelect}
                />
              </div>
              {!seeAll && !selectedStore ? (
                <>
                  <SpotlightBanner className={fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''} />
                  {/* <div className={`category-select-wrapper ${seeAll || selectedStore !== null ? 'fade-up' : ''}`}> */}
                    <div className="product-gallery-wrapper">
                      <div className="section-header" data-text="THE DEALS">
                        THE DEALS
                      </div>
                      <ItemGallery 
                        products={dealProducts}
                        onSeeAllClick={handleSeeAllClick} 
                        seeAll={false}
                        onLoginClick={openAuthModalLocal}
                        onPurchaseComplete={() => openTransitionModal('history')}
                        onLoadMore={loadMore}
                        hasMore={hasMore}
                      />
                    </div>
                  {/* </div> */}
                  <div className={`category-select-wrapper ${seeAll || selectedStore !== null ? 'fade-up' : ''}`}>
                    <div className="product-gallery-wrapper">
                      <div className="section-header" data-text="THE STORES">
                        THE STORES
                      </div>
                      <ItemGallery 
                        stores={stores}
                        onSeeAllClick={handleSeeAllStores}
                        seeAll={false}
                        onLoadMore={loadMoreStores}
                        hasMore={hasMoreStores}
                        isLoading={isStoresLoading}
                        userLocation={null}
                        onStoreSelect={handleStoreSelect}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              
            </>
          }
          {!seeAll && !selectedStore && <Footer className={`footer ${fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''}`} />}
        </section>
        <TopNav user={user} products={products} onLoginClick={openAuthModalLocal} />
        {isAuthModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeAuthModalLocal}
          >
            <AuthModal onClose={closeAuthModalLocal} />
          </PortalPopup>
        )}
        {isStashModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeStashModalLocal}
          >
            <StashModal
              onClose={closeStashModalLocal}
              products={products}
              initialMode={stashModalMode}
            />
          </PortalPopup>
        )}
        {isTransitionModalOpen && (
          <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setTransitionModalOpen(false)}
          >
            <TransitionModal
              onClose={() => setTransitionModalOpen(false)}
              products={products}
              initialMode={transitionModalMode}
              openAccountModal={openAuthModal}
            />
          </PortalPopup>
        )}
      </main>
    </div>
  );
};

export default HomeScreen;