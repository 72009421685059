import * as actionTypes from '../actions/actionTypes';

const initialState = {
  email: '',
  userId: '',
  stripeCustomerId: '',
  role: '',
  accountName: '',
  legalName: '',
  birthday: '',
  purchaseStatus: '',
  idType: '',
  licenseState: '',
  idNumber: '',
  phoneNumber: '',
  photoUrl: '',
  createdAt: null,
  lastUpdatedAt: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      // Timestamps should already be serialized as ISO strings
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CLEAR_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
