// actionTypes.js
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';
export const ATTACH_PAYMENT_METHOD_SUCCESS = 'ATTACH_PAYMENT_METHOD_SUCCESS';
export const ATTACH_PAYMENT_METHOD_FAIL = 'ATTACH_PAYMENT_METHOD_FAIL';
export const DETACH_PAYMENT_METHOD_SUCCESS = 'DETACH_PAYMENT_METHOD_SUCCESS';
export const DETACH_PAYMENT_METHOD_FAIL = 'DETACH_PAYMENT_METHOD_FAIL';
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = 'SET_DEFAULT_PAYMENT_METHOD_SUCCESS';
export const SET_DEFAULT_PAYMENT_METHOD_FAIL = 'SET_DEFAULT_PAYMENT_METHOD_FAIL';
export const CREATE_STRIPE_CUSTOMER_SUCCESS = 'CREATE_STRIPE_CUSTOMER_SUCCESS';
export const CREATE_STRIPE_CUSTOMER_FAIL = 'CREATE_STRIPE_CUSTOMER_FAIL';

export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const SET_CART = 'SET_CART';
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export const SET_PRODUCT_ACTIONS_LOADING = 'SET_PRODUCT_ACTIONS_LOADING';
export const SET_PRODUCT_ACTIONS_ERROR = 'SET_PRODUCT_ACTIONS_ERROR';