import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp, getDoc, doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import { IonIcon } from '@ionic/react';
import { timeOutline, bagOutline, cardOutline, helpCircleOutline } from 'ionicons/icons';

import { removeFromCart, updateCartItemQuantity, addProductToCart, createOrder } from '../../redux/actions/orderActions';
import ModalHeader from "./ModalHeader";
import StashItem from "./StashItem";
import OrderDetails from "./OrderDetails";
import LocationFull from "./LocationFull";
import VerificationForm from "./VerificationForm";
import { previewTreezTicket, createTreezTicket } from '../../utils/TreezService';
import OrderItem from './OrderItem';
import { useOrders } from '../hooks/useOrders';

import "./StashModal.css";

const StashModal = ({ onClose, products, onPurchaseComplete, initialMode = 'stash' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [latestProducts, setLatestProducts] = useState({});
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const cartItems = useSelector(state => state.order.cart);
  const { orders, loading: ordersLoading, error: ordersError } = useOrders(user?.userId);
  console.log('cartItems:', cartItems);
  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  useEffect(() => {
    // Add modal-open class to body when component mounts
    document.body.classList.add('modal-open');

    // Clean up function to remove class when component unmounts
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    // Set loading state when switching to history mode
    if (mode === 'history') {
      setIsHistoryLoading(true);
      // Use a small timeout to prevent flash
      const timer = setTimeout(() => {
        setIsHistoryLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [mode]);

  useEffect(() => {
    const fetchLatestProductData = async () => {
      setIsLoadingProducts(true);
      const updatedProducts = {};
      
      for (const item of cartItems) {
        try {
          const productRef = doc(db, 'products', item.id);
          const productDoc = await getDoc(productRef);
          
          if (productDoc.exists()) {
            const productData = productDoc.data();
            
            // Get store-specific data
            const storeRef = doc(db, 'products', item.id, 'stores', item.store_id);
            const storeDoc = await getDoc(storeRef);
            const storeData = storeDoc.exists() ? storeDoc.data() : {};
            
            updatedProducts[item.id] = {
              ...productData,
              ...storeData,
              id: item.id,
              sellableQuantity: storeData.sellable_quantity || 0
            };
          }
        } catch (err) {
          console.error('Error fetching latest product data:', err);
        }
      }
      
      setLatestProducts(updatedProducts);
      setIsLoadingProducts(false);
    };

    if (cartItems.length > 0) {
      fetchLatestProductData();
    }
  }, [cartItems]);

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  const handlePurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: cartItems.map(item => ({
          // location_name: "STASHPANDA",
          size_id: item.id,
          quantity: item.quantity,
        })),
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      console.log("[StashModal] Creating Treez ticket with data:", ticketData);
      const result = await createTreezTicket(ticketData);
      console.log("[StashModal] Creating Treez ticket:", result);
      if (result.resultCode === 'SUCCESS') {
        const totalAmount = cartItems.reduce((total, item) => {
          const product = latestProducts[item.id];
          const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
          return total + parseFloat((price * item.quantity).toFixed(2));
        }, 0);
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: cartItems.map(item => {
            const product = latestProducts[item.id];
            const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
            return {
              productId: item.id,
              quantity: item.quantity,
              price: price,
            };
          }),
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
          isDirectPurchase: false
        };

        const orderId = await dispatch(createOrder(orderData, result));
        console.log("Order successfully created in Firestore with ID:", orderId);

        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        // onPurchaseComplete();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      setError("Failed to create ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: cartItems.map(item => ({
          size_id: item.id,
          quantity: item.quantity,
        })),
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,        
        scheduled_date: new Date().toISOString(),
      };
      const previewResult = await previewTreezTicket(ticketData);
      console.log("[StashModal] Previewing Treez ticket:", previewResult);
      if (previewResult.resultCode === 'SUCCESS') {
        const totalAmount = cartItems.reduce((total, item) => {
          const product = latestProducts[item.id];
          const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
          return total + parseFloat((price * item.quantity).toFixed(2));
        }, 0);
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: cartItems.map(item => {
            const product = latestProducts[item.id];
            const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
            return {
              productId: item.id,
              quantity: item.quantity,
              price: price,
            };
          }),
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
          isDirectPurchase: false
        };

        const orderId = await dispatch(createOrder(orderData, previewResult));
        console.log("Order successfully created in Firestore with ID:", orderId);

        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        // onPurchaseComplete();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      console.error("Error previewing ticket:", error);
      setError("Failed to preview ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyAccount = (formData) => {
    // For now, just log the form data
    console.log('Verification form submitted:', formData);
    // TODO: Implement the actual verification logic
    handleVerificationSuccess();
  };

  const handleVerificationSuccess = () => {
    setPurchaseStatus(true);
    setShowVerificationForm(false);
  };

  const handleShowVerificationForm = () => {
    setShowVerificationForm(true);
  };

  const renderNavIcons = () => (
    <div className="nav-icons-container">
      <div style={{ width: '40px' }}>
        <IonIcon
          icon={timeOutline}
          className={`nav-icon ${mode === 'history' ? 'active' : ''}`}
          onClick={() => setMode('history')}
        />
      </div>
      <div style={{ width: '40px' }}>
        <IonIcon
          icon={bagOutline}
          className={`nav-icon ${mode === 'stash' ? 'active' : ''}`}
          onClick={() => setMode('stash')}
        />
      </div>
    </div>
  );

  const OrderProgress = ({ status }) => {
    const stages = [
      'VERIFICATION_PENDING',
      'AWAITING_PROCESSING',
      'IN_PROCESS',
      'PACKED_READY',
      'COMPLETED'
    ];

    const getStageIndex = (currentStatus) => {
      switch (currentStatus) {
        case 'VERIFICATION_PENDING':
          return 0;
        case 'AWAITING_PROCESSING':
          return 1;
        case 'IN_PROCESS':
          return 2;
        case 'PACKED_READY':
          return 3;
        case 'COMPLETED':
          return 4;
        case 'CANCELED':
          return -1;
        default:
          return -1;
      }
    };

    const currentIndex = getStageIndex(status);

    const getStatusLabel = (status) => {
      switch (status) {
        case 'VERIFICATION_PENDING':
          return 'Verification Needed';
        case 'AWAITING_PROCESSING':
          return 'Awaiting Confirmation';
        case 'IN_PROCESS':
          return 'Preparing Order';
        case 'PACKED_READY':
          return 'Ready for Pickup';
        case 'OUT_FOR_DELIVERY':
          return 'Out for Delivery';
        case 'COMPLETED':
          return 'Completed';
        case 'CANCELED':
          return 'Canceled';
        default:
          return 'Processing';
      }
    };

    // For canceled orders, show only the status label
    if (status === 'CANCELED') {
      return (
        <div className="order-progress">
          <div className="status-label canceled">
            {getStatusLabel(status)}
          </div>
        </div>
      );
    }

    // For completed orders, show all bars filled
    if (status === 'COMPLETED') {
      return (
        <div className="order-progress">
          <div className="progress-bars">
            {stages.slice(0, -1).map((stage, index) => (
              <React.Fragment key={stage}>
                <div className="progress-step-container">
                  <div className="progress-step completed" />
                </div>
                <div className="progress-bar-container">
                  <div className="progress-bar completed" />
                </div>
              </React.Fragment>
            ))}
            <div className="progress-step-container">
              <div className="progress-step completed" />
            </div>
          </div>
          <div className="status-label completed">
            {getStatusLabel(status)}
          </div>
        </div>
      );
    }

    return (
      <div className="order-progress">
        <div className="progress-bars">
          {stages.slice(0, -1).map((stage, index) => {
            const isCompleted = currentIndex > index;
            const isActive = currentIndex === index;
            return (
              <React.Fragment key={stage}>
                <div className="progress-step-container">
                  <div className={`progress-step ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`} />
                </div>
                <div className="progress-bar-container">
                  <div className={`progress-bar ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`} />
                </div>
              </React.Fragment>
            );
          })}
          <div className="progress-step-container">
            <div className={`progress-step ${status === 'COMPLETED' ? 'completed' : ''}`} />
          </div>
        </div>
        <div className={`status-label ${status === 'COMPLETED' ? 'completed' : 'active'}`}>
          {getStatusLabel(status)}
        </div>
      </div>
    );
  };

  const OrderItemWithProduct = ({ item, products }) => {
    const product = products.find(p => p.id === item.productId);
    
    if (!product) {
      return <div className="order-item-error">Product not found</div>;
    }

    return (
      <div className="order-stash-item-wrapper">
        <StashItem
          key={item.productId}
          name={product.name}
          brand={product.brand}
          type={product.category}
          price={item.price}
          imageUrl={product.imageUrl}
          quantity={item.quantity}
          readOnly={true}
        />
      </div>
    );
  };

  const OrderBox = ({ order, products }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [storeData, setStoreData] = useState(null);
    console.log('Order:', order);

    useEffect(() => {
      const fetchStoreData = async () => {
        if (order.store_data.store_id) {
          try {
            const storeDoc = await getDoc(doc(db, 'stores', order.store_data.store_id));
            if (storeDoc.exists()) {
              setStoreData(storeDoc.data());
            }
          } catch (error) {
            console.error('Error fetching store data:', error);
          }
        }
      };
      
      fetchStoreData();
      console.log('OrderBox Debug:', {
        order: order,
        storeData: storeData,
        storeId: order.ticketData?.store_id
      });
    }, [order.ticketData?.store_id]);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const items = order.items ? Object.values(order.items) : [];
    const isCompletedOrCanceled = order.orderStatus === 'COMPLETED' || order.orderStatus === 'CANCELED';
    
    // Get total amount from either root level or ticketData
    const totalAmount = order.totals?.total || (order.ticketData && order.ticketData.totalAmount) || 0;
    
    // Get date from either orderTime or scheduled_date
    const orderDate = order.orderTime || (order.ticketData && order.ticketData.scheduled_date);
    
    return (
      <div className={`order-box ${isCompletedOrCanceled ? 'completed-or-canceled' : ''}`}>
        <div className="order-header">
          <div className="header-content">
            <div className="store-info-ob">
              {storeData?.logo && (
                <img 
                  src={storeData.logo} 
                  alt={`${storeData.name} logo`}
                  className="store-logo"
                />
              )}
              <span className="store-name-ob">{storeData?.name || 'The Woods'} </span>
              <span className="order-date"> | </span>
              <div className="order-date">{formatDate(orderDate)}</div>
            </div>
            
            <div className="order-total">${((order.totals?.total || 0) / 100).toFixed(2)}</div>
          </div>
        </div>
        <div className="order-summary">
          {isCompletedOrCanceled ? (
            <div className={`status-label ${order.orderStatus.toLowerCase()}`}>
              {order.orderStatus === 'COMPLETED' ? 'Completed' : 'Canceled'}
            </div>
          ) : (
            <OrderProgress status={order.orderStatus} />
          )}
        </div>
        <div className="expand-button-container">
          <button className={`expand-button ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
              <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </button>
        </div>
        {isExpanded && (
          <div className="order-details">
            {order.treezOrderNumber && <div className="order-id">Order #{order.treezOrderNumber}</div>}
            <div className="order-items">
              {items.map((item, index) => (
                <OrderItemWithProduct 
                  key={`${item.productId}-${index}`} 
                  item={item} 
                  products={products}
                />
              ))}
            </div>
            <section className="stashmodal-inner1">
              <div className="box-parent">
                <OrderDetails order={order} isHistorical={true} />
              </div>
            </section>
          </div>
        )}
      </div>
    );
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    let dateObj;
    
    // Case 1: Firestore Timestamp object
    if (date && (date._seconds || date.seconds)) {
      dateObj = new Date((date._seconds || date.seconds) * 1000);
    }
    // Case 2: Raw timestamp format "Timestamp(seconds=X, nanoseconds=Y)"
    else if (typeof date === 'string' && date.startsWith('Timestamp')) {
      const seconds = date.match(/seconds=(\d+)/)?.[1];
      if (seconds) {
        dateObj = new Date(parseInt(seconds) * 1000);
      } else {
        dateObj = new Date(date);
      }
    }
    // Case 3: Regular date string
    else if (typeof date === 'string') {
      dateObj = new Date(date);
    }
    // Case 4: Already a Date object
    else if (date instanceof Date) {
      dateObj = date;
    }
    // Case 5: Fallback
    else {
      console.warn('Unknown date format:', date);
      return '';
    }
    
    // Check if date is valid
    if (isNaN(dateObj.getTime())) {
      console.warn('Invalid date:', date);
      return '';
    }

    return dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/Los_Angeles'
    });
  };

  const calculateOrderTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((total, item) => {
      if (!item) return total;
      const itemPrice = item.discountedPrice || item.price || 0;
      const quantity = item.quantity || 0;
      return total + (itemPrice * quantity);
    }, 0);
  };

  const renderStashContent = () => {
    const calculateTotal = () => {
      const subtotal = cartItems.reduce((total, item) => {
        const product = latestProducts[item.id];
        const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
        return total + parseFloat((price * item.quantity).toFixed(2));
      }, 0);

      const laCannabisRate = 0.10;
      const caExciseRate = 0.15;
      const salesTaxRate = 0.0975;

      const laCannabisCity = subtotal * laCannabisRate;
      const caExcise = subtotal * caExciseRate;
      const salesTax = subtotal * salesTaxRate;
      
      return (subtotal + laCannabisCity + caExcise + salesTax).toFixed(2);
    };

    const total = calculateTotal();
    return (
      <>
        <ModalHeader title="Your Stash" onClose={onClose} />
        {!showVerificationForm ? (
          <>
            <div className="stashmodal-child" />
            {renderNavIcons()}
            {cartItems.length === 0 ? (
              <div className="empty-cart-message">
                Your stash is empty. Add some items to make a purchase!
              </div>
            ) : (
              cartItems.map((item) => {
                const latestProduct = latestProducts[item.id];
                const product = latestProduct || products.find(p => p.id === item.id);
                
                return (
                  <StashItem
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    brand={item.brand}
                    category={product?.category_type || item.category}
                    classification={item.classification}
                    price={item.price}
                    discountedPrice={item.discountedPrice}
                    quantity={item.quantity}
                    imageUrl={item.imageUrl || product?.imageUrl}
                    sellableQuantity={isLoadingProducts ? Infinity : (latestProduct?.sellableQuantity || product?.sellableQuantity || 0)}
                    onRemove={() => handleRemoveFromCart(item.id)}
                  />
                );
              })
            )}
            <div className="stashmodal-inner" />
            <section className="stashmodal-inner1">
              <div className="box-parent">
                <OrderDetails cartItems={cartItems} products={products} />
                <LocationFull />
              </div>
            </section>
            <div className="stashmodal-button-container">
              <div className="payment-notice">
                <IonIcon icon={cardOutline} /> Payment made at store
              </div>
              <div className="help-notice">
                <IonIcon icon={helpCircleOutline} /> Need help? Email hello@stashpandas.com
              </div>
              <div 
                className={`purchase-wrapper ${cartItems.length === 0 ? 'disabled' : ''}`} 
                onClick={() => {
                  if (cartItems.length > 0) {
                    user.purchaseStatus ? handlePurchase() : setShowVerificationForm(true);
                  }
                }}
              >
                <div className="purchase">
                  {isLoading ? 'Processing...' : 
                   cartItems.length === 0 ? 'Add items to purchase' :
                   user.purchaseStatus ? `Complete Purchase • $${calculateTotal()}` : 'Verify Account to Purchase'}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="verification-form-wrapper">
            <VerificationForm onSubmit={handleVerifyAccount} onVerificationSuccess={handleVerificationSuccess} />
          </div>
        )}
      </>
    );
  };

  const renderOrderContent = () => {
    if (showVerificationForm) {
      return <VerificationForm onSubmit={handleVerifyAccount} onVerificationSuccess={handleVerificationSuccess} />;
    }

    return (
      <>
        <section className="frame-parent9">
          <div className="frame-parent10">
            <OrderDetails cartItems={cartItems} products={products} />
            <LocationFull />
          </div>
        </section>
        <div className="stashmodal-inner2">
        <div className="payment-notice">
          <IonIcon icon={cardOutline} /> Payment made at store
        </div>
          <div className="purchase-wrapper" onClick={() => user.purchaseStatus ? handlePurchase() : setShowVerificationForm(true)}>
            <div className="purchase">
              {user.purchaseStatus ? `Complete Purchase • $${cartItems.reduce((total, item) => {
                const product = latestProducts[item.id];
                const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
                return total + parseFloat((price * item.quantity).toFixed(2));
              }, 0).toFixed(2)}` : 'Verify Account to Purchase'}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderHistoryContent = () => {
    // Show loading when switching modes or when orders are loading
    if (!orders || ordersLoading || isHistoryLoading) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="stashmodal-child" />
          {renderNavIcons()}
          <div className="order-history-container">
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          </div>
        </>
      );
    }

    if (ordersError) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="order-history-container">
            <div className="error-message">Error loading orders: {ordersError.message}</div>
          </div>
        </>
      );
    }

    if (!orders || orders.length === 0) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="stashmodal-child" />
          {renderNavIcons()}
          <div className="order-history-container">
            <div className="no-orders-message">No orders found</div>
          </div>
        </>
      );
    }

    const sortedOrders = [...orders].sort((a, b) => {
      const getTimestamp = (order) => {
        if (order.orderTime?.seconds) {
          return order.orderTime.seconds * 1000;
        } else if (order.orderTime?._seconds) {
          return order.orderTime._seconds * 1000;
        } else if (order.ticketData?.scheduled_date) {
          return new Date(order.ticketData.scheduled_date).getTime();
        }
        return 0;
      };
      
      return getTimestamp(b) - getTimestamp(a);
    });

    return (
      <>
        <ModalHeader title="Order History" onClose={onClose} />
        <div className="stashmodal-child" />
        {renderNavIcons()}
        <div className="order-history-container">
          {sortedOrders.map((order) => {
            if (!order) return null;
            
            const orderDate = order.orderTime ? new Date(order.orderTime) : new Date();
            const totalAmount = order.totals?.total || 0;
            
            // Use the real-time status if available, otherwise fall back to the order's status
            const currentStatus = order.orderStatus;
            
            return (
              <OrderBox key={order.id || order.treezTicketId || Math.random()} order={order} products={products} />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="stashmodal">
        {mode === 'stash' ? renderStashContent() : 
         mode === 'order' ? renderOrderContent() : 
         renderHistoryContent()}
      </div>
      <style jsx>{`
        .store-info {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .store-logo {
          width: 24px;
          height: 24px;
          object-fit: contain;
          border-radius: 4px;
        }

        .store-name {
          font-weight: 500;
          color: var(--text-primary);
        }

        .separator {
          color: var(--text-secondary);
          margin: 0 4px;
        }

        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default StashModal;
