import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IonIcon } from '@ionic/react';
import { trashOutline, removeCircleOutline, addCircleOutline } from 'ionicons/icons';
import { updateCartItemQuantity, removeFromCart } from '../../redux/actions/orderActions';
import "./QuantityCounter.css";

const QuantityCounter = ({ inventoryCount, onQuantityChange, initialQuantity, productId, isBuyNow, align = 'center' }) => {
  const [quantity, setQuantity] = useState(initialQuantity)
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const handleIncrement = () => {
    if (quantity < inventoryCount) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleRemove = () => {
    dispatch(removeFromCart(user.userId, productId));
  };

  return (
    <div className={`quantity-counter-wrapper ${align}`}>
      <div className="quantity-counter">
        <div 
          className="icon-wrapper" 
          onClick={quantity === 1 && !isBuyNow ? handleRemove : handleDecrement}
          style={{ pointerEvents: quantity === 1 && isBuyNow ? 'none' : 'auto' }}
        >
          <IonIcon 
            icon={quantity === 1 ? trashOutline : removeCircleOutline} 
            style={{ visibility: quantity === 1 && isBuyNow ? 'hidden' : 'visible' }}
          />
        </div>
        <div className="quantity-display">
          <div className="quantity">{quantity}</div>
        </div>
        <div className="icon-wrapper" onClick={quantity < inventoryCount ? handleIncrement : undefined}>
          <IonIcon icon={addCircleOutline} style={{ visibility: quantity >= inventoryCount ? 'hidden' : 'visible' }} />
        </div>
      </div>
      {quantity >= inventoryCount && (
        <div className="quantity-message">Max quantity reached</div>
      )}
    </div>
  );
};

export default QuantityCounter;
