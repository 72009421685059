import "./ShopNowButton.css";

const ShopNowButton = ({ onClick, isSpotlight }) => {
  return (
    <button 
      className={`shop-now-container ${isSpotlight ? 'disabled' : ''}`}
      onClick={!isSpotlight ? onClick : undefined}
      disabled={isSpotlight}
    >
      <div className="shop-now-text">{isSpotlight ? "Coming soon!" : "Shop Now"}</div>
    </button>
  );
};

export default ShopNowButton;
