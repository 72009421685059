import "./ItemInfo.css";
import { useMemo } from 'react';

const ItemInfo = ({ product }) => {

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Extract data from attributes
  const attributes = product?.attributes || {};
  const cannabinoids = attributes?.cannabinoids || {};
  const details = attributes?.details || {};

  // Get strain label configuration
  const strainLabel = useMemo(() => {
    if (product.classification) {
      switch (product.classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: `rgba(var(--indica-color), var(--label-opacity))` };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: `rgba(var(--sativa-color), var(--label-opacity))` }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: `rgba(var(--cbd-color), var(--label-opacity))` };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: `rgba(var(--hybrid-color), var(--label-opacity))` };
        default:
          return null;
      }
    }
    return null;
  }, [product.classification]);

  // Calculate discount percentage
  const getDiscountPercentage = () => {
    if (!product.price || !product.discountedPrice) return 0;
    const discount = ((product.price - product.discountedPrice) / product.price) * 100;
    return Math.round(discount);
  };

  // Get THC value from either lab_results or attributes.cannabinoids
  const getThcValue = () => {
    const isEdible = product.category_type === 'EDIBLE';
    const value = cannabinoids?.thc?.value || (product?.lab_results?.THC ? parseFloat(product.lab_results.THC) * 100 : 0);
    return isEdible ? `${Math.round(value)}mg` : `${value.toFixed(1)}%`;
  };

  // Get CBD and CBN from lab_results
  const getCbdValue = () => {
    const isEdible = product.category_type === 'EDIBLE';
    const value = cannabinoids?.cbd?.value || (product?.lab_results?.CBD ? parseFloat(product.lab_results.CBD) * 100 : 0);
    return isEdible ? `${Math.round(value)}mg` : `${value.toFixed(1)}%`;
  };

  const getCbnValue = () => {
    return product?.lab_results?.CBN ? `${(parseFloat(product.lab_results.CBN) * 100).toFixed(1)}%` : '0.0%';
  };

  const discountPercentage = getDiscountPercentage();

  return (
    <div className="item-details-wrapper">
      {/* Header Section */}
        

        {/* Product Info Section */}
        <div className="product-info-section">

          {/* Labels */}
          <div className="labels-container">
            {discountPercentage > 0 && (
              <div className="discount-label">{discountPercentage}% OFF</div>
            )}
            {strainLabel && (
              <div 
                className="strain-label"
                style={{ backgroundColor: strainLabel.backgroundColor }}
              >
                {strainLabel.text}
              </div>
            )}
          </div>
          <img
            className="product-info-image"
            loading="lazy"
            alt=""
            src={product.imageUrl}
          />
          <div className="product-name-brand-description-container">
          <h1 className="product-info-name">
            {product.name} - {product.category_type} 
            {product.attributes?.amount?.value && product.attributes?.amount?.unit ? 
              ` (${product.attributes.amount.value}${product.attributes.amount.unit})`
              : ''
            }
          </h1>
          <h2 className="product-info-brand">{product.brand}</h2>
          {product.description && (
            <div className="product-info-description">
              {product.description}
            </div>
            )}
          </div>
        </div>
        
        {/* Stats Section */}
        <div className="stats-section">

          {/* Combined Details Section */}
          <div className="details-section">
            {attributes.effects?.length > 0 && (
              <div className="details-row">
                <div className="details-title">Effects</div>
                <div className="tag-container">
                  {attributes.effects.map((effect, i) => (
                    <span key={i} className="tag effect">{effect}</span>
                  ))}
                </div>
              </div>
            )}

            {attributes.activities?.length > 0 && (
              <div className="details-row">
                <div className="details-title">Good For</div>
                <div className="tag-container">
                  {attributes.activities.map((activity, i) => (
                    <span key={i} className="tag activity">{activity}</span>
                  ))}
                </div>
              </div>
            )}

            {attributes.flavors?.length > 0 && (
              <div className="details-row">
                <div className="details-title">Flavors</div>
                <div className="tag-container">
                  {attributes.flavors.map((flavor, i) => (
                    <span key={i} className="tag flavor">{flavor}</span>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* Cannabinoids Section */}
          <div className="cannabinoids-section">
            <div className="cannabinoid-box">
              <div className="cannabinoid-label">THC</div>
              <div className="cannabinoid-value">{getThcValue()}</div>
            </div>
            <div className="cannabinoid-box">
              <div className="cannabinoid-label">CBD</div>
              <div className="cannabinoid-value">{getCbdValue()}</div>
            </div>
            {/* <div className="cannabinoid-box">
              <div className="cannabinoid-label">CBN</div>
              <div className="cannabinoid-value">{getCbnValue()}</div>
            </div> */}
          </div>
          
          
        </div>

        {/* Serving Info */}
        {/* {details.servings && (
          <div className="serving-info">
            <span className="serving-label">Serving Size:</span>
            {details.servings}
          </div>
        )} */}
    </div>
  );
};

export default ItemInfo;
